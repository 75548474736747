import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationTriangleIcon } from 'assets/icons';
import { getDMSConfig, hideDMSErrorPopup } from 'domains/user';
import { theme } from 'styles';
import { Box, Flex, Icon, MarginBox, Modal, Text, WhiteButton } from 'UI';

const DMSErrorDialog = () => {
  const { t } = useTranslation();
  const dmsConfig = useSelector(getDMSConfig);
  const dispatch = useDispatch();

  if (!dmsConfig?.showErrorPopup) {
    return <></>;
  }

  function getDMSErrorText() {
    switch (dmsConfig?.errorType) {
      case 'API_ERROR':
        return (
          <Flex direction={'column'} justify={'flex-start'} maxHeight={130}>
            <Text type={'h6_black'}>{dmsConfig?.errorCode && dmsConfig.errorCode}</Text>
            <Text type={'light_14_black_55'} ellipsis>
              {dmsConfig?.errorMessage && dmsConfig.errorMessage}
            </Text>
          </Flex>
        );
      case 'ESTIMATE_NOT_FOUND':
        return (
          <Flex direction={'column'} align={'center'}>
            <Text type={'h6_black'}>
              {t('estimate.dms.export.estimate_not_found', 'Estimate was not found during DMS export')}
            </Text>
          </Flex>
        );
      case 'NO_ITEMS_IN_ESTIMATE':
        return (
          <Flex direction={'column'} align={'center'}>
            <Text type={'h6_black'}>
              {t('estimate.dms.export.no_items_in_estimate', 'No items in estimate, nothing to send to DMS.')}
            </Text>
          </Flex>
        );
      default:
        return (
          <Flex direction={'column'} align={'center'}>
            <Text type={'h6_black'}>
              {t('estimate.dms.export.unknown_error', 'Unknown error occurred during DMS export')}
            </Text>
          </Flex>
        );
    }
  }

  return (
    <Modal
      width={600}
      footer={
        <MarginBox mb={30} mx={30}>
          <Flex direction={'row-reverse'}>
            <WhiteButton onClick={() => dispatch(hideDMSErrorPopup())}>{t('common.action.close', 'Close')}</WhiteButton>
          </Flex>
        </MarginBox>
      }
      open={dmsConfig?.showErrorPopup}
      onCancel={() => dispatch(hideDMSErrorPopup())}
    >
      <Box width={500} height={250}>
        <Flex direction={'column'} align={'center'}>
          <Text type={'h2'}>{t('errors.something_went_wrong.header', 'Something went wrong')}</Text>
          <Icon IconComponent={ExclamationTriangleIcon} color={theme.color.red} size={40} noPointer />
          <Text type={'light_14_bold_red'}>{t('errors.dms_error_label', 'DMS')}</Text>
        </Flex>
        <MarginBox mt={15} />
        {getDMSErrorText()}
      </Box>
    </Modal>
  );
};

export default DMSErrorDialog;
