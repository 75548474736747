import {
  COMMAND_PROCESSING_RESPONSE,
  COMMAND_PROCESSING_RESPONSE_WITH_DATA,
  QUERY_ERROR_RESPONSE,
  QUERY_INVALID_RESPONSE,
  QUERY_NO_DATA_RESPONSE,
  QUERY_SUCCESS_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { QueryResponseType, WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { Frame } from '@stomp/stompjs';
import { Dispatch } from 'redux';
import { RootState } from 'app/AppStore';
import { basicValidation } from 'domains/webSockets/Websocket.mapper';
import {
  addProcessedRequest,
  getPendingRequestsSorted,
  getProcessedRequest,
  getRequest,
  removeProcessedRequest,
  removeRequest,
} from 'domains/webSockets/WebSocket.store';
import { wsSendAction } from 'utils/domainStore/api';
import { RequestResponseMappingManager } from '../WebSocket.requestsResponseMapping';

function resolveQueryType(
  foundRequestByTraceId: boolean | undefined,
  foundRequestByCommandTraceId: boolean | undefined,
): QueryResponseType {
  if (!foundRequestByTraceId && !foundRequestByCommandTraceId) {
    return QueryResponseType.EVENT_UPDATE;
  } else if (foundRequestByCommandTraceId) {
    return QueryResponseType.COMMAND_LOOKUP_RESPONSE;
  } else if (foundRequestByTraceId) {
    return QueryResponseType.INIT;
  } else {
    return QueryResponseType.INIT; // todo maybe we can change it now to undefined
  }
}

function isCommandResponse(responseType: string) {
  return responseType === COMMAND_PROCESSING_RESPONSE || responseType === COMMAND_PROCESSING_RESPONSE_WITH_DATA;
}

function getQueryResponseType(getState: () => RootState, message: Frame, responseType: string, dispatch: Dispatch) {
  const traceId = message.headers?.traceId ?? '';
  const foundRequestByTraceId = getRequest(getState(), traceId);
  // because we have requestResponse mapping, we know if COMMAND_RESPONSE is followed by another *RESPONSE
  isCommandResponse(responseType) &&
    RequestResponseMappingManager.getSagaByUrl(foundRequestByTraceId?.url ?? '') &&
    foundRequestByTraceId &&
    dispatch(addProcessedRequest(traceId));

  // *RESPONSE arrive after COMMAND_RESPONSE then it is possible to remove mark request as processed
  const commandTraceId = message.headers?.commandTraceId ?? '';
  const foundRequestByCommandTraceId = getProcessedRequest(getState(), commandTraceId);

  !isCommandResponse(responseType) &&
    RequestResponseMappingManager.isResponseMapped(responseType) &&
    foundRequestByCommandTraceId &&
    dispatch(removeProcessedRequest(commandTraceId));
  return resolveQueryType(!!foundRequestByTraceId, !!foundRequestByCommandTraceId);
}

function isQueryResponse(responseType: string) {
  return [QUERY_SUCCESS_RESPONSE, QUERY_NO_DATA_RESPONSE, QUERY_INVALID_RESPONSE, QUERY_ERROR_RESPONSE].includes(
    responseType,
    0,
  );
}

function pairResponseWithRequest(wsResponse: WsResponse, getState: () => RootState, message: Frame) {
  // check if it makes sense to update somehow COMMAND_PROCESSING_RESPONSE
  if (isCommandResponse(wsResponse.type)) {
    const originRequest = getRequest(getState(), message.headers?.traceId);
    if (RequestResponseMappingManager.getSagaByUrl(originRequest?.url ?? '') && originRequest && originRequest?.url) {
      const requestUrl = RequestResponseMappingManager.getSagaByUrl(originRequest.url);
      if (wsResponse.type === COMMAND_PROCESSING_RESPONSE) {
        if (wsResponse.payload === false) {
          // current issue with old responses - it is not possible to distinguish no_change and invalid
          wsResponse.queryResponseType = QueryResponseType.COMMAND_RESPONSE_INVALID;
          wsResponse.type = requestUrl;
        } else if (wsResponse.payload === true) {
          wsResponse.queryResponseType = QueryResponseType.COMMAND_RESPONSE_SUCCESS;
          wsResponse.type = requestUrl;
        }
      } else if (wsResponse.type === COMMAND_PROCESSING_RESPONSE_WITH_DATA) {
        let result;
        switch (wsResponse.payload.result) {
          case 'SUCCESS':
            result = QueryResponseType.COMMAND_RESPONSE_SUCCESS;
            break;
          case 'NO_CHANGE':
            result = QueryResponseType.COMMAND_RESPONSE_NO_CHANGE;
            break;
          case 'INVALID_COMMAND':
            result = QueryResponseType.COMMAND_RESPONSE_INVALID;
            break;
          case 'ERROR':
            result = QueryResponseType.COMMAND_RESPONSE_ERROR;
            break;
          default:
            throw new Error('Unknown response type');
        }
        wsResponse.payload = wsResponse.payload.body;
        wsResponse.queryResponseType = result;
        wsResponse.type = requestUrl;
      }
    }
  } else if (isQueryResponse(wsResponse.type)) {
    const originRequest = getRequest(getState(), message.headers?.traceId);
    if (RequestResponseMappingManager.getSagaByUrl(originRequest?.url ?? '') && originRequest && originRequest?.url) {
      wsResponse.type = RequestResponseMappingManager.getSagaByUrl(originRequest.url);
    }
  }
}

export const onMessageReceived = (
  message: Frame,
  dispatch: Dispatch,
  isError: boolean,
  getState: () => RootState,
): void => {
  const wsResponse = basicValidation(message);
  try {
    if (isError && wsResponse.payload === 'ERROR') {
      console.warn(wsResponse);
    }
  } catch (err) {
    console.error(err);
    console.error(wsResponse);
  }
  const traceId = message.headers?.traceId ?? '';

  wsResponse.queryResponseType = getQueryResponseType(getState, message, wsResponse.type, dispatch);
  pairResponseWithRequest(wsResponse, getState, message);

  dispatch(removeRequest(traceId));
  dispatch(wsResponse);
};

export const resendAllPendingRequests = (dispatch: Dispatch, getState: () => RootState): void => {
  const requests = getPendingRequestsSorted(getState());
  requests.forEach((req) => {
    dispatch(removeRequest(req.traceId));
    const { payload, url } = req;
    dispatch(wsSendAction(url, payload));
  });
};
