import React, { MouseEvent, ReactNode } from 'react';
import { Flex } from 'UI/Flex';
import { BreakpointSelectable } from 'utils';

export const buttonSizes = ['small', 'middle', 'large', 'xlarge'] as const;
export type ButtonSize = typeof buttonSizes[number];

export const buttonShapes = ['square', 'semiRound', 'round', 'circle', 'default'] as const;
export type ButtonShape = typeof buttonShapes[number];

export type ButtonWrapType = 'wrap' | 'nowrap' | 'initial' | 'inherit';

export type ButtonProps = {
  children?: ReactNode | string;
  stretch?: boolean;
  passRef?: React.Ref<HTMLButtonElement>;
  size?: ButtonSize;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  wrap?: BreakpointSelectable<ButtonWrapType>;
  // -----
  icon?: ReactNode;
  className?: string;
  shape?: ButtonShape;
  alignSelfFlexEnd?: boolean;
  htmlType?: 'submit' | 'button';
  dataCy?: string;
  outlined?: boolean;
};

function onMouseLeave(e: any) {
  e.target?.blur();
}

export const ButtonWrapper = ({ children, passRef, ...props }: ButtonProps) => {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={props.className}
      ref={passRef}
      onMouseLeave={onMouseLeave}
      type={props.htmlType ?? 'button'}
      data-cy={props.dataCy}
    >
      <Flex align={'center'} gap={5}>
        {props.icon}
        {children}
      </Flex>
    </button>
  );
};
