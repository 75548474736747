/* eslint-disable max-len */
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/VehicleSearchHistoryResponse';
import { AvailableDMSService } from '@1po/1po-bff-fe-spec/generated/dms/model/AvailableDMSService';
import { CommercialLink, Seller } from '@1po/1po-bff-fe-spec/generated/user/model/CommercialLink';
import { CatalogDisplayType } from '@1po/1po-bff-fe-spec/generated/user/request/UpdateCatalogSettingsRequest';
import { DelegationBuyer } from '@1po/1po-bff-fe-spec/generated/user/response/model/DelegationBuyer';
import { DelegationSeller } from '@1po/1po-bff-fe-spec/generated/user/response/model/DelegationSeller';
import { PendingSellerWarning } from '@1po/1po-bff-fe-spec/generated/user/response/model/PendingSellerWarning';
import { UserNotificationsResponse } from '@1po/1po-bff-fe-spec/generated/user/response/UserNotificationsResponse';
import { APP_NAMESPACE } from 'app/App.constants';
import { IamCatalogBrandsType, IamCatalogTabsType } from 'domains/catalog/Catalog.types';
import { LOADING, NO_DATA, SEARCH_STATUS, SearchData } from 'utils';
import { Country } from 'utils/i18n/Country';
import { Language } from 'utils/i18n/Language';

export const USER_NAMESPACE = `${APP_NAMESPACE}/USER`;

export const LOAD_USER = `${USER_NAMESPACE}/LOAD_USER`;
export const LOGOUT_USER = `${USER_NAMESPACE}/LOGOUT_USER`;
export const DISCONNECT_USER = `${USER_NAMESPACE}/DISCONNECT_USER`;
export const RELOAD_USER_AFTER_RECONNECT = `${USER_NAMESPACE}/RELOAD_USER_AFTER_RECONNECT`;
export const GET_USER_PROFILE = `${USER_NAMESPACE}/GET_USER_PROFILE`;
export const ADD_FAVORITE_TIRE_BRAND = `${USER_NAMESPACE}/ADD_FAVORITE_TIRE_BRAND`;
export const REMOVE_FAVORITE_TIRE_BRAND = `${USER_NAMESPACE}/REMOVE_FAVORITE_TIRE_BRAND`;
export const RESET_FAVORITE_TIRE_BRANDS_SELECTION = `${USER_NAMESPACE}/RESET_FAVORITE_TIRE_BRANDS_SELECTION`;
export const GET_USER_SELLERS = `${USER_NAMESPACE}/GET_USER_SELLERS`;
export const UPDATE_DH_CATALOG_REFERENCE_VIEW = `${USER_NAMESPACE}/UPDATE_DH_CATALOG_REFERENCE_VIEW`;
export const CONNECT_TO_DELEGATION_SESSION = `${USER_NAMESPACE}/CONNECT_TO_DELEGATION_SESSION`;
export const DISCONNECT_FROM_DELEGATION_SESSION = `${USER_NAMESPACE}/DISCONNECT_FROM_DELEGATION_SESSION`;
export const GET_POSSIBLE_SELLERS_TO_DELEGATE = `${USER_NAMESPACE}/GET_POSSIBLE_SELLERS_TO_DELEGATE`;
export const GET_POSSIBLE_BUYERS_TO_DELEGATE = `${USER_NAMESPACE}/GET_POSSIBLE_BUYERS_TO_DELEGATE`;
export const RESET_DELEGATION_SESSION = `${USER_NAMESPACE}/RESET_DELEGATION_SESSION`;

export const UPDATE_USER_PROFILE_LANGUAGES_REQUEST = `${USER_NAMESPACE}/UPDATE_USER_PROFILE_LANGUAGES`;
export const UPDATE_USER_SELLER = `${USER_NAMESPACE}/UPDATE_USER_SELLER`;

export const CHANGE_WEB_LANGUAGE = `${USER_NAMESPACE}/CHANGE_WEB_LANGUAGE`;
export const CHANGE_DOCUMENT_LANGUAGE = `${USER_NAMESPACE}/CHANGE_DOCUMENT_LANGUAGE`;
export const GET_AVAILABLE_DMS_SERVICES = `${USER_NAMESPACE}/GET_AVAILABLE_DMS_SERVICES`;

export const GET_VEHICLE_SEARCH_HISTORY_REQUEST = `${USER_NAMESPACE}/GET_VEHICLE_SEARCH_HISTORY_REQUEST`;
export const ADD_VEHICLE_TO_SEARCH_HISTORY_REQUEST = `${USER_NAMESPACE}/ADD_VEHICLE_TO_SEARCH_HISTORY_REQUEST`;
export const REMOVE_VEHICLE_FROM_SEARCH_HISTORY_REQUEST = `${USER_NAMESPACE}/REMOVE_VEHICLE_FROM_SEARCH_HISTORY_REQUEST`;
export const CLEAR_VEHICLE_SEARCH_HISTORY_REQUEST = `${USER_NAMESPACE}/CLEAR_VEHICLE_SEARCH_HISTORY_REQUEST`;

export const FETCH_USER_NOTIFICATION_REQUEST = `${USER_NAMESPACE}/FETCH_USER_NOTIFICATION_REQUEST`;
export const CANCEL_NOTIFICATION_REQUEST = `${USER_NAMESPACE}/CANCEL_NOTIFICATION_REQUEST`;
export const CANCEL_ALL_NOTIFICATIONS_REQUEST = `${USER_NAMESPACE}/CANCEL_ALL_NOTIFICATIONS_REQUEST`;

export const REDIRECT_URL_STORE_KEY = 'redirect_url';
export const LANGUAGE_STORE_KEY = 'lang';
export const DEFAULT_COUNTRY = Country.FR;
export type DealerRepository = 'TRESOR' | 'BIR' | 'VECTURY' | 'DMD';

export interface DelegationSession {
  commercialLink: CommercialLink;
  userRoles: UserRole[];
}

export type UsersState = {
  tokenUser: TokenUser;
  userRoles: UserRole[] | typeof LOADING;
  userProfileSearchStatus: SEARCH_STATUS;
  webLanguage: Language;
  documentLanguage: Language;
  documentLanguageFallback: Language;
  commercialLink: CommercialLink | null;
  dmsConfig: DmsConfig | undefined;
  favoriteTireBrandList: Array<ReferenceBrandType>;
  sellersToChoose: Seller[] | NO_DATA;
  pendingSellerWarning: PendingSellerWarning | SEARCH_STATUS;
  showSellerPopup: boolean;
  vehicleSearchHistory: VehicleDetail[] | NO_DATA;
  sparePartsView: SparePartsViewType;
  referenceView: CatalogDisplayType;
  iamCatalogBrandsView: IamCatalogBrandsType;
  iamCatalogTabsView: IamCatalogTabsType;
  dhCatalogTabsView: IamCatalogTabsType;
  delegation: Delegation;
  isReload: boolean;
  userNotifications: SearchData<UserNotificationsResponse>;
  stockProvider: SearchData<string>;
};

export interface Delegation {
  session: SearchData<DelegationSession>;
  possibleSellers: SearchData<DelegationSeller[]>;
  possibleBuyers: Map<string, SearchData<DelegationBuyer[]>>;
}

export interface TokenProfile {
  id: string;
  garageId?: string;
  garageName?: string;
  repository?: DealerRepository;
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  company?: string;
  locale?: string;
  country?: string;
  preferredLanguage?: string;
  rights?: string[];
  garageId?: string;
  repository?: DealerRepository;
  profiles: TokenProfile[];
  selectedProfile?: TokenProfile;
}

export interface UserProfile {
  webLanguage: Language | undefined;
  documentLanguage: Language | undefined;
  documentLanguageFallback: Language | undefined;
  commercialLink: CommercialLink | undefined;
}

export interface TokenUser {
  user: User | typeof LOADING | undefined;
  tokenExpired: boolean;
}

export type DmsErrorType = 'API_ERROR' | 'UNKNOWN_ERROR' | 'ESTIMATE_NOT_FOUND' | 'NO_ITEMS_IN_ESTIMATE';

export interface DmsConfig {
  availableServices?: AvailableDMSService[] | NO_DATA;
  showErrorPopup?: boolean;
  errorCode?: string;
  errorMessage?: string;
  errorType?: DmsErrorType;
}

export const GarageView = 'garage_view';
export const ClientView = 'client_view';
export const SparePartsView = [GarageView, ClientView];
export type SparePartsViewType = typeof SparePartsView[number];

export const CompactView = 'COMPACT';
export const StandardView = 'STANDARD';

export enum UserRole {
  ADM_COMMANDE = 'ADM_COMMANDE',
  COMMAND = 'COMMAND',
  CONNECT = 'CONNECT',
  CONNECT_COMMANDE = 'CONNECT_COMMANDE',
  CONSULT = 'CONSULT',
  CENTRAL_ADMIN = 'CENTRAL_ADMIN',
  COUNTRY_ADMIN = 'COUNTRY_ADMIN',
  PROMO = 'PROMO',
  R1 = 'R1',
  R2 = 'R2',
  R3 = 'R3',
  R3_MOTRIO = 'R3_MOTRIO',

  NO_IAM_ACCESS = 'NO_IAM_ACCESS', //null/no response - no access
  IAM_LIGHT_ACCESS = 'IAM_LIGHT_ACCESS', //0
  IAM_ACCESS = 'IAM_ACCESS', //1
  IAM_FAST_ACCESS = 'IAM_FAST_ACCESS', //2
  IAM_PRO_ACCESS = 'IAM_PRO_ACCESS', //3
  IAM_PRO_PLUS_ACCESS = 'IAM_PRO_PLUS_ACCESS', //4
}

export class TradingProfile {
  buyerId: string | undefined;
  sellerId: string | undefined;
  repository: DealerRepository;

  constructor(buyerId: string | undefined, sellerId: string | undefined, repository: DealerRepository) {
    this.buyerId = buyerId;
    this.sellerId = sellerId;
    this.repository = repository;
  }

  isComplete(): boolean {
    return this.buyerId !== undefined && this.sellerId !== undefined;
  }
}
