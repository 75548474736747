import React, { PropsWithChildren } from 'react';
import { CarouselRef } from 'antd/es/carousel';
import { Carousel as CarouselAnt } from 'antd-v5';
import { AngleLeftIcon, AngleRightIcon } from 'assets/icons';
import {
  CarouselStyles,
  LeftArrowPositionWrapper,
  RightArrowPositionWrapper,
  SArrow,
} from 'UI/Carousel/Carousel.styled';
import { Icon } from 'UI/index';
import { theme } from '../../styles';

export const Carousel = ({ children }: PropsWithChildren) => {
  let carouselRef: CarouselRef;

  const next = () => carouselRef.next();
  const prev = () => carouselRef.prev();

  return (
    <div className={'home-page-carousel'}>
      <CarouselStyles />
      <CarouselAnt
        ref={(ref) => {
          if (ref) {
            carouselRef = ref;
          }
        }}
        autoplay
        autoplaySpeed={5000}
      >
        {children}
      </CarouselAnt>
      {children && Array.isArray(children) && children.length > 1 && (
        <>
          <LeftArrowPositionWrapper>
            <SArrow onClick={prev}>
              <Icon IconComponent={AngleLeftIcon} color={theme.color.grey85} hoverFill={'rgba(255,255,255,0.9)'} />
            </SArrow>
          </LeftArrowPositionWrapper>
          <RightArrowPositionWrapper>
            <SArrow onClick={next}>
              <Icon IconComponent={AngleRightIcon} color={theme.color.grey85} hoverFill={'rgba(255,255,255,0.9)'} />
            </SArrow>
          </RightArrowPositionWrapper>
        </>
      )}
    </div>
  );
};
