import color from 'styles/color.json';

export const displayStyle = {
  error: { color: color.error },
  warning: { color: color.warning },
  success: { color: color.success_dark },
  info: { color: color.info },
  link: {
    color: color.info,
  },
  white: {
    color: color.white,
  },
  viewed_link: {
    color: color.viewed_link,
  },
  disabled: {
    color: color.disabled,
  },
  promoDiscount: {
    color: color.red,
  },
  cyanHighlight: {
    color: color.cyan,
  },
  green_dark: {
    color: color.green_dark,
  },
  default: {
    color: color.brand_black,
  },
};
