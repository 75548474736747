/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AdditionalInformation } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/AdditionalInformation';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { ROUTER_CATALOG_DH_L3 } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { ReactComponent as MotrioLogo } from 'assets/CarBrand/brand_motrio_mini.svg';
import { ReactComponent as ValuePlusLogo } from 'assets/CarBrand/brand_value_plus_mini.svg';
import { DotCircleIcon } from 'assets/icons';
import { AddToEstimateButtonAndDialog } from 'components/AddToEstimate/AddToEstimateButtonAndDialog';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { DataContainer } from 'components/DataContainer';
import { DiscountCorner } from 'components/Discount';
import { DocumentationAlertButton } from 'components/DocumentationAlertButton';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import ProductModal from 'components/ProductModal';
import { ReferencePriceSection } from 'components/ReferencePriceSection';
import { ReferenceUnavailableForOrder } from 'components/ReferenceUnavailableBox/ReferenceUnavailable';
import { SSOLink } from 'components/SSOLink/SSOLink';
import StockDisplay from 'components/StockInfo';
import PrivateComponent from 'composers/PrivateComponent';
import { getElectronicPartsRepairLink } from 'domains/appContext/AppContext.store';
import { useFetchSingleReference } from 'domains/catalog/Catalog.requests';
import { getLastSearchedVehicleKey, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { DATAHUB, STANDARD } from 'domains/catalog/Catalog.types';
import { useFetchLaborTimeIdsByReferenceGenericPart } from 'domains/laborTime/LaborTime.requests';
import { getLaborTimeIdsForGenericPart } from 'domains/laborTime/LaborTime.store';
import { getDHReference, getRepairPrice, ReferencePriceType } from 'domains/references';
import {
  useFetchEquivalentReferences,
  useFetchFullTradingData,
  useFetchRepairPrices,
} from 'domains/references/References.requests';

import { CLARION } from 'domains/ssoLink/SSOLink.types';
import { GarageView, getCurrency, SparePartsViewType, UserRole } from 'domains/user';
import {
  AdditionalInformationButton,
  LaborTimesButton,
} from 'pages/CatalogPage/common/ReferenceSection/ReferenceSectionButtons';
import { SupersessionTooltip } from 'pages/CatalogPage/common/ReferenceSection/SupersessionTooltip';
import LinkedReferences from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/LinkedReferences/LinkedReferences';
import { mapAdditionalInfoValue } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCard';
import {
  CirclePositionWrapper,
  SBox,
  SCard,
  SReferenceCardLaborTimesWrapper,
} from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCard.styled';
import ReferenceCardLaborTimesWrapper from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCardLaborTimesWrapper';
import Reuse from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/Reuse';
import { EquivalentSection } from 'pages/CatalogPage/IAM/FullTextSearchResult/SearchResultSection';
import { theme } from 'styles';
import {
  Box,
  CenteredSpin,
  ClarionLinkCircleButton,
  Descriptions,
  Flex,
  Icon,
  Image,
  INITIAL_ITEM_COUNT,
  Item,
  LinkRoundButton,
  MarginBox,
  Text,
  WithTooltip,
} from 'UI';
import { getData, LOADING, textFormatter } from 'utils';

function mapCompactAdditionalInfo(info: AdditionalInformation): Item {
  if (!info.id) {
    return {
      label: info.title ?? '',
      value: info.description ?? '',
    };
  }
  if (!info.title) {
    return {
      label: info.description ?? '',
      value: info.id,
    };
  }
  return {
    label: info.title,
    value: mapAdditionalInfoValue(info),
  };
}

interface ReferenceCardCompactProps {
  referenceNumber: string;
  referenceBrand?: ReferenceBrandType;
  price?: ReferencePriceType;
  sparePartsView: SparePartsViewType;
  additionalInfo?: AdditionalInformation[];
  displayAdditionalInfo?: boolean;
  linkedReferences: string[];
  isApplicableToCurrentVehicle: boolean | undefined;
  firstRefWithPrice?: string;
  firstRefWithLaborTimes?: string;
  firstRefWithPriceNotInCart?: string;
  renderLinkedReferencesSwitch: () => React.ReactNode;
  handleAddToCartClick: () => void;
  handleAddToEstimateClick: () => void;
  linkedReferencesActive: boolean;
  isLinkedReference?: boolean;
  displaySupersessionTooltip?: boolean;
  availableForOrder?: boolean;
  genericPart?: string;
  isDiscontinued?: boolean;
}

const ReferenceCardCompact = ({
  referenceNumber,
  referenceBrand,
  sparePartsView,
  additionalInfo,
  displayAdditionalInfo,
  linkedReferences,
  isApplicableToCurrentVehicle,
  price,
  firstRefWithPrice,
  firstRefWithLaborTimes,
  firstRefWithPriceNotInCart,
  handleAddToCartClick,
  handleAddToEstimateClick,
  renderLinkedReferencesSwitch,
  linkedReferencesActive,
  isLinkedReference = false,
  displaySupersessionTooltip,
  availableForOrder = true,
  genericPart,
  isDiscontinued = false,
}: ReferenceCardCompactProps) => {
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const initialActiveSection =
    displayAdditionalInfo && additionalInfo && additionalInfo.length > 0 ? 'additional_info' : undefined;
  const [activeSection, setActiveSection] = useState<'labor_time' | 'additional_info' | undefined>(
    initialActiveSection,
  );
  const { t } = useTranslation();
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const detail = useSelector((state: RootState) =>
    getDHReference(state, { vehicleKey: vehicleDetail?.vehicleKey, referenceNumber }),
  );
  const repairPrice = useSelector((state: RootState) => getRepairPrice(state, referenceNumber));
  const repairPriceData = repairPrice?.data;
  const repairLink = useSelector(getElectronicPartsRepairLink);
  const currency = useSelector(getCurrency);

  const laborTimeIdsWrapper = useSelector((state: RootState) =>
    getLaborTimeIdsForGenericPart(state, { vehicleKey, genericPart }),
  );
  const descriptionItems = additionalInfo
    ? additionalInfo
        .map((info) => mapCompactAdditionalInfo(info))
        .filter((info) => info.label !== '' || info.value !== '')
    : [];

  const displayShowMoreButton = descriptionItems.length > INITIAL_ITEM_COUNT;
  const [showMore, setShowMore] = useState(false);
  const initialItems = descriptionItems.slice(0, INITIAL_ITEM_COUNT);
  const [currentData, setCurrentData] = useState(initialItems);

  const equivalentReferences = useFetchEquivalentReferences(referenceNumber, isDiscontinued);

  useFetchSingleReference(referenceNumber, null, vehicleDetail?.vehicleKey);
  useFetchFullTradingData(referenceNumber, vehicleDetail?.vehicleKey);
  useFetchLaborTimeIdsByReferenceGenericPart(referenceNumber, vehicleDetail?.vehicleKey);
  useFetchRepairPrices([referenceNumber], vehicleDetail?.vehicleKey);

  if (!vehicleDetail) {
    return null;
  }

  const handleSwitchSection = (section: 'labor_time' | 'additional_info') => {
    setActiveSection(activeSection !== section ? section : undefined);
  };

  const handleShowMore = () => {
    setCurrentData(showMore ? initialItems : [...descriptionItems]);
    setShowMore((prevState) => !prevState);
  };

  const renderButtonActivatedSection = () => {
    switch (activeSection) {
      case 'additional_info':
        return (
          <SReferenceCardLaborTimesWrapper direction={'column'} isSquared={!availableForOrder}>
            <MarginBox mt={15}>
              <Descriptions
                title={t('catalog.details.additional_information', 'Additional information')}
                items={currentData}
                displayShowMoreButton={displayShowMoreButton}
                showMore={showMore}
                handleShowMore={handleShowMore}
              />
            </MarginBox>
          </SReferenceCardLaborTimesWrapper>
        );
      case 'labor_time':
        return (
          <>
            {laborTimeIdsWrapper?.data && (
              <ReferenceCardLaborTimesWrapper
                referenceNumber={referenceNumber}
                laborTimeIds={laborTimeIdsWrapper?.data}
                isSquared={!availableForOrder}
              />
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  const getReferenceLogo = () => {
    switch (referenceBrand) {
      case 'MOTRIO':
        return <Icon IconComponent={MotrioLogo} width={70} />;
      case 'VALUE_PLUS':
        return <Icon IconComponent={ValuePlusLogo} width={70} />;
      case 'STANDARD_EXCHANGE':
        return <Image alt={'standard_exchange_logo'} src={'catalog/brand_standard_exchange_mini.png'} height={35} />;

      default:
        return <></>;
    }
  };

  const LinkedPipe = () => {
    return (
      <>
        <MarginBox ml={15} />
        <SBox width={2} height={'calc(100% + 15px)'} background={theme.color.grey90}>
          <CirclePositionWrapper>
            <Icon IconComponent={DotCircleIcon} size={8} color={theme.color.clear_blue} ml={-3} />
          </CirclePositionWrapper>
        </SBox>
      </>
    );
  };

  return (
    <DataContainer data={detail}>
      <SCard isSquared={Boolean(activeSection) || !availableForOrder} isCompact>
        {sparePartsView === GarageView && <DiscountCorner reference={referenceNumber} type={'catalog_mini'} />}
        <Flex direction={'row'} align={'center'} minHeight={40}>
          <Box width={25} />
          {isLinkedReference && <LinkedPipe />}
          <Flex direction={'column'}>
            <Flex direction={'row'} align={'center'}>
              <ProductModal
                triggerComponent={
                  <Text type={'text_dim_bold'} disableGutter hoverUnderLine cursor={'pointer'}>
                    {referenceNumber}
                  </Text>
                }
                referenceNumber={referenceNumber}
                isVehicleCatalog
              />
              <MarginBox mr={10} />
              <CopyToClipboardButton
                textToCopy={referenceNumber}
                message={t(
                  'catalog.reference_card.reference_number.copied_to_clipboard',
                  'Reference number {{referenceNumber}} copied to clipboard',
                  { referenceNumber },
                )}
              />
              {displaySupersessionTooltip && (
                <>
                  <MarginBox mr={10} />
                  <ProductModal
                    triggerComponent={
                      <SupersessionTooltip>
                        <LinkRoundButton />
                      </SupersessionTooltip>
                    }
                    referenceNumber={referenceNumber}
                    scrollToSubstitutes
                  />
                </>
              )}
              <Reuse referenceNumber={referenceNumber} compactView />
              {(referenceBrand === 'MOTRIO' || referenceBrand === 'IAM') && (
                <>
                  <MarginBox mr={1} />
                  <DocumentationAlertButton
                    reference={getData(detail)}
                    vehicleDetail={vehicleDetail}
                    vehicleRegistrationNumber={vehicleDetail?.vrn ?? vehicleDetail?.vehicleKey}
                  />
                </>
              )}
            </Flex>
            {linkedReferences.length > 0 && (
              <Flex direction={'row'} align={'center'}>
                {renderLinkedReferencesSwitch()}
              </Flex>
            )}
          </Flex>
          <Flex>
            <MarginBox mx={15}>
              <ProductModal
                triggerComponent={
                  <Text type={'text_dim'} disableGutter hoverUnderLine cursor={'pointer'}>
                    {getData(detail)?.name ?? ''}
                  </Text>
                }
                referenceNumber={referenceNumber}
                isVehicleCatalog
              />
            </MarginBox>
          </Flex>
          <Flex align={'flex-start'}>{getReferenceLogo()}</Flex>
          <Flex gap={15}>
            <DataContainer
              data={laborTimeIdsWrapper?.searchStatus}
              NotFound={() => <Box width={40} />}
              Loading={() => (
                <Box width={40}>
                  <CenteredSpin />
                </Box>
              )}
            >
              <FirstHelpPopin
                streamId={ROUTER_CATALOG_DH_L3}
                popinId={`${ROUTER_CATALOG_DH_L3}_labor_times`}
                placement={'right'}
                skip={referenceNumber !== firstRefWithLaborTimes}
              >
                <LaborTimesButton
                  isOpen={activeSection === 'labor_time'}
                  handleClick={() => handleSwitchSection('labor_time')}
                />
              </FirstHelpPopin>
            </DataContainer>
            <DataContainer data={additionalInfo} NotFound={() => <Box width={40} />}>
              {additionalInfo && additionalInfo.length > 0 ? (
                <>
                  <AdditionalInformationButton
                    isOpen={activeSection === 'additional_info'}
                    handleClick={() => handleSwitchSection('additional_info')}
                  />
                </>
              ) : (
                <Box width={40} />
              )}
            </DataContainer>
            <DataContainer
              data={repairPriceData}
              NotFound={() => <Box width={40} />}
              Loading={() => (
                <Box width={40}>
                  <CenteredSpin />
                </Box>
              )}
            >
              {repairLink && repairPriceData ? (
                <WithTooltip
                  title={t('catalog.dh.reference_card.action.repair', 'Repair at: {{repairPrice}}', {
                    repairPrice: textFormatter.formatCurrency(repairPriceData, currency),
                  })}
                  placement={'top'}
                >
                  <Box>
                    <SSOLink
                      ssoType={CLARION}
                      renderLinkComponent={() => (
                        <ClarionLinkCircleButton color={'brand_black'} bgColor={'grey95'} size={22} boxSize={'smd'} />
                      )}
                      renderLoadingComponent={() => (
                        <Box width={40}>
                          <CenteredSpin />
                        </Box>
                      )}
                    />
                  </Box>
                </WithTooltip>
              ) : (
                <Box width={40} />
              )}
            </DataContainer>
          </Flex>
          <MarginBox mx={15}>
            <StockDisplay
              vehicleKey={vehicleDetail?.vehicleKey}
              referenceNumber={referenceNumber}
              isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
              small
              spinSize={'default'}
            />
          </MarginBox>
          <Flex minWidth={360}>
            <ReferencePriceSection
              referenceNumber={referenceNumber}
              sparePartsView={sparePartsView}
              handleAddToCartClick={handleAddToCartClick}
              vehicleKey={vehicleDetail?.vehicleKey}
              showFirstHelp={referenceNumber === firstRefWithPriceNotInCart}
              align={'left'}
              catalogSource={DATAHUB}
              referenceType={STANDARD}
              availableForOrder={availableForOrder}
              narrow
              useCompactView
            />
          </Flex>
          <MarginBox mx={15}>
            {price?.garageView?.vatExcludedPrice ? (
              <PrivateComponent
                render={() => (
                  <FirstHelpPopin
                    streamId={ROUTER_CATALOG_DH_L3}
                    popinId={`${ROUTER_CATALOG_DH_L3}_add_to_estimate`}
                    placement={'left'}
                    skip={referenceNumber !== firstRefWithPrice}
                  >
                    {availableForOrder ? (
                      <AddToEstimateButtonAndDialog
                        handleAddToEstimateClick={handleAddToEstimateClick}
                        displayed={availableForOrder}
                        size={'lg'}
                        isVehicleCatalog
                      />
                    ) : (
                      <Box width={40} />
                    )}
                  </FirstHelpPopin>
                )}
                requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
              />
            ) : (
              <Box width={40} />
            )}
          </MarginBox>
        </Flex>
      </SCard>
      {renderButtonActivatedSection()}
      {linkedReferencesActive && (
        <>
          <MarginBox mt={15} />
          <LinkedReferences
            linkedReferences={[...linkedReferences].sort((a, b) => (a > b ? 1 : -1))}
            vehicleDetail={vehicleDetail}
            isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
            useCompactView
            handleAddToEstimateClick={handleAddToEstimateClick}
            sparePartsView={sparePartsView}
            handleAddToCartClick={handleAddToCartClick}
          />
        </>
      )}
      <ReferenceUnavailableForOrder enabled={!availableForOrder} />
      {equivalentReferences?.searchStatus === LOADING && (
        <MarginBox mt={15}>
          <CenteredSpin />
        </MarginBox>
      )}
      {equivalentReferences?.data && equivalentReferences.data.length > 0 && (
        <MarginBox mt={15}>
          <EquivalentSection
            sparePartsView={sparePartsView}
            equivalentReferences={equivalentReferences?.data}
            additionalEquivalencesLoading={false}
            hasMainReference={false}
            vehicleKey={vehicleKey}
          />
        </MarginBox>
      )}
      <MarginBox mt={15} />
    </DataContainer>
  );
};

export default ReferenceCardCompact;
