import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ROUTER_ESTIMATE, ROUTER_ORDER_LIST, ROUTER_TIRES, ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import { BulkOrderingIcon, FileAltRegularV2Icon, OilIcon, OrderTireIcon } from 'assets/icons';
import { useUniversalProductsTree } from 'domains/catalog/Catalog.requests';
import {
  UNIVERSAL_PRODUCTS_NODE_ID,
  UNIVERSAL_PRODUCTS_OILS_5W30_NODE_ID,
  UNIVERSAL_PRODUCTS_OILS_NODE_ID,
} from 'domains/catalog/Catalog.types';
import { CREATE_NEW } from 'pages/BackOfficePage/BackOfficePage.type';
import { SFlex } from 'pages/HomePage/links/HomepageLinks.styled';
import { Flex, Icon, IconType, Link, Text } from 'UI';
import { getData } from 'utils';

export const HomepageLinks = () => {
  const { t } = useTranslation();
  const universalProductsTree = useUniversalProductsTree();

  const linkTiles = useMemo(() => {
    const has5W30Oils = !!getData(universalProductsTree)
      ?.find((l1) => l1.nodeId === UNIVERSAL_PRODUCTS_NODE_ID)
      ?.childCategories?.find((l2) => l2.nodeId === UNIVERSAL_PRODUCTS_OILS_NODE_ID)
      ?.childCategories?.find((l3) => l3.nodeId === UNIVERSAL_PRODUCTS_OILS_5W30_NODE_ID)?.references?.length;

    const data = [
      {
        key: 0,
        icon: BulkOrderingIcon,
        title: t('homepage.links.reference_order.title', 'Group order'),
        description: t('homepage.links.reference_order.description', 'Order by reference'),
        link: `${ROUTER_ORDER_LIST}/${CREATE_NEW}`,
      },
      {
        key: 1,
        icon: OrderTireIcon,
        title: t('homepage.links.tires.title', 'Order tires'),
        description: t('homepage.links.tires.description', 'Your Favorite Brands'),
        link: ROUTER_TIRES,
      },
      {
        key: 2,
        icon: FileAltRegularV2Icon,
        title: t('homepage.links.estimate.title', 'New estimate'),
        description: t('homepage.links.estimate.description', 'Create Estimate'),
        link: ROUTER_ESTIMATE,
      },
    ];
    if (has5W30Oils) {
      data.push({
        key: 3,
        icon: OilIcon,
        title: t('homepage.links.oils.title', 'Order oil'),
        description: t('homepage.links.oils.description', 'Engine Oil Finder'),
        link:
          `${ROUTER_UNIVERSAL_PRODUCTS}/${UNIVERSAL_PRODUCTS_NODE_ID}/${UNIVERSAL_PRODUCTS_OILS_NODE_ID}` +
          `/${UNIVERSAL_PRODUCTS_OILS_5W30_NODE_ID}`,
      });
    }
    return data;
  }, [t, universalProductsTree]);

  return (
    <Flex direction={'column'} gap={20}>
      <Flex justify={'center'}>
        <Text type={'h4_paragraph_title'}>
          <Trans i18nKey={'homepage.links.title'}>{'What are you looking for?'}</Trans>
        </Text>
      </Flex>
      <Flex direction={'row'} justify={'center'} wrap={'wrap'} gap={24}>
        {linkTiles.map((tile) => (
          <LinkTile
            key={tile.key}
            title={tile.title}
            description={tile.description}
            icon={tile.icon}
            link={tile.link}
          />
        ))}
      </Flex>
    </Flex>
  );
};

const LinkTile = ({
  title,
  description,
  icon,
  link,
}: {
  title: string;
  description: string;
  icon: IconType;
  link: string;
}) => {
  return (
    <Link to={link}>
      <SFlex direction={'row'} align={'center'} gap={24}>
        <Icon IconComponent={icon} size={40} noPointer />
        <Flex direction={'column'} gap={4}>
          <Text type={'h5_bold'}>{title}</Text>
          <Text type={'light_14_black_45'}>{description}</Text>
        </Flex>
      </SFlex>
    </Link>
  );
};
