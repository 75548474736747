import styled from 'styled-components';

export const SBadge = styled.div<{
  size: number;
  offset: [number | string, number | string];
  color: string;
  borderColor?: string;
  fontColor?: string;
}>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ color }) => color};
  border-radius: 50px;
  position: absolute;
  top: ${({ offset }) => (typeof offset[0] === 'number' ? `${offset[0]}px` : offset[0])};
  left: ${({ offset }) => (typeof offset[1] === 'number' ? `${offset[1]}px` : offset[1])};
  font-size: ${({ size }) => `${size - 5}px`};
  font-family: 'NouvelR';
  font-weight: 600;
  color: ${({ fontColor }) => fontColor ?? 'black'};
  border: ${({ borderColor }) => (borderColor ? `0.5px solid ${borderColor}` : 'none')};
  user-select: none;
  z-index: 1;
`;

export const Wrapper = styled.div`
  position: relative;
`;
