import React, { ReactNode } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { InfoCircleIcon } from 'assets/icons';
import { StatusType, theme } from 'styles';
import { Icon, IconType } from 'UI/Icon';
import { SAlert } from './Alert.styled';
import { MarginBox } from '../Box';

const clearTimeoutListener = (
  e: MouseEvent,
  timeOut: NodeJS.Timeout,
  notificationId: string,
  onClose: (...args: any[]) => void,
) => {
  if (e.target instanceof Element) {
    const elem = e?.target?.closest('#' + notificationId);
    if (!elem) {
      clearTimeout(timeOut);
      onClose();
    } else {
      addEventListener(timeOut, notificationId, onClose);
    }
  }
};

const addEventListener = (timeOut: NodeJS.Timeout, id: string, onClose: (...args: any[]) => void) => {
  document.addEventListener('mouseup', (e) => clearTimeoutListener(e, timeOut, id, onClose), { once: true });
};

// share with Notification and other feedback components
export interface FeedbackProps {
  isVisible?: boolean;
  onClose?: () => void;
  backgroundColor?: string;
  message?: ReactNode | string;
  id?: string;
  status: StatusType;
  icon?: IconType | ReactNode;
  size?: 'sm' | 'md' | 'lg';
}

function renderIcon(icon: ReactNode | IconType, getIconSize: () => number) {
  return React.isValidElement(icon) ? (
    icon
  ) : (
    <Icon
      IconComponent={icon as IconType}
      color={theme.color.info}
      size={getIconSize()}
      ml={getIconSize() / 3}
      mr={getIconSize() / 3}
    />
  );
}

function Alert({
  status,
  backgroundColor,
  id,
  isVisible = true,
  onClose = () => undefined,
  message,
  icon,
  size = 'sm',
}: Readonly<FeedbackProps>) {
  const handleClose = () => {
    onClose();
  };
  useDebouncedCallback(handleClose, 3000)();

  const getIconSize = () => {
    switch (size) {
      case 'sm':
        return 14;
      case 'md':
        return 20;
      case 'lg':
        return 24;
    }
  };

  const customIcon = icon ? renderIcon(icon, getIconSize) : null;

  if (!isVisible) {
    return null;
  }

  return (
    <div id={id}>
      <SAlert
        backgroundColor={backgroundColor}
        message={message}
        status={status}
        showIcon
        type={status}
        icon={customIcon}
      />
      <MarginBox mt={20} />
    </div>
  );
}

export type StatusAlertProps = Omit<FeedbackProps, 'status'>;

export const SuccessAlert = (props: StatusAlertProps) => <Alert status={'success'} {...props} />;
export const ErrorAlert = (props: StatusAlertProps) => <Alert status={'error'} {...props} />;
export const WarningAlert = (props: StatusAlertProps) => <Alert status={'warning'} {...props} />;
export const InfoAlert = (props: StatusAlertProps) => <Alert status={'info'} icon={InfoCircleIcon} {...props} />;
