import styled from 'styled-components';
import { Flex } from 'UI';

export const SFlex = styled(Flex)<{ index?: number }>`
  border-radius: 4px;
  border: solid 2px rgba(0, 0, 0, 0.1);
  padding: 16px 24px;
  min-width: 260px;
  min-height: 90px;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
