import React from 'react';
import { PresentableOrderStatusType } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { t } from 'i18next';
import styled from 'styled-components';
import { Ban, HourglassStartIcon, TruckIcon } from 'assets/icons';
import { Status, StatusLabel } from 'components/Pdf/PdfUI';
import { theme, ThemeColorKeyType } from 'styles';
import { Box, Flex, Icon, IconType, MarginBox, Progress, ProgressStatusType, Text } from 'UI';

export interface OrderStatusProps {
  orderStatus: PresentableOrderStatusType;
}

export const getStatusLabel = (status: PresentableOrderStatusType) => {
  switch (status) {
    case 'PROCESSING':
      return t('order.order_status.processing', 'Processing');
    case 'TO_BE_VALIDATED':
      return t('order.order_status.to_be_validated', 'To be validated');
    case 'IN_PROGRESS':
      return t('order.order_status.in_progress', 'In progress');
    case 'DELIVERED':
      return t('order.order_status.delivered', 'Delivered');
    case 'PARTIALLY_DELIVERED':
      return t('order.order_status.partially_delivered', 'Partially delivered');
    case 'ERROR':
      return t('order.order_status.error', 'Error');
    case 'CANCELLED':
      return t('order.order_status.cancelled', 'Cancelled');
    default:
      return '';
  }
};

export const getColor = (status: PresentableOrderStatusType): ThemeColorKeyType => {
  switch (status) {
    case 'IN_PROGRESS':
      return 'info';
    case 'DELIVERED':
      return 'selected';
    case 'PARTIALLY_DELIVERED':
      return 'warning';
    case 'ERROR':
    case 'CANCELLED':
      return 'error';
    case 'TO_BE_VALIDATED':
      return 'grey60';
    case 'PROCESSING':
    default:
      return 'brand_black';
  }
};

const getStatusProgress = (status: PresentableOrderStatusType): number => {
  switch (status) {
    case 'PROCESSING':
    case 'TO_BE_VALIDATED':
      return 25;
    case 'IN_PROGRESS':
      return 50;
    case 'DELIVERED':
    case 'PARTIALLY_DELIVERED':
    case 'CANCELLED':
    case 'ERROR':
      return 100;
    default:
      return 0;
  }
};

export const getStatusIcon = (status: PresentableOrderStatusType): IconType => {
  switch (status) {
    case 'PROCESSING':
    case 'TO_BE_VALIDATED':
      return HourglassStartIcon;
    case 'CANCELLED':
    case 'ERROR':
      return Ban;
    case 'IN_PROGRESS':
    case 'DELIVERED':
    case 'PARTIALLY_DELIVERED':
    default:
      return TruckIcon;
  }
};
const getProgressStatusType = (status: PresentableOrderStatusType): ProgressStatusType => {
  switch (status) {
    case 'CANCELLED':
    case 'ERROR':
      return 'error';
    case 'DELIVERED':
    case 'PARTIALLY_DELIVERED':
      return 'success';
    case 'IN_PROGRESS':
    case 'PROCESSING':
    case 'TO_BE_VALIDATED':
    default:
      return 'info';
  }
};

export const OrderStatusPdf = ({ orderStatus }: OrderStatusProps) => (
  <Status color={theme.color[getColor(orderStatus)]}>{getStatusLabel(orderStatus)}</Status>
);

export const OrderStatusLabelPdf = ({ orderStatus }: OrderStatusProps) => (
  <StatusLabel>{getStatusLabel(orderStatus)}</StatusLabel>
);

const OrderStatusText = styled(Text)<{ $color: ThemeColorKeyType }>`
  color: ${({ $color }) => theme.color[$color]};
`;

export const OrderStatus = ({ orderStatus }: OrderStatusProps) => {
  const color = getColor(orderStatus);
  return (
    <Flex direction={'column'} maxWidth={300} align={'flex-end'} dataCy={'status'}>
      <Box width={140}>
        <Progress
          percent={getStatusProgress(orderStatus)}
          color={color}
          status={getProgressStatusType(orderStatus)}
          showIcon
        />
      </Box>
      <Flex direction={'row'} dataCy={'status'}>
        <Icon
          IconComponent={getStatusIcon(orderStatus)}
          size={20}
          color={theme.color[color]}
          display={'block'}
          noPointer
          dataCy={'icon-status'}
        />
        <MarginBox mr={7} />
        <OrderStatusText type={'light_14'} $color={color} dataCy={'title-status'}>
          {getStatusLabel(orderStatus)}
        </OrderStatusText>
      </Flex>
    </Flex>
  );
};
