import { SEND_EMAIL_REFERENCE_INTEREST_EXADIS_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import {
  SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO,
  SEND_EMAIL_FROM_GARAGE,
  SEND_EMAIL_REFERENCE_INTEREST_EXADIS,
  SEND_EMAIL_TO_ASSISTANCE,
  SEND_RETURN_REQUEST_EMAIL,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { SendDocumentationAlertMotrio } from '@1po/1po-bff-fe-spec/generated/email/request/SendDocumentationAlertMotrio';
import { SendEmailFromGarage } from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailFromGarage';
import { SendEmailToAssistance } from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailToAssistance';
import { SendReferenceInterestToExadis } from '@1po/1po-bff-fe-spec/generated/email/request/SendReferenceInterestToExadis';
import { ReturnEmailRequest } from '@1po/1po-bff-fe-spec/generated/order/request/ReturnEmailRequest';
import { RequestResponseMappingManager } from 'domains/webSockets/WebSocket.requestsResponseMapping';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

RequestResponseMappingManager.addMapping(
  SEND_EMAIL_REFERENCE_INTEREST_EXADIS,
  SEND_EMAIL_REFERENCE_INTEREST_EXADIS_RESPONSE,
);

export function sendEmailFromGarageRequest(request: SendEmailFromGarage): WebSocketAction {
  return wsSendAction(SEND_EMAIL_FROM_GARAGE, request);
}

export function sendEmailReturnRequestRequest(request: ReturnEmailRequest): WebSocketAction {
  return wsSendAction(SEND_RETURN_REQUEST_EMAIL, request);
}

export function sendEmailExadisReferenceInterestRequest(request: SendReferenceInterestToExadis): WebSocketAction {
  return wsSendAction(SEND_EMAIL_REFERENCE_INTEREST_EXADIS, request);
}

export function sendEmailToAssistanceRequest(request: SendEmailToAssistance): WebSocketAction {
  return wsSendAction(SEND_EMAIL_TO_ASSISTANCE, request);
}

export function sendEmailDocumentationAlertMotrio(request: SendDocumentationAlertMotrio): WebSocketAction {
  return wsSendAction(SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO, request);
}
