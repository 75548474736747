/* eslint-disable max-len */
import { AddVehicleToSearchHistoryRequest } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/request/AddVehicleToSearchHistoryRequest';
import { RemoveVehicleFromSearchHistoryRequest } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/request/RemoveVehicleFromSearchHistoryRequest';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import {
  ADD_VEHICLE_TO_SEARCH_HISTORY_URL,
  CLEAR_SEARCH_HISTORY_URL,
  GET_AVAILABLE_DMS_SERVICES,
  GET_VEHICLE_SEARCH_HISTORY_URL,
  REMOVE_VEHICLE_FROM_SEARCH_HISTORY_URL,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { GetAvailableDMSServicesRequest } from '@1po/1po-bff-fe-spec/generated/dms/request/GetAvailableDMSServicesRequest';
import { AddFavoriteTireBrandRequest } from '@1po/1po-bff-fe-spec/generated/user/request/AddFavoriteTireBrandRequest';
import { CancelNotificationRequest } from '@1po/1po-bff-fe-spec/generated/user/request/CancelNotificationRequest';
import { ConnectToDelegateSession } from '@1po/1po-bff-fe-spec/generated/user/request/ConnectToDelegateSession';
import { GetPossibleBuyersForSeller } from '@1po/1po-bff-fe-spec/generated/user/request/GetPossibleBuyersForSeller';
import { IsLoggedInRequest } from '@1po/1po-bff-fe-spec/generated/user/request/IsLoggedInRequest';
import { RemoveFavoriteTireBrandRequest } from '@1po/1po-bff-fe-spec/generated/user/request/RemoveFavoriteTireBrandRequest';
import { ResetFavoriteTireBrandSelectionRequest } from '@1po/1po-bff-fe-spec/generated/user/request/ResetFavoriteTireBrandSelectionRequest';
import { SelectSellerRequest } from '@1po/1po-bff-fe-spec/generated/user/request/SelectSellerRequest';
import { UpdateCatalogSettingsRequest } from '@1po/1po-bff-fe-spec/generated/user/request/UpdateCatalogSettingsRequest';
import { UpdateUserLanguageInfoRequest } from '@1po/1po-bff-fe-spec/generated/user/request/UpdateUserLanguageInfoRequest';
import {
  ADD_FAVORITE_TIRE_BRAND_URL,
  DELEGATE_SESSION_CONNECT_URL,
  DELEGATE_SESSION_DISCONNECT_URL,
  DELEGATE_SESSION_GET_BUYERS_LIST_URL,
  DELEGATE_SESSION_GET_SELLERS_LIST_URL,
  GET_USER_PROFILE_URL,
  GET_USER_SELLERS_URL,
  REMOVE_FAVORITE_TIRE_BRAND_URL,
  RESET_FAVORITE_TIRE_BRANDS_SELECTION_URL,
  UPDATE_CATALOG_SETTINGS_URL,
  UPDATE_USER_PROFILE_LANGUAGES_URL,
  UPDATE_USER_PROFILE_SELLER_URL,
  GET_USER_NOTIFICATIONS_URL,
  CANCEL_USER_NOTIFICATION_URL,
  CANCEL_ALL_USER_NOTIFICATIONS_URL,
} from '@1po/1po-bff-fe-spec/generated/user/UserURLs';
import { WebSocketAction } from 'utils/domainStore';
import { wsSendAction } from 'utils/domainStore/api';
import { Language } from 'utils/i18n/Language';

export const TRANSLATIONS_PATH = '/translations';

export function sendGetUserProfileRequest(request: IsLoggedInRequest): WebSocketAction {
  return wsSendAction(GET_USER_PROFILE_URL, request);
}

export function sendGetAvailableDMSServicesRequest(request: GetAvailableDMSServicesRequest): WebSocketAction {
  return wsSendAction(GET_AVAILABLE_DMS_SERVICES, request);
}

export function sendUpdateUserProfileLanguagesRequest(
  webLanguage: Language,
  documentLanguage: Language,
  documentLanguageFallback?: Language,
): WebSocketAction {
  const request: UpdateUserLanguageInfoRequest = {
    webLanguage: webLanguage.key,
    documentLanguage: documentLanguage.key,
    documentLanguageFallback: documentLanguageFallback?.key,
  };

  return wsSendAction(UPDATE_USER_PROFILE_LANGUAGES_URL, request);
}

export function sendGetUserSellersRequest(): WebSocketAction {
  return wsSendAction(GET_USER_SELLERS_URL, null);
}

export function sendFetchUserNotificationsRequest(): WebSocketAction {
  return wsSendAction(GET_USER_NOTIFICATIONS_URL, null);
}

export function sendCancelNotificationRequest(request: CancelNotificationRequest): WebSocketAction {
  return wsSendAction(CANCEL_USER_NOTIFICATION_URL, request);
}

export function sendCancelAllNotificationsRequest(): WebSocketAction {
  return wsSendAction(CANCEL_ALL_USER_NOTIFICATIONS_URL, null);
}

export function sendUpdateDhCatalogReferenceViewRequestSaga(request: UpdateCatalogSettingsRequest): WebSocketAction {
  return wsSendAction(UPDATE_CATALOG_SETTINGS_URL, request);
}

export function sendUpdateUserSellerRequest(request: SelectSellerRequest): WebSocketAction {
  return wsSendAction(UPDATE_USER_PROFILE_SELLER_URL, request);
}

export function sendGetVehicleHistorySubscriptionRequest(): WebSocketAction {
  return wsSendAction(GET_VEHICLE_SEARCH_HISTORY_URL, null);
}

export function addVehicleToSearchHistoryRequest(vehicle: VehicleDetail): WebSocketAction {
  const request: AddVehicleToSearchHistoryRequest = {
    vehicle,
  };
  return wsSendAction(ADD_VEHICLE_TO_SEARCH_HISTORY_URL, request);
}

export function sendRemoveVehicleFromSearchHistoryRequest(vehicleKey: string): WebSocketAction {
  const request: RemoveVehicleFromSearchHistoryRequest = {
    vehicleKey,
  };
  return wsSendAction(REMOVE_VEHICLE_FROM_SEARCH_HISTORY_URL, request);
}

export function sendClearSearchHistoryRequest(): WebSocketAction {
  return wsSendAction(CLEAR_SEARCH_HISTORY_URL, null);
}

export function connectToDelegateSession(buyerId: string, sellerId: string): WebSocketAction {
  const request: ConnectToDelegateSession = {
    buyerId,
    sellerId,
  };
  return wsSendAction(DELEGATE_SESSION_CONNECT_URL, request);
}

export function disconnectFromConnectAsUser(): WebSocketAction {
  return wsSendAction(DELEGATE_SESSION_DISCONNECT_URL, null);
}

export function getPossibleSellersForUser(): WebSocketAction {
  return wsSendAction(DELEGATE_SESSION_GET_SELLERS_LIST_URL, null);
}

export function sendAddFavoriteTireBrandForUserRequest(request: AddFavoriteTireBrandRequest): WebSocketAction {
  return wsSendAction(ADD_FAVORITE_TIRE_BRAND_URL, request);
}

export function sendRemoveFavoriteTireBrandForUserRequest(request: RemoveFavoriteTireBrandRequest): WebSocketAction {
  return wsSendAction(REMOVE_FAVORITE_TIRE_BRAND_URL, request);
}

export function sendResetFavoriteTireBrandForUserRequest(
  request: ResetFavoriteTireBrandSelectionRequest,
): WebSocketAction {
  return wsSendAction(RESET_FAVORITE_TIRE_BRANDS_SELECTION_URL, request);
}

export function getPossibleBuyersForUser(sellerId: string): WebSocketAction {
  const request: GetPossibleBuyersForSeller = {
    sellerId,
  };
  return wsSendAction(DELEGATE_SESSION_GET_BUYERS_LIST_URL, request);
}
