/* eslint-disable max-len */
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import {
  Origin,
  ReferenceSource,
  ReferenceType,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferencesStockRequest';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { TFunction } from 'i18next';
import { matchRoute, ROUTER_CATALOG_DH_L3, ROUTER_PRODUCT } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { BagIcon, ChevronDownIcon, ChevronUpIcon, TagsIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { calculatePriceAfterDiscount, getDiscountColor, getDiscountTextStyle } from 'components/Discount';
import { ExadisInterestedButton } from 'components/ExadisInterestedButton';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import Popover from 'components/Popover';
import QuantityModule from 'components/QuantityModule';
import { QuantityModuleUpdateComponentType } from 'components/QuantityModule/QuantityModule';
import { PRICE_WRAPPER_MAX_WIDTH_NARROW, PriceErrorDisplay } from 'components/ReferencePriceSection/PriceErrorDisplay';
import { PriceUnavailable } from 'components/ReferencePriceSection/PriceUnavailable';
import { DeliveryInformationUnavailable } from 'components/ReferenceUnavailableBox/DeliveryInformationUnavailable';
import { isEmptyStock } from 'components/StockInfo/EmptyStock';
import PrivateComponent from 'composers/PrivateComponent';
import { useIsOilChunkUser } from 'domains/appContext/AppContext.requests';
import { get750ChunkReferences, getChunkReferences } from 'domains/appContext/AppContext.store';
import {
  getBasketOtherSection,
  getBasketVehicles,
  updateReferenceQuantity,
  updateReferenceQuantityRequest,
} from 'domains/basket/Basket.store';
import { removeCartReferenceInCatalog } from 'domains/catalog/Catalog.store';
import { DATAHUB } from 'domains/catalog/Catalog.types';
import { getDiscount, getIsStockAvailable, getPrice, getStockInfo, ReferencePriceType } from 'domains/references';
import { useFetchPrices } from 'domains/references/References.requests';
import { ClientView, GarageView, SparePartsViewType, UserRole } from 'domains/user';
import { theme } from 'styles';
import {
  BlackButton,
  ButtonShape,
  Flex,
  GreyButton,
  Icon,
  MarginBox,
  notifyTop,
  Spin,
  Text,
  YellowCartArrowDownButton,
} from 'UI';
import { FOUND, getData, SearchData, textFormatter } from 'utils';
import {
  TRACKING_EVENT_CART_PART_QUANTITY_ZERO,
  TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION,
  TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
} from 'utils/eventTracker/EventTracker.types';
import { PclPriceWrapper, SFlex } from './ReferencePriceSection.styled';
/* eslint-enable max-len */

export const PRICE_WRAPPER_MAX_WIDTH = 200;

export const getDiscountedPrice = (price: number, discount?: number): number => {
  if (!discount) {
    return price;
  } else {
    return price * (1 - discount / 100);
  }
};

export const getDiscountText = (t: TFunction, discountRate?: string) => {
  const formattedDiscount = textFormatter.formatPercentDecimal(Number(discountRate) || 0);
  return t('common.discount.including_discount', 'Including {{discount_in_percentage}} discount', {
    discount_in_percentage: formattedDiscount,
  });
};

const getVatExcludedText = (t: TFunction, price: number, currency?: string) =>
  t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
    vat_exclude_price: textFormatter.formatCurrency(price, currency ?? ''),
  });

const ClientDiscountView = ({
  displayDetailedView,
  setDisplayDetailedView,
  priceData,
  discount,
  referenceType,
  isNoLongerAvailable,
  isAvailableSoon,
}: {
  displayDetailedView: boolean;
  setDisplayDetailedView: (display: boolean) => void;
  priceData: ReferencePriceType | undefined;
  discount: ReferenceDiscount | undefined;
  referenceType: ReferenceType;
  isNoLongerAvailable?: boolean;
  isAvailableSoon?: boolean;
}) => {
  const { t } = useTranslation();
  const promotionDiscountValue = discount?.discount;

  if (referenceType === 'TIRE' && !isNoLongerAvailable && !isAvailableSoon) {
    return null;
  }

  const renderDetailedView = () => (
    <Flex direction="column">
      <Flex direction="row">
        <Flex minWidth={159} maxWidth={159}>
          {promotionDiscountValue && (
            <Icon IconComponent={TagsIcon} size={16} mt={2} mr={5} color={getDiscountColor(discount)} noPointer />
          )}
          <Text type="light_12_black_65" dataCy="discount-promotion">
            {getDiscountText(t, priceData?.garageView?.discountRate)}
          </Text>
        </Flex>
        <Icon
          IconComponent={ChevronUpIcon}
          size={16}
          onClick={() => setDisplayDetailedView(false)}
          hoverFill={theme.color.grey20}
          mt={1}
          ml={5}
        />
      </Flex>
      {promotionDiscountValue && (
        <Flex>
          <Text type="light_12" displayStyle={getDiscountTextStyle(discount)}>
            {textFormatter.formatPercentDecimal(promotionDiscountValue)}
          </Text>
          <MarginBox mr={5} />
          <Text type="light_12" displayStyle={getDiscountTextStyle(discount)}>
            {t('common.discount.additional_discount', 'Additional discount')}
          </Text>
        </Flex>
      )}
      {priceData && (
        <Text type="light_12_black_65" dataCy="price-vat-excl">
          {getVatExcludedText(t, calculatePriceAfterDiscount(discount, priceData, false), priceData?.currency)}
        </Text>
      )}
    </Flex>
  );
  const renderSummaryView = () => (
    <Flex direction="column">
      <Flex direction="row">
        <Flex minWidth={159} maxWidth={159}>
          {promotionDiscountValue && (
            <Icon IconComponent={TagsIcon} size={16} mt={2} mr={5} color={getDiscountColor(discount)} noPointer />
          )}
          <Text type="light_12_black_65">
            {t('common.discount.discount_code', 'DiscountCode: {{discount_code}}', {
              discount_code: priceData?.clientView?.discountCode,
            })}
          </Text>
        </Flex>
        {!isAvailableSoon && !isNoLongerAvailable && (
          <Icon
            IconComponent={ChevronDownIcon}
            size={16}
            hoverFill={theme.color.grey20}
            onClick={() => setDisplayDetailedView(true)}
            mt={1}
            ml={5}
          />
        )}
      </Flex>
      <MarginBox mt={promotionDiscountValue ? 40 : 20} />
    </Flex>
  );

  return displayDetailedView ? renderDetailedView() : renderSummaryView();
};

type ButtonSize = 'small' | 'middle' | 'large';

interface ButtonsModuleProps {
  referenceNumber: string;
  catalogSource: CatalogSource;
  handleAddToCartClick: () => void;
  stock: SearchData<ReferenceStock> | undefined;
  isAvailableSoon?: boolean;
  isNoLongerAvailable?: boolean;
  enabled?: boolean;
  withIcon?: boolean;
  showFirstHelp?: boolean;
  useCompactView?: boolean;
  externalButton?: ReactNode;
  buttonSize?: ButtonSize;
  buttonShape?: ButtonShape;
}

const ButtonsModule = ({
  referenceNumber,
  catalogSource,
  handleAddToCartClick,
  stock,
  isAvailableSoon,
  isNoLongerAvailable,
  enabled = true,
  withIcon = false,
  showFirstHelp,
  useCompactView = false,
  externalButton,
  buttonSize = 'middle',
  buttonShape = 'default',
}: ButtonsModuleProps) => {
  const { t } = useTranslation();

  const renderCartButtons = () => {
    if (useCompactView) {
      return <YellowCartArrowDownButton onClick={() => handleAddToCartClick()} round size={24} boxSize={'smd'} />;
    }
    if (!isNoLongerAvailable && !isAvailableSoon) {
      if (enabled) {
        return (
          <BlackButton
            size={buttonSize}
            onClick={() => handleAddToCartClick()}
            stretch
            dataCy={`add to cart ref-${referenceNumber}`}
            shape={buttonShape}
          >
            <Flex align={'center'} justify={'center'} gap={5}>
              {withIcon && <Icon IconComponent={BagIcon} size={20} />}
              {t('cart.action.add_to_cart', 'Add to cart')}
            </Flex>
          </BlackButton>
        );
      }
      return (
        <GreyButton
          size={buttonSize}
          onClick={() => handleAddToCartClick()}
          stretch
          dataCy={`add to cart ref-${referenceNumber}`}
          shape={buttonShape}
        >
          <Flex align={'center'} justify={'center'} gap={5}>
            {withIcon && <Icon IconComponent={BagIcon} size={20} />}
            {t('cart.action.add_to_cart', 'Add to cart')}
          </Flex>
        </GreyButton>
      );
    }
    return null;
  };

  const renderCartButtonsWithFirstHelpPopin = () => {
    const showCartButtons =
      (catalogSource === DATAHUB || (stock?.searchStatus === FOUND && !isEmptyStock(stock))) && !isNoLongerAvailable;

    return showCartButtons ? (
      <FirstHelpPopin
        streamId={ROUTER_CATALOG_DH_L3}
        popinId={`${ROUTER_CATALOG_DH_L3}_add_to_cart`}
        placement={'left'}
        skip={!showFirstHelp}
      >
        {renderCartButtons()}
      </FirstHelpPopin>
    ) : null;
  };

  return (
    <Flex
      minWidth={buttonSize === 'large' ? 250 : 180}
      maxWidth={buttonSize === 'large' ? 250 : 180}
      size={0}
      direction="column"
      align={externalButton ? undefined : 'flex-end'}
    >
      {externalButton ? (
        <Flex direction={'row'} justify={'space-between'} align={'center'} gap={20} wrap={'nowrap'}>
          {externalButton}
          {renderCartButtonsWithFirstHelpPopin()}
          <ExadisInterestedButton catalogSource={catalogSource} referenceNumber={referenceNumber} />
        </Flex>
      ) : (
        renderCartButtonsWithFirstHelpPopin()
      )}
    </Flex>
  );
};

interface OrderActionsProps {
  referenceNumber: string;
  referenceType: ReferenceType;
  catalogSource: CatalogSource;
  handleAddToCartClick: () => void;
  vehicleKey?: string;
  isApplicableToCurrentVehicle?: boolean;
  availableForOrder?: boolean;
  isAvailableSoon?: boolean;
  isNoLongerAvailable?: boolean;
  enabled?: boolean;
  origin?: Origin;
  supplierCode?: string;
  referenceSource?: ReferenceSource;
  withIcon?: boolean;
  showFirstHelp?: boolean;
  useCompactView?: boolean;
  externalButton?: ReactNode;
  buttonSize?: ButtonSize;
  buttonShape?: ButtonShape;
}

const OrderActionsSection = ({
  referenceNumber,
  referenceType,
  catalogSource,
  handleAddToCartClick,
  vehicleKey,
  isApplicableToCurrentVehicle = true,
  availableForOrder = true,
  isAvailableSoon,
  isNoLongerAvailable,
  enabled = true,
  origin,
  supplierCode,
  referenceSource,
  withIcon = false,
  showFirstHelp,
  useCompactView = false,
  externalButton,
  buttonSize = 'middle',
  buttonShape = 'default',
}: OrderActionsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const vehicles = useSelector(getBasketVehicles);
  const location = useLocation();
  const otherSection = useSelector(getBasketOtherSection);
  const stock = useSelector((state: RootState) => getStockInfo(state, referenceNumber));
  const maxQuantity = stock?.data?.maxConfirmedQuantity;
  const chunkReferences = useSelector(getChunkReferences);
  const chunk750References = useSelector(get750ChunkReferences);
  const isOilUser = useIsOilChunkUser();
  const referencesOfCurrentVeh = useMemo(
    () => vehicles.find((vehicle) => vehicle.vehicleDetail.vehicleKey === vehicleKey)?.references,
    [vehicleKey, vehicles],
  );

  const isApplicableOrInCurrVehCart = useCallback(() => {
    if (!vehicleKey) {
      return false;
    }
    return (
      isApplicableToCurrentVehicle || referencesOfCurrentVeh?.find((ref) => ref.referenceNumber === referenceNumber)
    );
  }, [isApplicableToCurrentVehicle, referenceNumber, referencesOfCurrentVeh, vehicleKey]);
  const basketReferences = isApplicableOrInCurrVehCart() ? referencesOfCurrentVeh : otherSection?.references;
  const currentBasketReference = basketReferences?.find((ref) => ref.referenceNumber === referenceNumber);

  const refQuantity = currentBasketReference?.quantity as number;
  const getChunk = () => {
    if (stock?.data?.warehouses.some((wh) => wh.type === 'LOCAL')) {
      return 1;
    }
    return isOilUser ? chunkReferences.get(referenceNumber) : undefined;
  };

  const quantityCallback = useCallback(
    (quantity: number, type?: QuantityModuleUpdateComponentType) => {
      if (quantity === 0) {
        dispatch(
          removeCartReferenceInCatalog({
            vehicleKey: isApplicableOrInCurrVehCart() ? vehicleKey : undefined,
            basketReferenceType: isApplicableOrInCurrVehCart() ? 'VEHICLE' : 'OTHER',
            referenceNumber,
            referenceType,
            origin,
            supplierCode,
            referenceSource: referenceSource ?? 'STANDARD',
          }),
        );
        if (type === 'INPUT') {
          trackAppEvent(TRACKING_EVENT_CART_PART_QUANTITY_ZERO);
        } else {
          trackAppEvent(
            matchRoute(location.pathname, ROUTER_PRODUCT)
              ? TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION
              : TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
          );
        }
      } else {
        dispatch(
          updateReferenceQuantityRequest({
            basketReferenceType: isApplicableOrInCurrVehCart() ? 'VEHICLE' : 'OTHER',
            vehicleKey: isApplicableOrInCurrVehCart() ? vehicleKey : undefined,
            externalBasketId: undefined,
            newQuantity: quantity,
            referenceNumber,
            origin,
            supplierCode,
            referenceSource,
            referenceType,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isApplicableOrInCurrVehCart, location.pathname, referenceNumber, vehicleKey],
  );

  const onMaxValueOverflow = useCallback(() => {
    if (stock?.data?.maxConfirmedQuantity !== undefined) {
      dispatch(
        updateReferenceQuantity({
          vehicleKey,
          referenceNumber,
          newQuantity: stock?.data?.maxConfirmedQuantity,
          externalBasketId: undefined,
        }),
      );
    }
    notifyTop(
      'info',
      t(
        'catalog.reference.stock.notification.parts_unavailable',
        'Sorry, Looks like the desired number of parts is currently unavailable.',
      ),
    );
  }, [dispatch, referenceNumber, stock?.data?.maxConfirmedQuantity, t, vehicleKey]);

  if (!availableForOrder) {
    return null;
  }

  return (
    <Flex direction="row">
      {currentBasketReference ? (
        <Flex align="center" gap={20}>
          {externalButton}
          <QuantityModule
            value={refQuantity}
            onChange={quantityCallback}
            showDelete
            maxValue={maxQuantity}
            onMaxValueOverflow={onMaxValueOverflow}
            chunk={getChunk()}
            isMin750Chunk={isOilUser && chunk750References.includes(referenceNumber)}
            size={externalButton ? 'middle' : buttonSize}
          />
        </Flex>
      ) : (
        <PrivateComponent
          requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
          render={() => (
            <ButtonsModule
              referenceNumber={referenceNumber}
              handleAddToCartClick={handleAddToCartClick}
              catalogSource={catalogSource}
              stock={stock}
              isAvailableSoon={isAvailableSoon}
              isNoLongerAvailable={isNoLongerAvailable}
              enabled={enabled}
              withIcon={withIcon}
              showFirstHelp={showFirstHelp}
              useCompactView={useCompactView}
              externalButton={externalButton}
              buttonSize={buttonSize}
              buttonShape={buttonShape}
            />
          )}
        />
      )}
    </Flex>
  );
};

interface PriceSectionProps {
  referenceNumber: string;
  referenceType: ReferenceType;
  sparePartsView?: SparePartsViewType;
  isMKTP?: boolean;
  align?: 'left' | 'right';
  hideDiscountSection?: boolean;
  narrow?: boolean;
  useCompactView?: boolean;
  clientDiscount?: number;
  isAvailableSoon?: boolean;
  isNoLongerAvailable?: boolean;
}

const PriceSection = ({
  referenceNumber,
  referenceType,
  isMKTP = false,
  hideDiscountSection,
  clientDiscount,
  isAvailableSoon,
  isNoLongerAvailable,
  sparePartsView = ClientView,
  useCompactView = false,
  narrow,
  align = 'right',
}: PriceSectionProps) => {
  const { t } = useTranslation();
  const price = useSelector((state: RootState) => getPrice(state, referenceNumber));
  const referenceDiscount = useSelector((state: RootState) => getDiscount(state, referenceNumber));
  const priceData = getData(price);
  const [displayDetailedView, setDisplayDetailedView] = useState<boolean>(false);
  const isTire = referenceType === ('TIRE' as ReferenceType);

  const garagePriceVatExcluded = calculatePriceAfterDiscount(referenceDiscount, priceData, false);
  const recommendedPriceVatExcluded = Number(priceData?.clientView?.recommendedPriceVatExcluded);
  const clientPriceVatExcluded = getDiscountedPrice(recommendedPriceVatExcluded, clientDiscount);
  const recommendedPriceVatIncluded = Number(priceData?.clientView?.recommendedPriceVatIncluded);
  const clientPriceVatIncluded = getDiscountedPrice(recommendedPriceVatIncluded, clientDiscount);
  const vatExcludedMargin = clientPriceVatExcluded - garagePriceVatExcluded;

  const renderGaragePrice = useCallback(() => {
    if (!priceData) {
      return <></>;
    }

    const renderPrice = () => (
      <Flex direction={'column'}>
        <Text type={'h5_bold'} displayStyle={getDiscountTextStyle(referenceDiscount)} dataCy={'price-vat-excl'}>
          {getVatExcludedText(t, garagePriceVatExcluded, priceData?.currency)}
        </Text>
        {referenceDiscount && (
          <Text type={'light_12_black_65'} decoration={'line-through'}>
            {getVatExcludedText(t, Number(priceData.garageView?.vatExcludedPrice), priceData?.currency)}
          </Text>
        )}
        {!isMKTP && (
          <Text type={'light_12_black_65'} dataCy={'price-vat-incl'}>
            {t('common.price.or_vat_include_price', 'or {{vat_include_price}} VAT. Incl', {
              vat_include_price: textFormatter.formatCurrency(
                calculatePriceAfterDiscount(referenceDiscount, priceData, true),
                priceData.currency,
              ),
            })}
          </Text>
        )}
      </Flex>
    );
    if (useCompactView) {
      return (
        <Popover
          content={
            <SFlex direction={'column'}>
              <Text type={'light_12'}>
                {t('common.price.vat_exclude_margin', '{{vat_exclude_margin}} VAT. Excl Margin', {
                  vat_exclude_margin: textFormatter.formatCurrency(vatExcludedMargin, priceData.currency),
                })}
              </Text>
              <Text type={'light_12'}>{getDiscountText(t, priceData.garageView?.discountRate)}</Text>
              {referenceDiscount && (
                <Text type={'light_12'} displayStyle={getDiscountTextStyle(referenceDiscount)}>
                  {`${referenceDiscount.discount}% ${t('common.discount.additional_discount', 'Additional discount')}`}
                </Text>
              )}
            </SFlex>
          }
          arrowColor={theme.color.white}
          placement={'top'}
        >
          {renderPrice()}
        </Popover>
      );
    } else {
      return renderPrice();
    }
  }, [garagePriceVatExcluded, isMKTP, priceData, referenceDiscount, t, useCompactView, vatExcludedMargin]);

  const renderClientPrice = useCallback(() => {
    if (!priceData) {
      return <></>;
    }

    const renderPclPrice = () => (
      <PclPriceWrapper direction={'column'} background={theme.color.grey90}>
        <Text type={'light_12_black_65'}>{t('common.price.pcl', 'PCL')}</Text>
        <Text type={'text_bold'} dataCy={'price-vat-incl'}>
          {getVatExcludedText(t, recommendedPriceVatExcluded, priceData?.currency)}
        </Text>
        <Text type={'light_12_black_65'} dataCy={'price-vat-excl'}>
          {t('common.price.or_vat_include_price', 'or {{vat_include_price}} VAT. Incl', {
            vat_include_price: textFormatter.formatCurrency(recommendedPriceVatIncluded, priceData?.currency ?? ''),
          })}
        </Text>
      </PclPriceWrapper>
    );

    const renderPrice = () => {
      const isRecommendedPclPriceEqualPclPrice = recommendedPriceVatExcluded === clientPriceVatExcluded;
      return (
        <Flex direction={'column'}>
          {isTire && (
            <>
              {!isRecommendedPclPriceEqualPclPrice && renderPclPrice()}
              <Text type={'light_12_black_85'}>
                {isRecommendedPclPriceEqualPclPrice
                  ? t('common.price.pcl', 'PCL')
                  : t('common.price.recommended_pcl', 'Recommended PCL')}
              </Text>
            </>
          )}
          <Text type={'h5_bold'} dataCy={'price-vat-incl'}>
            {getVatExcludedText(t, clientPriceVatExcluded, priceData?.currency)}
          </Text>
          {!isMKTP && (
            <Text type={'light_12_black_65'} dataCy={'price-vat-excl'}>
              {t('common.price.or_vat_include_price', 'or {{vat_include_price}} VAT. Incl', {
                vat_include_price: textFormatter.formatCurrency(clientPriceVatIncluded, priceData?.currency ?? ''),
              })}
            </Text>
          )}
        </Flex>
      );
    };
    if (useCompactView) {
      return (
        <Popover
          content={
            <SFlex direction={'column'}>
              <Text type={'light_12'}>{getDiscountText(t, priceData.garageView?.discountRate)}</Text>
              {referenceDiscount && (
                <Text type={'light_12'} displayStyle={getDiscountTextStyle(referenceDiscount)}>
                  {`${referenceDiscount.discount}% ${t('common.discount.additional_discount', 'Additional discount')}`}
                </Text>
              )}
              <Text type={'light_12'}>
                {getVatExcludedText(
                  t,
                  calculatePriceAfterDiscount(referenceDiscount, priceData, false),
                  priceData?.currency,
                )}
              </Text>
            </SFlex>
          }
          arrowColor={theme.color.white}
          placement={'top'}
        >
          <Flex>
            {renderPrice()}
            {referenceDiscount && (
              <Icon IconComponent={TagsIcon} size={16} mt={6} ml={10} color={getDiscountColor(referenceDiscount)} />
            )}
          </Flex>
        </Popover>
      );
    } else {
      return renderPrice();
    }
  }, [
    clientPriceVatExcluded,
    clientPriceVatIncluded,
    isMKTP,
    isTire,
    priceData,
    recommendedPriceVatExcluded,
    recommendedPriceVatIncluded,
    referenceDiscount,
    t,
    useCompactView,
  ]);

  const renderGaragePriceView = useCallback(
    () => (
      <Flex
        minHeight={align === 'left' ? 0 : 86}
        size={1}
        direction="column"
        justify={useCompactView ? 'center' : 'flex-start'}
      >
        {renderGaragePrice()}
        {!useCompactView && (
          <>
            {!hideDiscountSection ? (
              <>
                <Text type="light_12_black_65" dataCy="margin-vat-excl">
                  {t('common.price.vat_exclude_margin', '{{vat_exclude_margin}} VAT. Excl Margin', {
                    vat_exclude_margin: textFormatter.formatCurrency(vatExcludedMargin, priceData?.currency),
                  })}
                </Text>
                <Text type="light_12_black_65">{getDiscountText(t, priceData?.garageView?.discountRate)}</Text>
              </>
            ) : (
              <MarginBox mt={40} />
            )}
            {narrow && !referenceDiscount && <MarginBox mt={20} />}
          </>
        )}
      </Flex>
    ),

    [
      align,
      hideDiscountSection,
      narrow,
      priceData?.currency,
      priceData?.garageView?.discountRate,
      referenceDiscount,
      renderGaragePrice,
      t,
      useCompactView,
      vatExcludedMargin,
    ],
  );
  const renderClientPriceView = useCallback(
    () => (
      <Flex minHeight={align === 'left' ? 0 : 86} size={1} direction="column" justify="flex-start">
        {renderClientPrice()}
        {!useCompactView && !isMKTP && (
          <>
            <ClientDiscountView
              displayDetailedView={displayDetailedView}
              setDisplayDetailedView={setDisplayDetailedView}
              priceData={priceData}
              discount={referenceDiscount}
              isAvailableSoon={isAvailableSoon}
              isNoLongerAvailable={isNoLongerAvailable}
              referenceType={referenceType}
            />
            {narrow && !referenceDiscount && <MarginBox mt={20} />}
          </>
        )}
      </Flex>
    ),
    [
      align,
      displayDetailedView,
      isAvailableSoon,
      isMKTP,
      isNoLongerAvailable,
      narrow,
      priceData,
      referenceDiscount,
      referenceType,
      renderClientPrice,
      useCompactView,
    ],
  );
  return (
    <Flex size={1} direction={'column'} align={align === 'left' ? 'flex-start' : 'flex-end'}>
      {sparePartsView === ClientView && renderClientPriceView()}
      {sparePartsView === GarageView && priceData?.garageView && renderGaragePriceView()}
    </Flex>
  );
};

interface ReferenceCardPriceWrapperProps {
  referenceNumber: string;
  referenceType: ReferenceType;
  catalogSource: CatalogSource;
  handleAddToCartClick: () => void;
  sparePartsView?: SparePartsViewType;
  vehicleKey?: string;
  align?: 'left' | 'right';
  isApplicableToCurrentVehicle?: boolean;
  hideDiscountSection?: boolean;
  narrow?: boolean;
  showFirstHelp?: boolean;
  useCompactView?: boolean;
  availableForOrder?: boolean;
  origin?: Origin;
  supplierCode?: string;
  referenceSource?: ReferenceSource;
  enabled?: boolean;
  isMKTP?: boolean;
  clientDiscount?: number;
  withIcon?: boolean;
  buttonSize?: 'small' | 'middle' | 'large';
  buttonShape?: ButtonShape;
  isAvailableSoon?: boolean;
  isNoLongerAvailable?: boolean;
  linkedReferences?: string[];
  hideButtons?: boolean;
  hidePrice?: boolean;
  externalButton?: ReactNode;
}

export const ReferencePriceSection = React.memo(function ReferenceCardPriceWrapper({
  referenceNumber,
  sparePartsView,
  handleAddToCartClick,
  isApplicableToCurrentVehicle = true,
  vehicleKey,
  align = 'right',
  hideDiscountSection,
  catalogSource,
  referenceType,
  narrow,
  showFirstHelp = false,
  useCompactView = false,
  availableForOrder = true,
  origin,
  supplierCode,
  referenceSource,
  enabled = true,
  isMKTP = false,
  clientDiscount,
  withIcon,
  buttonSize,
  buttonShape,
  isAvailableSoon,
  isNoLongerAvailable,
  linkedReferences = undefined,
  hidePrice,
  hideButtons,
  externalButton,
}: ReferenceCardPriceWrapperProps) {
  const price = useSelector((state: RootState) => getPrice(state, referenceNumber));
  const stockAvailable = useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber));
  const availableInUserCountry = catalogSource === DATAHUB ? stockAvailable : true;
  useFetchPrices([referenceNumber, ...(linkedReferences ?? [])], vehicleKey);

  const getMaxWidth = () => {
    if (useCompactView) {
      return 'initial';
    }
    return narrow ? PRICE_WRAPPER_MAX_WIDTH_NARROW : PRICE_WRAPPER_MAX_WIDTH;
  };

  return (
    <PrivateComponent
      requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
      render={() => (
        <>
          {availableInUserCountry ? (
            <DataContainer
              data={price}
              NotFound={() => <PriceUnavailable narrow={narrow} />}
              ErrorState={() => <PriceErrorDisplay narrow={narrow} />}
              Loading={() => <Spin size={'small'} />}
            >
              <Flex
                direction={useCompactView ? 'row' : 'column'}
                maxWidth={getMaxWidth()}
                justify={align === 'left' ? 'flex-start' : 'flex-end'}
                align={useCompactView ? 'center' : 'inherit'}
              >
                {!hidePrice && (
                  <PriceSection
                    referenceNumber={referenceNumber}
                    referenceType={referenceType}
                    sparePartsView={sparePartsView}
                    useCompactView={useCompactView}
                    isMKTP={isMKTP}
                    hideDiscountSection={hideDiscountSection}
                    clientDiscount={clientDiscount}
                    isAvailableSoon={isAvailableSoon}
                    isNoLongerAvailable={isNoLongerAvailable}
                    align={align}
                    narrow={narrow}
                  />
                )}
                {useCompactView && <MarginBox ml={15} />}
                {!hideButtons && (
                  <OrderActionsSection
                    referenceNumber={referenceNumber}
                    referenceType={referenceType}
                    catalogSource={catalogSource}
                    handleAddToCartClick={handleAddToCartClick}
                    vehicleKey={vehicleKey}
                    isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
                    availableForOrder={availableForOrder}
                    isAvailableSoon={isAvailableSoon}
                    isNoLongerAvailable={isNoLongerAvailable}
                    enabled={enabled}
                    origin={origin}
                    supplierCode={supplierCode}
                    referenceSource={referenceSource}
                    withIcon={withIcon}
                    showFirstHelp={showFirstHelp}
                    useCompactView={useCompactView}
                    externalButton={externalButton}
                    buttonSize={buttonSize}
                    buttonShape={buttonShape}
                  />
                )}
              </Flex>
            </DataContainer>
          ) : (
            <DeliveryInformationUnavailable />
          )}
        </>
      )}
    />
  );
});
