import React from 'react';
// eslint-disable-next-line max-len
import { UniversalProductsCategory } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsTreeResponse';
import { UNIVERSAL_PRODUCTS_LINK } from 'app/AppRouter';
import { CategoryCard } from 'pages/UniversalProductsPage/Menu/Menu.styled';
import { Box, Flex, Link, MarginBox, Text } from 'UI';

interface SubCategoriesProps {
  openCategoryId: string;
  level2Categories: UniversalProductsCategory[];
}

const SubCategories = ({ openCategoryId, level2Categories }: SubCategoriesProps) => {
  return (
    <Box>
      <Flex wrap={'wrap'} gap={15}>
        {level2Categories.map((level2Category) => {
          return (
            <CategoryCard key={level2Category.nodeId}>
              <Flex direction={'column'} gap={8}>
                <Text type={'h6'} transform={'uppercase'}>
                  {level2Category.name}
                </Text>
                {level2Category.childCategories?.map((level3Category) => {
                  return (
                    <MarginBox ml={5} key={level3Category.nodeId}>
                      <Link
                        to={`/${UNIVERSAL_PRODUCTS_LINK}/${openCategoryId}/${level2Category.nodeId}/${level3Category.nodeId}`}
                      >
                        <Text type={'text_dim'} cursor={'pointer'} hoverUnderLine>
                          {level3Category.name}
                        </Text>
                      </Link>
                    </MarginBox>
                  );
                })}
              </Flex>
            </CategoryCard>
          );
        })}
      </Flex>
    </Box>
  );
};

export default SubCategories;
