import React, { FocusEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FreeBundleField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateFreeBundle';
import { InputRef, Menu } from 'antd';
import { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import {
  addBundleFromAutocomplete,
  getBundleFulltextAutocomplete,
  sendAutocompleteBundlesRequest,
} from 'domains/estimate/Estimate.store';
import { BundleSearchParams } from 'domains/estimate/Estimate.types';
import { getUserDisplayMode } from 'domains/user';
import FreeBundleAutocompleteMenu from 'pages/EstimatePage/TableSection/FreeBundleAutocompleteMenu';
import { isEmpty } from 'pages/EstimatePage/TableSection/tableComponents';
import { getData, hasData, useFocus } from 'utils';
import { SearchContainer, SSearchInput } from './FreeBundleAutocomplete.styled';

const FreeBundleAutocomplete = ({
  value,
  saveFunction,
  rowId,
  fieldId,
}: {
  value: string;
  saveFunction: (value: string) => void;
  rowId: string;
  fieldId: FreeBundleField;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const userDisplayMode = useSelector(getUserDisplayMode);
  const inputReference = React.useRef<InputRef>(null);
  const menuReference = useRef<Menu>(null);

  const [bundleSearch, setBundleSearch] = useState<BundleSearchParams>({
    queryCode: undefined,
    queryDescription: undefined,
    pending: false,
    isValid: false,
    open: false,
    focusMenu: undefined,
    searchId: undefined,
  });

  useEffect(() => {
    setBundleInputValue(value);
  }, [value]);

  const [bundleInputValue, setBundleInputValue] = useState(value);
  const results = useSelector((state: RootState) => getBundleFulltextAutocomplete(state));
  const resultsLength = getData(results)?.length ?? 0;
  useFocus(inputReference);

  useEffect(() => {
    if (
      bundleSearch &&
      !bundleSearch.pending &&
      (bundleSearch.queryCode || bundleSearch.queryDescription) &&
      bundleSearch.isValid
    ) {
      dispatch(
        sendAutocompleteBundlesRequest({
          queryCode: bundleSearch.queryCode ?? '',
          queryDescription: bundleSearch.queryDescription ?? '',
        }),
      );
      setBundleSearch({ ...bundleSearch, pending: true });
    }
  }, [dispatch, bundleSearch]);

  const SearchInputID = `search-bundle-${fieldId}-fulltext-id`;
  const SearchInputName = `search-bundle-${fieldId}-fulltext-name`;

  const onCodeDebouncedChange = useCallback(
    (val: string) => {
      setBundleSearch(
        val?.length > 2
          ? {
              ...bundleSearch,
              queryCode: val.toLowerCase(),
              pending: false,
              isValid: true,
              open: true,
              focusMenu: undefined,
            }
          : {
              ...bundleSearch,
              queryCode: '',
              pending: false,
              isValid: false,
              open: false,
              focusMenu: undefined,
            },
      );
    },
    [bundleSearch],
  );

  const onDescriptionDebouncedChange = useCallback(
    (val: string) => {
      setBundleSearch(
        val?.length > 2
          ? {
              ...bundleSearch,
              queryDescription: val.toLowerCase(),
              pending: false,
              isValid: true,
              open: true,
              focusMenu: undefined,
            }
          : {
              ...bundleSearch,
              queryDescription: '',
              pending: false,
              isValid: false,
              open: false,
              focusMenu: undefined,
            },
      );
    },
    [bundleSearch],
  );

  const handleClickSelection = useCallback(
    (label: string, id: string, code: string, price: string) => () => {
      const input = fieldId === 'CODE' ? code : label;
      if (!input) {
        return;
      }
      const searchId = uuidv4();
      setBundleInputValue(input);
      setBundleSearch({
        queryCode: fieldId === 'CODE' ? code : '',
        queryDescription: fieldId === 'NAME' ? label : '',
        pending: false,
        isValid: true,
        open: false,
        focusMenu: undefined,
        searchId,
      });
      const bundle = {
        id,
        code,
        designation: label,
        price,
      };
      dispatch(addBundleFromAutocomplete({ bundle, itemId: rowId }));
    },
    [dispatch, rowId, fieldId],
  );

  const handleQueryInputValue =
    fieldId === 'NAME'
      ? handleClickSelection(bundleInputValue.trim(), '', '', '')
      : handleClickSelection('', '', bundleInputValue.trim(), '');

  const handleMove = useCallback(
    (offset: number) => {
      if (bundleSearch.open) {
        setBundleSearch({
          ...bundleSearch,
          focusMenu: bundleSearch?.focusMenu ? (bundleSearch.focusMenu + offset) % resultsLength : 0,
        });
      }
    },
    [resultsLength, bundleSearch],
  );

  const handleDown = useCallback(() => handleMove(1), [handleMove]);
  const handleUp = useCallback(() => handleMove(-1), [handleMove]);

  const handleKeyPress = useCallback(
    (event: { key: string }) => {
      if (event.key === 'Escape') {
        setBundleSearch({ ...bundleSearch, open: false, focusMenu: 0 });
      }
      if (event.key === 'ArrowUp') {
        handleUp();
      }
      if (event.key === 'ArrowDown') {
        handleDown();
      }
    },
    [handleDown, handleUp, bundleSearch, setBundleSearch],
  );

  const handleBlurInput = (_val: string | undefined, e?: FocusEvent) => {
    if (bundleSearch?.open && bundleSearch.focusMenu === undefined && !e?.relatedTarget?.id.includes('search-menu')) {
      setBundleSearch({ ...bundleSearch, open: false });
      saveFunction(fieldId === 'CODE' ? bundleSearch.queryCode ?? '' : bundleSearch.queryDescription ?? '');
    }
  };

  return (
    <SearchContainer>
      <SSearchInput
        $isInvalid={isEmpty(value) && fieldId === 'NAME'}
        id={SearchInputID}
        name={SearchInputName}
        autoComplete={`on section-${SearchInputName}`}
        value={bundleInputValue}
        onChange={(val) => setBundleInputValue(val)}
        onChangeDebounced={fieldId === 'CODE' ? onCodeDebouncedChange : onDescriptionDebouncedChange}
        onPressEnter={handleQueryInputValue}
        onKeyPress={handleKeyPress}
        onBlur={handleBlurInput}
        displayMode={userDisplayMode}
        color={theme.displayMode[userDisplayMode].searchFontColor}
        passRef={inputReference}
        placeholder={t('estimate.bundles.search_by_description', 'Search bundles')}
      />
      {hasData(results) && results?.length > 0 && bundleSearch?.open && (
        <FreeBundleAutocompleteMenu
          results={results}
          search={bundleSearch}
          setSearch={setBundleSearch}
          onClick={handleClickSelection}
          inputValue={bundleInputValue}
          menuRef={menuReference}
          field={fieldId}
        />
      )}
    </SearchContainer>
  );
};

export default FreeBundleAutocomplete;
