/* eslint-disable max-len */
import {
  ReferenceSource,
  ReferenceStockTradingDataRequestDetail,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferencesStockRequest';
import { FilterAndSort } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterAndSort';
import { FilterArray } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterArray';
import { FilterBy } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterBy';
import { SortBy } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/SortBy';
import { MyStoreBundleItem } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddFreeBundlesFromMyStore';
import { LinkedReference } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddReferenceFromCatalog';
import { AddVehicle } from '@1po/1po-bff-fe-spec/generated/estimate/request/AddVehicle';
import { ReferenceTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/estimate/request/GetReferenceTradingDataForB2BEstimateRequest';
import { FilterAndSortField } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/FilterAndSortField';
import { AddReferenceByRefNumber } from '@1po/1po-bff-fe-spec/generated/estimate/response/AddReferenceByRefNumber';
import { AutocompleteSearchBundlesResponse } from '@1po/1po-bff-fe-spec/generated/estimate/response/AutocompleteSearchBundlesResponse';
import { CheckoutB2BEstimateOrderResponse } from '@1po/1po-bff-fe-spec/generated/estimate/response/CheckoutB2BEstimateOrderResponse';
import { EstimateDmsOrderNumbers } from '@1po/1po-bff-fe-spec/generated/estimate/response/EstimateDmsOrderNumbers';
import {
  GetEstimate,
  LinkedReference as LinkedRef,
} from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { GetEstimateHistory } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimateHistory';
import { GetReferencesDiscountsForB2BEstimateResponse } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetReferencesDiscountsForB2BEstimateResponse';
import { GetReferencesPriceForB2BEstimateResponse } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetReferencesPriceForB2BEstimateResponse';
import { GetReferencesStocksForB2BEstimateResponse } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetReferencesStocksForB2BEstimateResponse';
import { GetSettings } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetSettings';
import { RemovedB2BEstimateReferencesDiscountsResponse } from '@1po/1po-bff-fe-spec/generated/estimate/response/RemovedB2BEstimateReferencesDiscountsResponse';
import { call, put, putResolve, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import {
  getLastSearchedVehicleKey,
  getLastVehicleDetail,
  setLastSearchedVehicleKey,
} from 'domains/catalog/Catalog.store';
import {
  fillFromMaintenancePlan,
  sendAddBundlesAutocomplete,
  sendAddBundlesFromMyStore,
  sendAddCatalogLaborTime,
  sendAddCatalogReference,
  sendAddCatalogReferencesToBasket,
  sendAddCustomItem,
  sendAddCustomSetting,
  sendAddDmsOrderNumber,
  sendAddLaborTimeByCode,
  sendAddReferenceByReferenceNumber,
  sendAddReferenceNumber,
  sendAddTire,
  sendAddTireByReferenceNumber,
  sendAddVehicle,
  sendCancelDeletionRequest,
  sendCheckoutB2BRequest,
  sendClearItems,
  sendDeleteEstimateRequest,
  sendEstimateToDMS,
  sendGetEstimateById,
  sendGetHistory,
  sendGetLatestEstimate,
  sendGetReferencesPriceRequest,
  sendGetReferencesStocksRequest,
  sendGetRepairOrderRequest,
  sendGetSettings,
  sendQueryAutocompleteBundlesRequest,
  sendRemoveCustomSetting,
  sendRemoveItem,
  sendUpdateB2BCounterParties,
  sendUpdateClientContact,
  sendUpdateFreeBundle,
  sendUpdateLaborTime,
  sendUpdateObservations,
  sendUpdateOtherItem,
  sendUpdateReference,
  sendUpdateSettings,
  sendUpdateTire,
  sendUpdateVehicle,
  sendUpdateWasteRecycling,
  sendUpdateWasteRecyclingSettings,
} from 'domains/estimate/Estimate.api';
import * as actions from 'domains/estimate/Estimate.store';
import {
  addReferenceForValidation,
  createNewEstimate,
  getCatalogReferences,
  getCatalogTires,
  getCurrentEstimateId,
  getEstimateById,
  getEstimateHistoryCursor,
  getEstimateHistorySearch,
  getEstimateVehicleKey,
  getReferences,
  getTires,
  removeB2BEstimateReferencesDiscounts,
  removeReferenceForValidation,
  resetEstimateHistory,
  resetSellerBuyerInfo,
  setB2BEstimateReferencesDiscountsData,
  setB2BEstimateReferencesPriceData,
  setB2BEstimateReferencesPriceNoDataStatus,
  setB2BEstimateReferencesStockData,
  setB2BEstimateReferencesStockNoDataStatus,
  setBundleFulltextAutocomplete,
  setBundleLastAutocompleteKey,
  setCurrentEstimateId,
  setDmsOrderNumbers,
  setEstimateDataStatus,
  setEstimateHidden,
  setEstimateHistoryResponse,
  setEstimateHistorySearchStatus,
  setEstimateRemoved,
  setEstimateResponse,
  setHistoryEstimate,
  setOrderEstimateStatus,
  setSettings,
} from 'domains/estimate/Estimate.store';
import { RequestReference } from 'domains/estimate/Estimate.types';
import { getUserGarageWorkshopId } from 'domains/garage/Garage.store';
import { clearCheckoutStatus, setCheckoutStatus } from 'domains/order/Order.store';
import { DHReferenceLocal, getDHReferences, getLoadedPrices, ReferencePriceType } from 'domains/references';

import { getTradingProfile, getUserContext, hideDMSErrorPopup } from 'domains/user';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { notifyTop } from 'UI/Notification/notification';
import { AppTranslation, FOUND, getData, hasData, LOADING, NO_DATA, sagaGuard } from 'utils';
import { select } from 'utils/domainStore';

export function* fetchLatestEstimateRequest(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const vehicleKey = yield* select(getLastSearchedVehicleKey);

  if (!tradingProfile?.buyerId) {
    return;
  }

  yield put(sendGetLatestEstimate({ vehicleKey }));
}

export function* fetchEstimateByIdResponse(action: WsResponse<GetEstimate>): SagaIterator {
  const payload = action.payload;
  const { estimate } = payload;
  const { estimateId, clientContact } = estimate;
  const tradingProfile = yield* select(getTradingProfile);

  const storedEstimate = getData(yield* select((state: RootState) => getEstimateById(state, estimateId)));
  const buyerTresorCode = clientContact.tresorCode === undefined ? tradingProfile?.buyerId : clientContact.tresorCode;
  const sellerTresorCode =
    clientContact.sellerTresorCode === undefined ? tradingProfile?.sellerId : clientContact.sellerTresorCode;
  const userContext = yield* select(getUserContext);

  if (estimate.estimateType === 'B2B' && buyerTresorCode !== undefined && sellerTresorCode !== undefined) {
    const catalogReferences = estimate.referenceSubsection.references.filter((ref) => !ref.isCustom);
    const catalogTires = estimate.tireSubsection.references.filter((ref) => !ref.isCustom);
    const tireRefNumber = catalogTires.map((t) => t.referenceNumber);
    const allItems = [...catalogReferences, ...catalogTires];
    const referenceNumbers = allItems.map((r) => r.referenceNumber);

    const referencesWithoutPrice = allItems
      .filter((ref) => !storedEstimate?.prices?.has(ref.referenceNumber))
      .map((ref) => {
        return {
          referenceNumber: ref.referenceNumber,
          commercialFamily: ref.commercialFamily ?? '',
          origin: 'origin' in ref ? ref.origin : undefined,
          supplierCode: 'supplierCode' in ref ? ref.supplierCode : undefined,
          referenceSource: 'referenceSource' in ref ? ref.referenceSource : undefined,
        } as ReferenceTradingDataRequestDetail;
      });

    const referencesWithoutStock = allItems
      .filter((ref) => {
        const currStock = storedEstimate?.stockInfo?.get(ref.referenceNumber);
        return currStock === undefined || currStock?.data?.confirmedQuantity !== ref.quantity;
      })
      .map((ref) => {
        return {
          referenceNumber: ref.referenceNumber,
          type: tireRefNumber.includes(ref.referenceNumber) ? 'TIRE' : 'STANDARD',
          quantity: ref.quantity,
          origin: 'origin' in ref ? ref.origin : undefined,
          supplierCode: 'supplierCode' in ref ? ref.supplierCode : undefined,
          referenceSource: 'referenceSource' in ref ? ref.referenceSource : undefined,
        } as ReferenceStockTradingDataRequestDetail;
      });

    if (referencesWithoutPrice.length > 0) {
      yield put(
        setB2BEstimateReferencesPriceNoDataStatus({
          estimateId,
          buyerTresorCode,
          sellerTresorCode,
          referenceNumbers,
          status: LOADING,
        }),
      );
      yield put(sendGetReferencesPriceRequest(estimateId, buyerTresorCode, sellerTresorCode, referencesWithoutPrice));
    }

    if (referencesWithoutStock.length > 0) {
      yield put(
        setB2BEstimateReferencesStockNoDataStatus({
          estimateId,
          buyerTresorCode,
          sellerTresorCode,
          referenceNumbers,
          status: LOADING,
        }),
      );
      yield put(
        sendGetReferencesStocksRequest(
          estimateId,
          buyerTresorCode,
          sellerTresorCode,
          referencesWithoutStock,
          userContext,
        ),
      );
    }
  }
  yield put(setEstimateResponse(payload));
  yield put(setHistoryEstimate(payload));
}

export function* fetchDeleteEstimateByIdResponse(action: WsResponse<string>): SagaIterator {
  const estimateId = action.payload;

  yield put(setEstimateRemoved({ estimateId }));
}

export function* fetchHideEstimateByIdResponse(action: WsResponse<string>): SagaIterator {
  const estimateId = action.payload;
  yield put(setEstimateHidden({ estimateId, isHidden: true }));
}

export function* fetchShowEstimateByIdResponse(action: WsResponse<string>): SagaIterator {
  const estimateId = action.payload;
  yield put(setEstimateHidden({ estimateId, isHidden: false }));
}

export function* fetchEstimateCreatedResponse(action: WsResponse<string>): SagaIterator {
  yield put(resetEstimateHistory());
  yield put(actions.fetchEstimateHistoryRequest());
  yield put(actions.fetchEstimateByIdRequest(action.payload));
  yield put(setCurrentEstimateId(action.payload));
}

export function* fetchEstimateHistoryRequest(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const cursor = yield* select(getEstimateHistoryCursor);
  const search = yield* select(getEstimateHistorySearch);

  if (!tradingProfile?.buyerId) return;

  let orFilter = undefined;
  if (search) {
    orFilter = new FilterArray<FilterAndSortField>('OR', [
      new FilterBy<FilterAndSortField>('VIN', search, 'CONTAINS'),
      new FilterBy<FilterAndSortField>('VRN', search, 'CONTAINS'),
      new FilterBy<FilterAndSortField>('SEQUENCE_NUMBER', search, 'CONTAINS'),
      new FilterBy<FilterAndSortField>('FULL_NAME', search, 'CONTAINS'),
      new FilterBy<FilterAndSortField>('RRF_CODE', search, 'CONTAINS'),
      new FilterBy<FilterAndSortField>('CREATOR_IPN', search, 'CONTAINS'),
    ]);
  }
  const filterAndSort: FilterAndSort<FilterAndSortField> = {
    filter: orFilter,
    sorts: [new SortBy('SEQUENCE_NUMBER', 'DESC')],
    cursor,
  };

  yield put(setEstimateHistorySearchStatus(LOADING));
  yield put(sendGetHistory({ filterAndSort }));
}

export function* fetchEstimateByIdRequest({
  payload,
}: ReturnType<typeof actions.fetchEstimateByIdRequest>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = payload;

  if (!tradingProfile?.buyerId || !estimateId) {
    return;
  }

  yield put(setEstimateDataStatus({ estimateId, status: LOADING }));
  yield put(sendGetEstimateById({ estimateId }));
}

export function* fetchEstimateHistoryResponse(action: WsResponse<GetEstimateHistory>): SagaIterator {
  yield put(setEstimateHistoryResponse(action.payload));
}

export function* addCatalogLaborTime({ payload }: ReturnType<typeof actions.addCatalogLaborTime>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);

  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendAddCatalogLaborTime({
      estimateId,
      vehicleDetail,
      ...payload,
    }),
  );
}

export function* addCustomSetting(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const itemId = uuidv4();

  if (!tradingProfile?.buyerId) return;

  yield put(sendAddCustomSetting({ itemId }));
}

export function* removeCustomSetting({ payload }: ReturnType<typeof actions.removeCustomSetting>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const { itemId } = payload;

  if (!tradingProfile?.buyerId) return;

  yield put(sendRemoveCustomSetting({ itemId }));
}

export function* addBundleFromMyStore({ payload }: ReturnType<typeof actions.addBundleFromMyStore>): SagaIterator {
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  const converted: MyStoreBundleItem[] = payload.map((i) => ({
    id: i.id || '',
    code: i.code || '',
    designation: i.designation || '',
    price: i.price,
  }));

  yield put(
    sendAddBundlesFromMyStore({
      estimateId,
      vehicleDetail,
      myStoreBundles: [...converted],
    }),
  );
}

export function* addBundleFromAutocomplete({
  payload,
}: ReturnType<typeof actions.addBundleFromAutocomplete>): SagaIterator {
  const estimateId = yield* select(getCurrentEstimateId);
  const { bundle, itemId } = payload;
  const converted: MyStoreBundleItem = {
    id: bundle.id || '',
    code: bundle.code || '',
    designation: bundle.designation || '',
    price: bundle.price,
  };
  yield put(
    sendAddBundlesAutocomplete({
      estimateId,
      bundle: converted,
      itemId,
    }),
  );
}

function mapLinkedReference(
  reference: RequestReference,
  linkedRef: DHReferenceLocal,
  linkedRefPrices: { prices: NO_DATA | ReferencePriceType; reference: string }[],
) {
  const ratio =
    reference.linkedReferences?.find((ref) => ref.referenceNumber === linkedRef.referenceNumber)?.ratio ?? 1;
  const price = linkedRefPrices.find((refPrice) => refPrice.reference === linkedRef.referenceNumber)?.prices;
  const unitPrice =
    hasData(price) && price.clientView?.recommendedPriceVatExcluded
      ? price.clientView.recommendedPriceVatExcluded?.toString()
      : '';
  const priceVatExcluded =
    hasData(price) && price.clientView?.recommendedPriceVatExcluded
      ? price.clientView.recommendedPriceVatExcluded?.toString()
      : '';
  const garagePrice =
    hasData(price) && price.garageView?.vatExcludedPrice ? price.garageView.vatExcludedPrice?.toString() : '';
  const vatPercentage = hasData(price) && price.garageView?.vatPercentage ? price.garageView.vatPercentage : 20;
  const discountRate = hasData(price) ? price.garageView?.discountRate : '';
  return {
    referenceNumber: linkedRef.referenceNumber,
    designation: linkedRef.name,
    catalogSource: 'DATAHUB',
    quantity: ratio,
    unitPrice,
    priceVatExcluded,
    vatPercentage,
    garagePrice,
    discountRate,
    ratio,
    isCompatible: linkedRef.isApplicableToCurrentVehicle ?? true,
  } as LinkedReference;
}

export function* addCatalogReference({ payload }: ReturnType<typeof actions.addCatalogReference>): SagaIterator {
  const { reference } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const linkedRefNumbers = reference.linkedReferences?.map((linkedRef) => linkedRef.referenceNumber) ?? [];
  const linkedRefDetails = yield* select((state: RootState) =>
    getDHReferences(state, {
      vehicleKey: estimateVehicleKey,
      referenceNumbers: linkedRefNumbers,
    }),
  );
  const linkedRefPrices = yield* select((state: RootState) => getLoadedPrices(state, linkedRefNumbers ?? []));

  const linkedReferencesWithRatio = linkedRefDetails.map((r) => mapLinkedReference(reference, r, linkedRefPrices));

  const referenceWithLinkedDetails = {
    ...reference,
    linkedReferences: linkedReferencesWithRatio.filter((linkedRef) => linkedRef.garagePrice),
  };

  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendAddCatalogReference({
      vehicleDetail,
      reference: referenceWithLinkedDetails,
      estimateId,
    }),
  );
}

export function* addCustomReference(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();

  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,

      itemType: 'REFERENCE',
    }),
  );
}

export function* addCustomLaborTime(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();

  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,

      itemType: 'LABOR_TIME',
    }),
  );
}

export function* addCustomWasteRecycling(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();

  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,

      itemType: 'WASTE_RECYCLING',
    }),
  );
}

export function* addCustomFreeBundle(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();
  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,

      itemType: 'FREE_BUNDLE',
    }),
  );
}

export function* addCatalogTire({ payload }: ReturnType<typeof actions.addCatalogTire>): SagaIterator {
  const { reference } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);

  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendAddTire({
      vehicleDetail,
      reference,
      estimateId,
      userContext,
    }),
  );
}

export function* addCustomTire(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();

  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,

      itemType: 'TIRE',
    }),
  );
}

export function* addOtherItem(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const vehicleDetail = estimateVehicleKey === undefined ? undefined : yield* select(getLastVehicleDetail);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const itemId = uuidv4();

  yield put(
    sendAddCustomItem({
      vehicleDetail,
      itemId,
      estimateId,

      itemType: 'OTHER_ITEM',
    }),
  );
}

export function* updateReference({ payload }: ReturnType<typeof actions.updateReference>): SagaIterator {
  const { itemId, parentItemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  if (field === 'REFERENCE_NUMBER' && newValue.length >= 10) {
    yield put(
      actions.addCatalogReferenceByReferenceNumber({
        referenceNumber: newValue,
        searchType: 'CUSTOM_ITEM',
        itemType: 'STANDARD',
        itemId,
      }),
    );
    return;
  }

  yield put(
    sendUpdateReference({
      itemId,
      parentItemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* updateLaborTime({ payload }: ReturnType<typeof actions.updateLaborTime>): SagaIterator {
  const { itemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendUpdateLaborTime({
      itemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* updateTire({ payload }: ReturnType<typeof actions.updateTire>): SagaIterator {
  const { itemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  if (field === 'REFERENCE_NUMBER' && newValue.length >= 10) {
    yield put(
      actions.addCatalogTireByReferenceNumber({
        referenceNumber: newValue,
        searchType: 'CUSTOM_ITEM',
        itemType: 'TIRE',
        itemId,
      }),
    );
    return;
  }

  yield put(
    sendUpdateTire({
      itemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* updateOtherItem({ payload }: ReturnType<typeof actions.updateOtherItem>): SagaIterator {
  const { itemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendUpdateOtherItem({
      itemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* updateFreeBundle({ payload }: ReturnType<typeof actions.updateFreeBundle>): SagaIterator {
  const { itemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendUpdateFreeBundle({
      itemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* updateWasteRecycling({ payload }: ReturnType<typeof actions.updateWasteRecycling>): SagaIterator {
  const { itemId, newValue, field } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendUpdateWasteRecycling({
      itemId,
      newValue,
      field,
      estimateId,
    }),
  );
}

export function* removeReference({ payload }: ReturnType<typeof actions.removeReference>): SagaIterator {
  const { itemId, parentItemId } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      itemId,
      parentItemId,
      itemType: 'REFERENCE',
      estimateId,
    }),
  );
}

export function* removeLaborTime({ payload }: ReturnType<typeof actions.removeLaborTime>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      itemId: payload,
      itemType: 'LABOR_TIME',
      estimateId,
    }),
  );
}

export function* removeWasteRecycling({ payload }: ReturnType<typeof actions.removeWasteRecycling>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      itemId: payload,
      itemType: 'WASTE_RECYCLING',
      estimateId,
    }),
  );
}

export function* removeTire({ payload }: ReturnType<typeof actions.removeTire>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      itemId: payload,
      itemType: 'TIRE',
      estimateId,
    }),
  );
}

export function* removeOtherItem({ payload }: ReturnType<typeof actions.removeOtherItem>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      itemId: payload,
      itemType: 'OTHER_ITEM',
      estimateId,
    }),
  );
}

export function* removeFreeBundle({ payload }: ReturnType<typeof actions.removeFreeBundle>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendRemoveItem({
      itemId: payload,
      itemType: 'FREE_BUNDLE',
      estimateId,
    }),
  );
}

export function* removeAllReferences(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ estimateId, itemType: 'REFERENCE' }));
}

export function* removeAllLaborTimes(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ estimateId, itemType: 'LABOR_TIME' }));
}

export function* removeAllWasteRecycling(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ estimateId, itemType: 'WASTE_RECYCLING' }));
}

export function* removeAllFreeBundles(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ estimateId, itemType: 'FREE_BUNDLE' }));
}

export function* removeAllTires(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ estimateId, itemType: 'TIRE' }));
}

export function* removeAllOtherItems(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;
  yield put(sendClearItems({ estimateId, itemType: 'OTHER_ITEM' }));
}

export function* deleteEstimateRequest({ payload }: ReturnType<typeof actions.deleteEstimateRequest>): SagaIterator {
  const { estimateId } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  const currentEstimateId = yield* select(getCurrentEstimateId);

  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendDeleteEstimateRequest({
      estimateId,
    }),
  );

  yield put(setEstimateHidden({ estimateId, isHidden: true }));

  if (currentEstimateId === estimateId) {
    yield put(createNewEstimate({ vehicle: undefined }));
    yield put(setLastSearchedVehicleKey(undefined));
  }
}

export function* cancelEstimateDeletionRequest({
  payload,
}: ReturnType<typeof actions.cancelEstimateDeletion>): SagaIterator {
  const { estimateId } = payload;
  const tradingProfile = yield* select(getTradingProfile);

  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendCancelDeletionRequest({
      estimateId,
    }),
  );

  yield put(setEstimateHidden({ estimateId, isHidden: false }));
}

export function* updateClient({ payload }: ReturnType<typeof actions.updateClient>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!tradingProfile?.buyerId || !estimateId) return;
  const { newValue, field } = payload;

  yield put(
    sendUpdateClientContact({
      estimateId,
      originalVehicle: vehicleDetail,
      newValue,
      field,
    }),
  );
}

export function* updateB2BCounterParties({
  payload,
}: ReturnType<typeof actions.updateB2BCounterParties>): SagaIterator {
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateData = getData(yield* select((state: RootState) => getEstimateById(state, estimateId)));
  const { buyerTresorCode, sellerTresorCode } = payload;
  const userContext = yield* select(getUserContext);
  const catalogReferences = estimateData?.referenceSubsection.references.filter((ref) => !ref.isCustom) ?? [];
  const catalogTires = estimateData?.tireSubsection.references.filter((ref) => !ref.isCustom) ?? [];
  const tireRefNumber = catalogTires.map((t) => t.referenceNumber);
  const allItems = [...catalogReferences, ...catalogTires];
  const referenceNumbers = allItems.map((r) => r.referenceNumber);

  const referencesPriceRequest = allItems.map((ref) => {
    return {
      referenceNumber: ref.referenceNumber,
      commercialFamily: ref.commercialFamily ?? '',
      origin: 'origin' in ref ? ref.origin : undefined,
      supplierCode: 'supplierCode' in ref ? ref.supplierCode : undefined,
      referenceSource: 'referenceSource' in ref ? ref.referenceSource : undefined,
    } as ReferenceTradingDataRequestDetail;
  });

  const referencesStockRequest = allItems.map((ref) => {
    return {
      referenceNumber: ref.referenceNumber,
      type: tireRefNumber.includes(ref.referenceNumber) ? 'TIRE' : 'STANDARD',
      quantity: ref.quantity,
      origin: 'origin' in ref ? ref.origin : undefined,
      supplierCode: 'supplierCode' in ref ? ref.supplierCode : undefined,
      referenceSource: 'referenceSource' in ref ? ref.referenceSource : undefined,
    } as ReferenceStockTradingDataRequestDetail;
  });

  yield put(resetSellerBuyerInfo(estimateId));
  yield put(
    sendUpdateB2BCounterParties({
      estimateId,
      sellerTresorCode,
      buyerTresorCode,
    }),
  );

  if (referencesPriceRequest.length > 0) {
    yield put(
      setB2BEstimateReferencesPriceNoDataStatus({
        estimateId,
        buyerTresorCode,
        sellerTresorCode,
        referenceNumbers,
        status: LOADING,
      }),
    );
    yield put(sendGetReferencesPriceRequest(estimateId, buyerTresorCode, sellerTresorCode, referencesPriceRequest));
  }

  if (referencesStockRequest.length > 0) {
    yield put(
      setB2BEstimateReferencesStockNoDataStatus({
        estimateId,
        buyerTresorCode,
        sellerTresorCode,
        referenceNumbers,
        status: LOADING,
      }),
    );
    yield put(
      sendGetReferencesStocksRequest(
        estimateId,
        buyerTresorCode,
        sellerTresorCode,
        referencesStockRequest,
        userContext,
      ),
    );
  }
}

export function* updateObservations({ payload }: ReturnType<typeof actions.updateObservations>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !estimateId) return;

  yield put(
    sendUpdateObservations({
      estimateId,
      originalVehicle: vehicleDetail,
      newValue: payload,
    }),
  );
}

export function* addVehicle(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !vehicleDetail || !estimateId) return;

  const request: AddVehicle = {
    estimateId,

    vehicleDetail,
  };

  yield put(sendAddVehicle(request));
}

export function* updateVehicle({ payload }: ReturnType<typeof actions.updateVehicle>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);
  if (!tradingProfile?.buyerId || !vehicleDetail || !estimateId) return;
  const { newValue, field } = payload;
  yield put(
    sendUpdateVehicle({
      estimateId,
      newValue,
      originalVehicle: vehicleDetail,
      field,
    }),
  );
}

export function* updateSettings({ payload }: ReturnType<typeof actions.updateSettings>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.buyerId) return;

  yield put(sendUpdateSettings(payload));
}

export function* updateWasteRecyclingSettings({
  payload,
}: ReturnType<typeof actions.updateWasteRecyclingSettings>): SagaIterator {
  const { itemId, field, newValue } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.buyerId) return;

  yield put(sendUpdateWasteRecyclingSettings({ itemId, field, newValue }));
}

export function* addCatalogReferenceByReferenceNumber({
  payload,
}: ReturnType<typeof actions.addCatalogReferenceByReferenceNumber>): SagaIterator {
  const userContext = yield* select(getUserContext);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!estimateId) return;
  const { referenceNumber, itemId, itemType, searchType } = payload;

  yield putResolve(
    addReferenceForValidation({
      userContext,
      referenceNumber,
      vehicleDetail,
      estimateId,
      itemId,
      itemType,
      searchType,
    }),
  );
  yield put(
    sendAddReferenceByReferenceNumber({
      userContext,
      referenceNumber,
      vehicleDetail,
      estimateId,
      itemId,
      itemType,
      searchType,
    }),
  );
}

export function* addReferenceNumber({ payload }: ReturnType<typeof actions.addReferenceNumber>): SagaIterator {
  const userContext = yield* select(getUserContext);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!estimateId) return;
  const { referenceNumber, itemId } = payload;

  yield put(
    sendAddReferenceNumber({
      userContext,
      referenceNumber,
      vehicleDetail,
      estimateId,
      itemId,
    }),
  );
}

export function* addCatalogTireByReferenceNumber({
  payload,
}: ReturnType<typeof actions.addCatalogTireByReferenceNumber>): SagaIterator {
  const userContext = yield* select(getUserContext);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!estimateId) return;
  const { referenceNumber, itemId, itemType, searchType } = payload;

  if (searchType === 'CUSTOM_ITEM') {
    yield putResolve(
      addReferenceForValidation({
        userContext,
        referenceNumber,
        vehicleDetail,
        estimateId,
        itemId,
        itemType,
        searchType,
      }),
    );
  }

  yield put(
    sendAddTireByReferenceNumber({
      userContext,
      referenceNumber,
      vehicleDetail,
      estimateId,
      itemId,
      itemType,
      searchType,
    }),
  );
}

export function* removeKnownReferenceValidation({
  payload,
}: ReturnType<typeof actions.removeKnownReferenceValidation>): SagaIterator {
  yield putResolve(removeReferenceForValidation(payload));
}

export function* addCatalogLaborTimeByCode({
  payload,
}: ReturnType<typeof actions.addCatalogLaborTimeByCode>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const userContext = yield* select(getUserContext);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!tradingProfile?.buyerId || !vehicleDetail || !estimateId) return;

  const laborTimeCode = payload;
  yield put(
    sendAddLaborTimeByCode({
      userContext,
      laborTimeCode,
      vehicleDetail,
      estimateId,
    }),
  );
}

export function* fetchSettingsRequest(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.buyerId) return;
  yield put(sendGetSettings());
}

export function* fetchSettingsResponse(action: WsResponse<GetSettings>): SagaIterator {
  const { estimateSettings } = action.payload;
  yield put(setSettings(estimateSettings));
}

export function* fetchDmsOrderNumbersResponse(action: WsResponse<EstimateDmsOrderNumbers>): SagaIterator {
  yield put(setDmsOrderNumbers(action.payload));
}

export function* addDmsOrderNumberRequest({
  payload,
}: ReturnType<typeof actions.addDmsOrderNumberRequest>): SagaIterator {
  yield put(sendAddDmsOrderNumber(payload));
}

export function* addCatalogReferencesToBasket(): SagaIterator {
  const userContext = yield* select(getUserContext);
  const currentVehicleDetail = yield* select(getLastVehicleDetail);
  const estimateId = yield* select(getCurrentEstimateId);
  const estimateVehicleKey = yield* select((state: RootState) => getEstimateVehicleKey(state, estimateId));
  if (!estimateId) {
    return;
  }
  const references = yield* select((state: RootState) => getCatalogReferences(state, estimateId));
  const linkedReferences = references.flatMap((ref) => (ref.linkedReferences ? ref.linkedReferences : []));

  const tires = yield* select((state: RootState) => getCatalogTires(state, estimateId));
  const referencesSum =
    (references ? references.length : 0) +
    (tires ? tires.length : 0) +
    (linkedReferences ? linkedReferences.length : 0);

  if (referencesSum === 0) {
    return;
  }

  // sum qty for duplicate main and linked references
  const mainRefNumbers = references.map((ref) => ref.referenceNumber);
  const linkedRefNumbers = linkedReferences.map((ref) => ref.referenceNumber);
  const duplicateRefNumbers = mainRefNumbers.filter((mainRef) => linkedRefNumbers.includes(mainRef));
  const mainReferencesWithSumQty = [...references];

  duplicateRefNumbers.forEach((refNumber) => {
    while (linkedReferences.some((r) => r.referenceNumber === refNumber)) {
      const linkedRefIndex = linkedReferences.findIndex((r) => r.referenceNumber === refNumber);
      const mainRefIndex = references.findIndex((r) => r.referenceNumber === refNumber);
      mainReferencesWithSumQty[mainRefIndex] = {
        ...references[mainRefIndex],
        quantity: mainReferencesWithSumQty[mainRefIndex].quantity + (linkedReferences[linkedRefIndex]?.quantity ?? 0),
      };
      linkedReferences.splice(linkedRefIndex, 1);
    }
  });

  const linkedReferencesWithSumQty = [...linkedReferences].reduce((acc: LinkedRef[], next: LinkedRef) => {
    const indexOfDuplicate = acc.findIndex((linkedRef) => linkedRef.referenceNumber === next.referenceNumber);
    if (indexOfDuplicate === -1) {
      acc.push(next);
      return acc;
    }
    const duplicateToMerge = acc[indexOfDuplicate];
    acc[indexOfDuplicate] = { ...duplicateToMerge, quantity: duplicateToMerge.quantity + next.quantity };
    return acc;
  }, []);

  const referenceDetails = [...mainReferencesWithSumQty, ...linkedReferencesWithSumQty].map((ref) => {
    return {
      quantity: ref.quantity,
      referenceNumber: ref.referenceNumber,
      referenceSource: ref.referenceSource ?? 'STANDARD',
      origin: ref.origin,
      supplierCode: ref.supplierCode,
    };
  });

  const tiresDetails = [...tires].map((ref) => {
    return {
      quantity: ref.quantity,
      referenceNumber: ref.referenceNumber,
      referenceSource: 'STANDARD' as ReferenceSource,
    };
  });

  yield put(
    sendAddCatalogReferencesToBasket({
      userContext,
      vehicleDetail: estimateVehicleKey ? currentVehicleDetail : undefined,
      referenceDetails: [...referenceDetails, ...tiresDetails],
    }),
  );
}

export function* addKnowReferenceByRefNumberResponse(action: WsResponse<AddReferenceByRefNumber>): SagaIterator {
  const { estimateId, result, referenceNumber, itemType } = action.payload;
  const currentEstimateId = yield* select(getCurrentEstimateId);

  if (currentEstimateId === estimateId) {
    if (result !== 'OK') {
      const notificationMessage =
        result === 'UNKNOWN_REFERENCE'
          ? AppTranslation.t('catalog.reference.error.unknown_reference', 'Unknown reference')
          : AppTranslation.t(
              'cart.action.add_reference.missing_price',
              'Price currently unavailable, please try again later.',
            );
      yield call(notifyTop, 'error', notificationMessage, null);
    } else {
      let ref = undefined;
      let tire = undefined;

      switch (itemType) {
        case 'STANDARD': {
          const references = yield* select((state: RootState) => getReferences(state, currentEstimateId));
          ref = references.find((r) => r.referenceNumber === referenceNumber);
          break;
        }
        case 'TIRE': {
          const tires = yield* select((state: RootState) => getTires(state, currentEstimateId));
          tire = tires.find((r) => r.referenceNumber === referenceNumber);
          break;
        }
      }

      if (ref || tire) {
        const msg = AppTranslation.t(
          'estimate.action.result.reference_listed',
          'This reference is already listed in your estimate',
        );
        const decs = AppTranslation.t(
          'estimate.action.result.reference_listed.description',
          '1 unit has been added to reference {{ referenceNumber }}',
          {
            referenceNumber,
          },
        );
        yield call(notifyTop, 'info', msg, decs);
      }
    }
  }
}

export function* addTireByRefNumberResponse(action: WsResponse<AddReferenceByRefNumber>): SagaIterator {
  const { estimateId, result, referenceNumber } = action.payload;
  const currentEstimateId = yield* select(getCurrentEstimateId);

  if (currentEstimateId === estimateId) {
    if (result !== 'OK') {
      const notificationMessage =
        result === 'UNKNOWN_REFERENCE'
          ? AppTranslation.t('catalog.reference.error.unknown_reference', 'Unknown reference')
          : AppTranslation.t(
              'cart.action.add_reference.missing_price',
              'Price currently unavailable, please try again later.',
            );
      yield call(notifyTop, 'error', notificationMessage, null);
    } else {
      const msg = AppTranslation.t(
        'estimate.action.result.tire_reference_listed.description',
        'Tire reference has been added to the tire section of the estimate',
        {
          referenceNumber,
        },
      );
      yield call(notifyTop, 'success', msg);
    }
  }
}

export function* sendEstimateToDMSResponse(): SagaIterator {
  yield call(
    notifyTop,
    'success',
    AppTranslation.t('estimate.dms.export.success', 'The estimation has been successfully sent to your DMS'),
    null,
  );
}

export function* fillEstimateFromMaintenancePlan({
  payload,
}: ReturnType<typeof actions.fillEstimateFromMaintenancePlan>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  const estimateId = yield* select(getCurrentEstimateId);

  if (!tradingProfile?.buyerId || !estimateId) return;

  const { vehicleDetail, laborTimes, references } = payload;
  if (!vehicleDetail) return;

  yield put(
    fillFromMaintenancePlan({
      references,
      laborTimes,
      vehicleDetail,
      estimateId,
    }),
  );
}

export function* createEstimateFromDMSRequest({
  payload,
}: ReturnType<typeof actions.createEstimateFromDMSRequest>): SagaIterator {
  const { orderNumber } = payload;
  const userContext = yield* select(getUserContext);
  const workshopId = yield* select(getUserGarageWorkshopId);

  if (!workshopId) {
    return;
  }

  yield put(hideDMSErrorPopup());
  yield put(sendGetRepairOrderRequest({ userContext, workshopId, orderNumber }));
}

export function* sendEstimateToDMSRequest(): SagaIterator {
  const estimateId = yield* select(getCurrentEstimateId);
  const workshopId = yield* select(getUserGarageWorkshopId);

  if (!workshopId || !estimateId) {
    return;
  }

  yield put(hideDMSErrorPopup());
  yield put(sendEstimateToDMS({ estimateId, workshopId }));
}

export function* sendAutocompleteBundlesRequest({
  payload,
}: ReturnType<typeof actions.sendAutocompleteBundlesRequest>): SagaIterator {
  yield put(
    setBundleLastAutocompleteKey({ searchCode: payload.queryCode, searchDescription: payload.queryDescription }),
  );
  yield put(
    sendQueryAutocompleteBundlesRequest({
      searchQueryCode: payload.queryCode,
      searchQueryDesignation: payload.queryDescription,
    }),
  );
}

export function* sendAutocompleteBundlesResponse(action: WsResponse<AutocompleteSearchBundlesResponse>): SagaIterator {
  yield put(setBundleFulltextAutocomplete(action.payload));
}

export function* checkoutB2BEstimateRequest({
  payload,
}: ReturnType<typeof actions.checkoutB2BEstimateRequest>): SagaIterator {
  const { estimateId, checkoutItems, deliveryType, paymentClientCode, instruction } = payload;
  const userContext = yield* select(getUserContext);
  const estimate = getData(yield* select((state: RootState) => getEstimateById(state, estimateId)));
  if (!estimate) {
    return;
  }

  yield put(clearCheckoutStatus());
  yield put(setOrderEstimateStatus({ estimateId, status: LOADING }));
  yield put(
    sendCheckoutB2BRequest({
      estimateId,
      userContext,
      deliveryType,
      paymentClientCode,
      instruction,
      mktpDeliveryMode: estimate.estimateOrderItemDetails.mktpDeliveryMode,
      itemDetails: checkoutItems,
    }),
  );
}

export function* checkoutB2BEstimateResponse(action: WsResponse<CheckoutB2BEstimateOrderResponse>): SagaIterator {
  const payload = action.payload;
  const { estimateId, placedOrders, failedOrders, ordersOverallStatus } = payload;
  yield put(setOrderEstimateStatus({ estimateId, status: FOUND }));
  yield put(
    setCheckoutStatus({
      placedOrders,
      failedOrders,
      ordersOverallStatus,
    }),
  );
}

export function* fetchB2BReferencesPriceResponseSaga(
  action: WsResponse<GetReferencesPriceForB2BEstimateResponse>,
): SagaIterator {
  yield put(setB2BEstimateReferencesPriceData({ ...action.payload }));
}

export function* fetchB2BReferencesStockResponseSaga(
  action: WsResponse<GetReferencesStocksForB2BEstimateResponse>,
): SagaIterator {
  yield put(setB2BEstimateReferencesStockData({ ...action.payload }));
}

export function* fetchB2BReferencesDiscountsResponseSaga(
  action: WsResponse<GetReferencesDiscountsForB2BEstimateResponse>,
): SagaIterator {
  yield put(setB2BEstimateReferencesDiscountsData({ ...action.payload }));
}

export function* b2BReferencesDiscountsRemovedSaga(
  action: WsResponse<RemovedB2BEstimateReferencesDiscountsResponse>,
): SagaIterator {
  yield put(removeB2BEstimateReferencesDiscounts({ ...action.payload }));
}

export function* EstimateSagas(): SagaIterator {
  yield takeEvery(actions.fetchEstimateByIdRequest.type, sagaGuard(fetchEstimateByIdRequest));
  yield takeEvery(actions.fetchEstimateByIdResponse.type, sagaGuard(fetchEstimateByIdResponse));
  yield takeEvery(actions.fetchDeleteEstimateByIdResponse.type, sagaGuard(fetchDeleteEstimateByIdResponse));
  yield takeEvery(actions.fetchHideEstimateByIdResponse.type, sagaGuard(fetchHideEstimateByIdResponse));
  yield takeEvery(actions.fetchShowEstimateByIdResponse.type, sagaGuard(fetchShowEstimateByIdResponse));
  yield takeEvery(actions.fetchLatestEstimateRequest.type, sagaGuard(fetchLatestEstimateRequest));
  yield takeEvery(actions.fetchEstimateCreatedResponse.type, sagaGuard(fetchEstimateCreatedResponse));
  yield takeEvery(actions.fetchEstimateHistoryRequest.type, sagaGuard(fetchEstimateHistoryRequest));
  yield takeEvery(actions.fetchEstimateHistoryResponse.type, sagaGuard(fetchEstimateHistoryResponse));

  yield takeEvery(actions.addCatalogLaborTime.type, sagaGuard(addCatalogLaborTime));
  yield takeEvery(actions.addCatalogReference.type, sagaGuard(addCatalogReference));
  yield takeEvery(actions.addReferenceNumber.type, sagaGuard(addReferenceNumber));
  yield takeEvery(actions.addCatalogTire.type, sagaGuard(addCatalogTire));
  yield takeLatest(actions.addCatalogReferenceByReferenceNumber.type, sagaGuard(addCatalogReferenceByReferenceNumber));
  yield takeLatest(actions.addCatalogTireByReferenceNumber.type, sagaGuard(addCatalogTireByReferenceNumber));
  yield takeLatest(actions.addCatalogLaborTimeByCode.type, sagaGuard(addCatalogLaborTimeByCode));
  yield takeEvery(actions.addCustomReference.type, sagaGuard(addCustomReference));
  yield takeEvery(actions.addCustomLaborTime.type, sagaGuard(addCustomLaborTime));
  yield takeEvery(actions.addCustomWasteRecycling.type, sagaGuard(addCustomWasteRecycling));
  yield takeEvery(actions.addCustomFreeBundle.type, sagaGuard(addCustomFreeBundle));
  yield takeEvery(actions.addCustomTire.type, sagaGuard(addCustomTire));
  yield takeEvery(actions.addOtherItem.type, sagaGuard(addOtherItem));

  yield takeLatest(actions.updateReference.type, sagaGuard(updateReference));
  yield takeLatest(actions.updateLaborTime.type, sagaGuard(updateLaborTime));
  yield takeLatest(actions.updateTire.type, sagaGuard(updateTire));
  yield takeLatest(actions.updateOtherItem.type, sagaGuard(updateOtherItem));
  yield takeLatest(actions.updateWasteRecycling.type, sagaGuard(updateWasteRecycling));
  yield takeLatest(actions.updateFreeBundle.type, sagaGuard(updateFreeBundle));
  yield takeLatest(actions.updateSettings.type, sagaGuard(updateSettings));
  yield takeLatest(actions.updateWasteRecyclingSettings.type, sagaGuard(updateWasteRecyclingSettings));
  yield takeLatest(actions.addCustomSetting.type, sagaGuard(addCustomSetting));
  yield takeLatest(actions.removeCustomSetting.type, sagaGuard(removeCustomSetting));
  yield takeLatest(actions.addBundleFromMyStore.type, sagaGuard(addBundleFromMyStore));
  yield takeLatest(actions.addBundleFromAutocomplete.type, sagaGuard(addBundleFromAutocomplete));

  yield takeEvery(actions.removeReference.type, sagaGuard(removeReference));
  yield takeEvery(actions.removeLaborTime.type, sagaGuard(removeLaborTime));
  yield takeEvery(actions.removeFreeBundle.type, sagaGuard(removeFreeBundle));
  yield takeEvery(actions.removeWasteRecycling.type, sagaGuard(removeWasteRecycling));
  yield takeEvery(actions.removeTire.type, sagaGuard(removeTire));
  yield takeEvery(actions.removeOtherItem.type, sagaGuard(removeOtherItem));

  yield takeLatest(actions.removeAllReferences.type, sagaGuard(removeAllReferences));
  yield takeLatest(actions.removeAllLaborTimes.type, sagaGuard(removeAllLaborTimes));
  yield takeLatest(actions.removeAllFreeBundles.type, sagaGuard(removeAllFreeBundles));
  yield takeLatest(actions.removeAllWasteRecycling.type, sagaGuard(removeAllWasteRecycling));
  yield takeLatest(actions.removeAllTires.type, sagaGuard(removeAllTires));
  yield takeLatest(actions.removeAllOtherItems.type, sagaGuard(removeAllOtherItems));
  yield takeLatest(actions.deleteEstimateRequest.type, sagaGuard(deleteEstimateRequest));
  yield takeLatest(actions.cancelEstimateDeletion.type, sagaGuard(cancelEstimateDeletionRequest));

  yield takeLatest(actions.addVehicle.type, sagaGuard(addVehicle));
  yield takeLatest(actions.updateVehicle.type, sagaGuard(updateVehicle));
  yield takeLatest(actions.updateClient.type, sagaGuard(updateClient));
  yield takeLatest(actions.updateObservations.type, sagaGuard(updateObservations));

  yield takeLatest(actions.removeKnownReferenceValidation.type, sagaGuard(removeKnownReferenceValidation));

  yield takeLatest(actions.fetchSettingsRequest.type, sagaGuard(fetchSettingsRequest));
  yield takeLatest(actions.fetchSettingsResponse.type, sagaGuard(fetchSettingsResponse));

  yield takeLatest(actions.addCatalogReferencesToBasket.type, sagaGuard(addCatalogReferencesToBasket));
  yield takeLatest(actions.addKnowReferenceByRefNumberResponse.type, sagaGuard(addKnowReferenceByRefNumberResponse));
  yield takeLatest(actions.addTireByRefNumberResponse.type, sagaGuard(addTireByRefNumberResponse));

  yield takeLatest(actions.createEstimateFromDMSRequest.type, sagaGuard(createEstimateFromDMSRequest));
  yield takeLatest(actions.sendEstimateToDMSRequest.type, sagaGuard(sendEstimateToDMSRequest));
  yield takeLatest(actions.sendEstimateToDMSResponse.type, sagaGuard(sendEstimateToDMSResponse));
  yield takeLatest(actions.fetchDmsOrderNumbersResponse.type, sagaGuard(fetchDmsOrderNumbersResponse));
  yield takeLatest(actions.addDmsOrderNumberRequest.type, sagaGuard(addDmsOrderNumberRequest));

  yield takeLatest(actions.fillEstimateFromMaintenancePlan.type, sagaGuard(fillEstimateFromMaintenancePlan));

  yield takeLatest(actions.updateB2BCounterParties.type, sagaGuard(updateB2BCounterParties));
  yield takeLatest(actions.fetchB2BReferencesPriceResponseSaga.type, sagaGuard(fetchB2BReferencesPriceResponseSaga));
  yield takeLatest(actions.fetchB2BReferencesStockResponseSaga.type, sagaGuard(fetchB2BReferencesStockResponseSaga));

  yield takeLatest(
    actions.fetchB2BReferencesDiscountsResponseSaga.type,
    sagaGuard(fetchB2BReferencesDiscountsResponseSaga),
  );
  yield takeLatest(actions.b2BReferencesDiscountsRemovedSaga.type, sagaGuard(b2BReferencesDiscountsRemovedSaga));
  yield takeLatest(actions.checkoutB2BEstimateRequest.type, sagaGuard(checkoutB2BEstimateRequest));
  yield takeLatest(actions.checkoutB2BEstimateResponse.type, sagaGuard(checkoutB2BEstimateResponse));

  yield takeLatest(actions.sendAutocompleteBundlesRequest.type, sagaGuard(sendAutocompleteBundlesRequest));
  yield takeLatest(actions.sendAutocompleteBundlesResponse.type, sagaGuard(sendAutocompleteBundlesResponse));
}
