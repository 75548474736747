import React, { LegacyRef, useCallback } from 'react';
import { FreeBundleField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateFreeBundle';
import { MyStoreBundle } from '@1po/1po-bff-fe-spec/generated/estimate/response/AutocompleteSearchBundlesResponse';
import { Menu } from 'antd';
import { BundleSearchParams } from 'domains/estimate/Estimate.types';
import { capitalize, Pipeline, Text } from 'UI';
import { SearchMenu, SItem } from './FreeBundleAutocomplete.styled';

interface AutocompleteMenuProps {
  results: MyStoreBundle[];
  menuRef: LegacyRef<Menu>;
  inputValue: string;
  onClick: (label: string, id: string, code: string, price: string) => () => void;
  search: BundleSearchParams;
  setSearch: React.Dispatch<React.SetStateAction<BundleSearchParams>>;
  field: FreeBundleField;
}

const FreeBundleAutocompleteMenu = ({
  results,
  menuRef,
  inputValue,
  onClick,
  search,
  setSearch,
  field,
}: AutocompleteMenuProps) => {
  const valueToMatch = inputValue.replace(/[^a-zA-Z\s]/g, '');
  const matcher = new RegExp(`(?=${valueToMatch})|(?<=${valueToMatch})`, 'gi');

  const handleBlurMenu = (e: React.FocusEvent<HTMLLIElement>) => {
    if (search?.focusMenu !== undefined && !e?.relatedTarget?.id?.includes('search-menu')) {
      setSearch({ ...search, open: false, focusMenu: undefined });
    }
  };

  const handleEscPress = useCallback(
    (event: { key: string }) => {
      if (event.key === 'Escape') {
        setSearch({ ...search, open: false, focusMenu: 0 });
      }
    },
    [search, setSearch],
  );

  return (
    <SearchMenu ref={menuRef} onKeyDown={handleEscPress}>
      {results.map((fulltext, index) => (
        <React.Fragment key={`keywords-${fulltext.id}-${fulltext.code}`}>
          <SItem
            onClick={onClick(fulltext.designation ?? '', fulltext.id ?? '', fulltext.code ?? '', fulltext.price ?? '')}
            key={`menu-item-${fulltext.id}-${fulltext.code}`}
            id={`search-menu-${index}`}
            onBlur={handleBlurMenu}
          >
            {((field === 'NAME' ? fulltext.designation : fulltext.code) ?? '').split(matcher)?.map((segment, idx) => (
              <Text
                key={`segment-${segment}`}
                type={segment !== inputValue ? 'search_result_highlighted' : 'search_result_base'}
                cursor={'pointer'}
              >
                {idx === 0 ? capitalize(segment) : segment}
              </Text>
            ))}
          </SItem>
          {index + 1 !== results.length && <Pipeline horizontal size={'100%'} />}
        </React.Fragment>
      ))}
    </SearchMenu>
  );
};
export default FreeBundleAutocompleteMenu;
