import React from 'react';
import { Badge as AntdBadge, BadgeProps as AntdBadgeProps } from 'antd-v5';
import { useTheme } from 'styled-components';
import { SBadge, Wrapper } from './Badge.styled';
import { MarginBox } from '../Box';

interface BadgeProps {
  children?: React.ReactNode;
  count?: number;
  showZero?: boolean;
  className?: string;
  color?: string;
  borderColor?: string;
  size?: number;
  offset?: [number | string, number | string];
  Component?: React.ReactNode;
  fontColor?: string;
}

export function Badge({
  children,
  count,
  showZero,
  className,
  color,
  size,
  offset,
  borderColor,
  fontColor,
}: Readonly<BadgeProps>) {
  const theme = useTheme();

  if (!showZero && count === 0) {
    return <>{children}</>;
  }

  return (
    <Wrapper>
      <SBadge
        className={className}
        size={size ?? 15}
        offset={offset ?? [0, 0]}
        color={color ?? theme.color.brand}
        borderColor={borderColor}
        fontColor={fontColor}
      >
        <MarginBox mb={0.5}>{count}</MarginBox>
      </SBadge>
      {children}
    </Wrapper>
  );
}

export function BadgeComponent({ children, Component, className, color, size, offset, borderColor }: BadgeProps) {
  const theme = useTheme();

  return (
    <Wrapper>
      <SBadge
        className={className}
        size={size ?? 15}
        offset={offset ?? [0, 0]}
        color={color ?? theme.color.brand}
        borderColor={borderColor}
      >
        <MarginBox mb={0.5}>{Component}</MarginBox>
      </SBadge>
      {children}
    </Wrapper>
  );
}

export function NativeBadge(props: AntdBadgeProps) {
  const theme = useTheme();

  return (
    <AntdBadge color={props.color ?? theme.color.brand} {...props}>
      {props.children}
    </AntdBadge>
  );
}
