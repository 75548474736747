import React, { ReactNode } from 'react';
import { Tooltip } from 'antd-v5';

interface TooltipProps {
  title: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right' | 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight';
  children: ReactNode;
  open?: boolean;
  color?: string;
}

export const WithTooltip = ({ title, children, placement, open, color }: TooltipProps): ReactNode => {
  return (
    <Tooltip title={title} placement={placement} open={open} color={color}>
      {children}
    </Tooltip>
  );
};
