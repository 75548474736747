import styled, { createGlobalStyle } from 'styled-components';
import { Flex } from '../Flex';

export const SFlex = styled(Flex)`
  position: relative;
  z-index: -10;
`;

export const SArrow = styled.div`
  position: relative;
  width: 46px;
  height: 80px;
  opacity: 0.8;
  background-color: ${({ theme }) => theme.color.brand_black};
  z-index: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

export const Left = styled.div`
  text-align: left;
`;

export const LeftArrowPositionWrapper = styled.div`
  position: absolute;
  top: calc(50% - 40px);
`;
export const RightArrowPositionWrapper = styled.div`
  position: absolute;
  right: 0;
  top: calc(50% - 40px);
`;

export const CarouselStyles = createGlobalStyle`
  .home-page-carousel {

    position: relative;

    li.slick-active {
      width: 16px !important;
    }

    .ant5-carousel .slick-dots {
      li button {
        background: ${({ theme }) => theme.color.grey_metal};
        height: 12px;
        width: 12px;
        border-radius: 6px;
        margin-top: -6px;
      }

      li.slick-active button {
        background: ${({ theme }) => theme.color.brand}
      }
    }
  }
`;
