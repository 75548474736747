import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OtherItem } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { Reference } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Reference';
import { Tire } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Tire';
import { TFunction } from 'i18next';
import { RootState } from 'app/AppStore';
import { TrashAltIcon } from 'assets/icons';
import { useIsB2B } from 'components/B2BComponents/useIsB2B';
import { Dialog, useDisclosure } from 'components/Dialog';
import {
  addCustomFreeBundle,
  addCustomLaborTime,
  addCustomReference,
  addCustomTire,
  addCustomWasteRecycling,
  addOtherItem,
  getFreeBundlesLength,
  getLaborTimesLength,
  getOtherItemsLength,
  getReferencesLength,
  getTiresLength,
  getWasteRecyclingLength,
  removeAllFreeBundles,
  removeAllLaborTimes,
  removeAllOtherItems,
  removeAllReferences,
  removeAllTires,
  removeAllWasteRecycling,
} from 'domains/estimate/Estimate.store';
import TireTable from 'pages/EstimatePage/TableSection/TireTable';
import { Flex, MarginBox, PlusCircleButton, Text, TrashAltButton } from 'UI';
import FreeBundlesTable from './FreeBundlesTable';
import LaborTimeTable from './LaborTimeTable';
import OtherTable from './OtherTable';
import ReferenceTable from './ReferenceTable';
import {
  FreeBundlesTableHeader,
  LaborTimeTableHeader,
  OtherTableHeader,
  ReferenceTableHeader,
  TiresTableHeader,
  WasteRecyclingTableHeader,
} from './tableHeaders';
import WasteRecyclingTable from './WasteRecyclingTable';

type TableSectionType = 'reference' | 'labor_time' | 'tire' | 'other' | 'waste_recycling' | 'free_bundles';

export interface TableElements {
  table: JSX.Element;
  header: JSX.Element;
  addRowLine: JSX.Element;
  addEmptyRow: { payload: undefined; type: string };
  deleteTable: { payload: undefined; type: string };
  deleteAllDescription: string;
  getDataLength: (state: RootState, estimateId: string) => number;
}

export interface TableProps {
  estimateId: string;
  sparePartsView?: string;
}

interface InvalidEstimateRowParams {
  isClientView?: boolean;
  row: Reference | Tire | OtherItem;
}

export function clientViewMarginValidator({ isClientView, row }: InvalidEstimateRowParams): boolean {
  const isFullDiscountPercent = !row.garagePrice && row.discountType === 'PERCENT' && row.discount === '100.00';
  const isFullDiscountValue =
    !row.garagePrice && row.discountType === 'VALUE_TO_EXTRACT' && row.discount === row.unitPrice;
  const isMarginInvalid = Number(row.margin) <= 0;
  const hasInvalidConditions = isMarginInvalid || isFullDiscountPercent || isFullDiscountValue;

  return isClientView ? isClientView && hasInvalidConditions : hasInvalidConditions;
}

function typeSwitch(type: TableSectionType, t: TFunction, estimateId: string, sparePartsView?: string): TableElements {
  switch (type) {
    case 'reference':
      return {
        table: <ReferenceTable estimateId={estimateId} sparePartsView={sparePartsView} />,
        header: <ReferenceTableHeader />,
        addRowLine: <Trans i18nKey={'estimate.action.add_reference'}>{'Add other reference'}</Trans>,
        addEmptyRow: addCustomReference(),
        deleteTable: removeAllReferences(),
        deleteAllDescription: t(
          'estimate.action.remove_references.description',
          'This action will delete all items from the Reference list.',
        ),
        getDataLength: getReferencesLength,
      };
    case 'labor_time':
      return {
        table: <LaborTimeTable estimateId={estimateId} />,
        header: <LaborTimeTableHeader />,
        addRowLine: <Trans i18nKey={'common.action.add_labor_time'}>{'Add Labor time'}</Trans>,
        addEmptyRow: addCustomLaborTime(),
        deleteTable: removeAllLaborTimes(),
        deleteAllDescription: t(
          'estimate.action.remove_labor_times.description',
          'This action will delete all items from the Labor time list.',
        ),
        getDataLength: getLaborTimesLength,
      };
    case 'waste_recycling':
      return {
        table: <WasteRecyclingTable estimateId={estimateId} />,
        header: <WasteRecyclingTableHeader />,
        addRowLine: <Trans i18nKey={'common.action.add_waste_recycling'}>{'Add Waste recycling'}</Trans>,
        addEmptyRow: addCustomWasteRecycling(),
        deleteTable: removeAllWasteRecycling(),
        deleteAllDescription: t(
          'estimate.action.remove_waste_recycling.description',
          'This action will delete all items from the Waste recycling list.',
        ),
        getDataLength: getWasteRecyclingLength,
      };
    case 'free_bundles':
      return {
        table: <FreeBundlesTable estimateId={estimateId} />,
        header: <FreeBundlesTableHeader />,
        addRowLine: <Trans i18nKey={'common.action.add_bundle'}>{'Add bundle'}</Trans>,
        addEmptyRow: addCustomFreeBundle(),
        deleteTable: removeAllFreeBundles(),
        deleteAllDescription: t(
          'estimate.action.remove_free_bundles.description',
          'This action will delete all items from the Free bundles list.',
        ),
        getDataLength: getFreeBundlesLength,
      };
    case 'tire':
      return {
        table: <TireTable estimateId={estimateId} sparePartsView={sparePartsView} />,
        header: <TiresTableHeader />,
        addRowLine: <Trans i18nKey={'common.action.add_tire'}>{'Add Tire'}</Trans>,
        addEmptyRow: addCustomTire(),
        deleteTable: removeAllTires(),
        deleteAllDescription: t(
          'estimate.action.remove_tires.description',
          'This action will delete all items from the Tires list.',
        ),
        getDataLength: getTiresLength,
      };
    case 'other':
      return {
        table: <OtherTable estimateId={estimateId} sparePartsView={sparePartsView} />,
        header: <OtherTableHeader />,
        addRowLine: <Trans i18nKey={'estimate.action.add_other_reference'}>{'Add other'}</Trans>,
        addEmptyRow: addOtherItem(),
        deleteTable: removeAllOtherItems(),
        deleteAllDescription: t(
          'estimate.action.remove_other_items.description',
          'This action will delete all items from the Other item list.',
        ),
        getDataLength: getOtherItemsLength,
      };
    default:
      return {
        table: <></>,
        header: <></>,
        addRowLine: <Trans i18nKey={'common.action.no_data'}>{'No data available'}</Trans>,
        addEmptyRow: { payload: undefined, type: '' },
        deleteTable: { payload: undefined, type: '' },
        deleteAllDescription: t('common.action.no_data_description', 'No data is available for this section.'),
        getDataLength: () => 0,
      };
  }
}

export interface TableSectionProps {
  estimateId: string;
  type: TableSectionType;
  className?: string;
  sparePartsView?: string;
}

export default function TableSection({ estimateId, type, className, sparePartsView }: Readonly<TableSectionProps>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const disclosure = useDisclosure();
  const { onOpen } = disclosure;
  const isB2B = useIsB2B();
  const tableElements = typeSwitch(type, t, estimateId, sparePartsView);
  const dataLength = useSelector((state: RootState) => tableElements.getDataLength(state, estimateId));

  function addRow() {
    dispatch(tableElements.addEmptyRow);
  }

  function removeAll() {
    dispatch(tableElements.deleteTable);
  }

  return (
    <>
      <div className={className}>
        {tableElements.header}
        {dataLength > 0 && (
          <MarginBox mt={15}>
            <Flex align={'center'}>
              <TrashAltButton onClick={onOpen} />
              <MarginBox ml={15}>
                <Text type={'light_14'}>
                  <Trans i18nKey={'estimate.action.delete_all_items'}>{'Delete all items'}</Trans>
                </Text>
              </MarginBox>
            </Flex>
          </MarginBox>
        )}
        <MarginBox mt={15}>{tableElements.table}</MarginBox>
        {!isB2B && (
          <MarginBox mt={30}>
            <PlusCircleButton onClick={addRow}>
              <Text type={'link_bold'}>{tableElements.addRowLine}</Text>
            </PlusCircleButton>
          </MarginBox>
        )}
      </div>
      <Dialog
        disclosure={disclosure}
        title={t('common.dialog.actionRequired', 'Action required')}
        description={[
          tableElements.deleteAllDescription,
          t('common.dialog.confirmationQuestion', 'Would you like to confirm?'),
        ]}
        icon={TrashAltIcon}
        status={'info'}
        handleConfirm={removeAll}
      />
    </>
  );
}
