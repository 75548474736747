import React from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/VehicleSearchHistoryResponse';
import styled from 'styled-components';
import { ReactComponent as CommentAltIcon } from 'assets/deprecatedIcons/comment-alt.svg';
import { ReactComponent as FileArchiveIcon } from 'assets/deprecatedIcons/file-archive.svg';
import { ReactComponent as IndustryIcon } from 'assets/deprecatedIcons/industry.svg';
import { ReactComponent as UserSecretIcon } from 'assets/deprecatedIcons/user-secret.svg';
import { BackToTopButtonSm } from 'components/BackToTopButton/BackToTopButton';
import { DataContainer } from 'components/DataContainer';
import Loader from 'components/Loader';
import PromotionBannerWrapper from 'components/PromotionBanner';
import SearchHistoryOverview from 'components/SearchHistoryOverview';
import { PromotionBannerCarousel } from 'domains/promotion/Promotion.types';
import { PortfolioCard, PortfolioCardProps } from 'pages/HomePage/HomePage';
import { HomepageLinks } from 'pages/HomePage/links/HomepageLinks';
import SearchBar from 'pages/HomePage/search/SearchBar';
import { Carousel, Container, emptyPadding, Grid, IconButton, IconType, MarginBox, Text } from 'UI';
import { isLoaded, NO_DATA } from 'utils';

const HomeMenuContainer = styled.div`
  height: 122px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.brand_black};
`;

const HomeMenuButton = ({ title, Icon }: { title: string; Icon: IconType }) => (
  <HomeMenuContainer>
    <IconButton IconComponent={Icon} round bgColor={'grey20'} color={'grey100'} size={16} />
    <MarginBox mt={15} />
    <Text type={'dark_12_white_100'}>{title}</Text>
  </HomeMenuContainer>
);

export const HomepageSm = ({
  vehicleSearchHistory,
  banners,
  bannersWithImage,
  portfolioData,
}: {
  vehicleSearchHistory: VehicleDetail[] | NO_DATA;
  banners: NO_DATA | PromotionBannerCarousel[];
  bannersWithImage: PromotionBannerCarousel[];
  portfolioData: Array<PortfolioCardProps>;
}) => {
  const { t } = useTranslation();

  return (
    <Container size={'md'} padding={emptyPadding}>
      <MarginBox mt={30} />
      <SearchBar />
      <MarginBox mt={30} />
      <SearchHistoryOverview redirectToCatalog={true} />
      {isLoaded(vehicleSearchHistory) && (
        <>
          <MarginBox mt={30} />
          <DataContainer data={banners} Loading={() => <Loader height={'100px'} />} NotFound={() => <></>}>
            {bannersWithImage.length > 0 && (
              <Carousel>
                {bannersWithImage.map((banner) => (
                  <PromotionBannerWrapper key={banner.promotionId} banner={banner} />
                ))}
              </Carousel>
            )}
          </DataContainer>
          <MarginBox mt={15} />
          <HomepageLinks />
          <MarginBox mt={30} />
          <Grid
            columns={[1, 1]}
            gutter={[
              [0, 0],
              [0, 0],
            ]}
          >
            {portfolioData.map((data, i) => (
              <MarginBox mx={15} my={15} key={`portfolio-${i}`}>
                <PortfolioCard {...data} bg={''} index={i} />
              </MarginBox>
            ))}
          </Grid>
          <MarginBox mt={15} />
          <BackToTopButtonSm />
          <Grid columns={2} gutter={[0, 0]}>
            <HomeMenuButton title={t('portfolio.company.title', 'Company')} Icon={IndustryIcon} />
            <HomeMenuButton title={t('portfolio.company.solution', 'Solution')} Icon={FileArchiveIcon} />
            <HomeMenuButton title={t('portfolio.company.privacy', 'Privacy')} Icon={UserSecretIcon} />
            <HomeMenuButton title={t('portfolio.company.assistance', 'Assistance')} Icon={CommentAltIcon} />
          </Grid>
        </>
      )}
    </Container>
  );
};
