import { Alert } from 'antd';
import styled, { css } from 'styled-components';
import { StatusType, ThemeColorKeyType } from 'styles';

const borderColorSelector = (status: StatusType): ThemeColorKeyType => {
  switch (status) {
    case 'success':
      return 'success';
    case 'error':
      return 'error';
    case 'warning':
      return 'warning';
    case 'info':
      return 'info';
    default:
      return 'brand_black';
  }
};

const backgroundColorSelector = (status: StatusType): ThemeColorKeyType => {
  switch (status) {
    case 'success':
      return 'off_green';
    case 'error':
      return 'very_light_pink';
    case 'warning':
      return 'off_white';
    case 'info':
      return 'info_blue';
    default:
      return 'brand_black';
  }
};

const infoAlertCss = css`
  border-radius: 8px;
  border: none;
  box-shadow: 0 5px 20px 0 ${({ theme }) => theme.color.shadow_2};
`;

export const SAlert = styled(Alert)<{ status: StatusType; backgroundColor?: string }>`
  border-radius: 2px;
  border: solid 1px
    ${({ theme, status, backgroundColor }) => backgroundColor ?? theme.color[borderColorSelector(status)]};
  background-color: ${({ theme, status, backgroundColor }) =>
    backgroundColor ?? theme.color[backgroundColorSelector(status)]};

  ${({ status }) => status === 'info' && infoAlertCss};
`;
