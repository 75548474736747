import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Notification from 'components/UserNotifications/Notification';
import { cancelAllNotificationsRequest, getUserNotifications } from 'domains/user';
import { CenterFlex, Flex, Pipeline, Text } from 'UI';
import { getSearchData } from 'utils';

const UserNotifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userNotifications = useSelector(getUserNotifications);
  const notificationsData = getSearchData(userNotifications)?.notifications ?? [];

  const handleCancelAll = () => {
    dispatch(cancelAllNotificationsRequest());
  };

  return (
    <Flex direction={'column'} minWidth={500} maxWidth={500} maxHeight={480} overflowY={'scroll'}>
      <Flex justify={'space-between'} padding={'10px 20px 10px 20px'}>
        <Text type={'h6'} disableGutter>
          {t('notifications.title', 'Notifications')}
        </Text>
        <Text type={'light_14_red'} disableGutter cursor={'pointer'} hoverUnderLine onClick={handleCancelAll}>
          {t('notifications.action.clear_all', 'Clear all')}
        </Text>
      </Flex>
      <Pipeline size={'100%'} horizontal />
      <Flex direction={'column'} padding={'10px 20px 10px 20px'}>
        {notificationsData.length > 0 ? (
          <Flex direction={'column'} gap={20}>
            {notificationsData.map((notification) => (
              <Notification key={notification.notificationId} notification={notification} />
            ))}
          </Flex>
        ) : (
          <CenterFlex>
            <Text type={'text_dim'}>No unread notifications</Text>
          </CenterFlex>
        )}
      </Flex>
    </Flex>
  );
};

export default UserNotifications;
