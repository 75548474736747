/* eslint-disable max-len */

import React from 'react';
import styled from 'styled-components';
import { BlackButton, Box, Flex, Text } from 'UI';

export type BackgroundPositionCss = 'left' | 'center' | 'right' | 'top' | 'bottom' | string;
type Cursor = 'initial' | 'pointer';

const SBackground = styled.div<{
  height?: number;
  backgroundPosition?: BackgroundPositionCss;
  cursor: Cursor;
}>`
  background-repeat: no-repeat;
  background-position: ${({ backgroundPosition }) => backgroundPosition ?? 'center'};
  background-size: cover;
  cursor: ${({ cursor }) => cursor};
  height: ${({ height }) => (height ? `${height}px` : undefined)};
`;

const SDiv = styled.div`
  max-width: 800px;
  min-width: 400px;
  background-color: white;
  padding: 20px;
  margin: 0px 100px;
`;

export type BannerImageSource = 'BASE64' | 'URL' | 'IMAGE_SRC';

export interface BannerProps {
  image: string;
  title?: string;
  description?: string;
  buttonLabel?: string;
  onClick?: () => void;
  backgroundPosition?: BackgroundPositionCss;
  dataType: BannerImageSource;
  height?: number;
  onlyImage?: boolean;
  dark?: boolean;
  cursor?: Cursor;
}

const getBackgroundImage = (image: string, dataType: BannerImageSource): string => {
  switch (dataType) {
    case 'BASE64':
      return `data:image/jpeg;base64,${image}`;
    case 'URL':
      return `${image}`;
    case 'IMAGE_SRC':
      return `/images/${image}`;
    default:
      return '';
  }
};

export const Banner = ({
  image,
  title,
  description,
  buttonLabel,
  onClick,
  backgroundPosition,
  dataType,
  height,
  onlyImage = false,
  dark,
  cursor = 'initial',
}: BannerProps) => {
  return (
    <SBackground
      backgroundPosition={backgroundPosition}
      onClick={onlyImage ? onClick : () => undefined}
      style={!onlyImage ? { backgroundImage: `url("${getBackgroundImage(image, dataType)}")` } : undefined}
      height={height}
      cursor={cursor}
    >
      {!onlyImage && (
        <Flex direction={'row'} align={'center'} justify={'flex-start'} maxHeight={height} minHeight={height}>
          <SDiv>
            <Text type={dark ? 'h1_banner_dark' : 'h1_banner_light'}>{title}</Text>
            <Text type={dark ? 'dark_14_white_100' : 'light_14_black_85'}>{description}</Text>
            <Box height={32} />
            {buttonLabel && (
              <BlackButton onClick={onClick} size={'xlarge'}>
                {buttonLabel}
              </BlackButton>
            )}
          </SDiv>
        </Flex>
      )}
      {onlyImage && <img alt="ads" src={getBackgroundImage(image, dataType)} style={{ width: '100%' }} />}
    </SBackground>
  );
};
