import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserNotificationResponse } from '@1po/1po-bff-fe-spec/generated/user/response/UserNotificationResponse';
import styled from 'styled-components';
import { CircleCrossIcon } from 'assets/icons';
import StopPropagation from 'components/StopPropagation/StopPropagation';
import { mapNotificationToType } from 'components/UserNotifications/notificationsUtils';
import { cancelNotificationRequest } from 'domains/user';
import { Flex, Icon, Text } from 'UI';

const SFlex = styled(Flex)`
  cursor: pointer;
`;

const Notification = ({ notification }: { notification: UserNotificationResponse }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationData = mapNotificationToType(t, notification);

  const cancelNotification = () => {
    if (notification.notificationId) {
      dispatch(cancelNotificationRequest({ notificationId: notification.notificationId }));
    }
  };

  const handleRedirectFromNotification = () => {
    if (notificationData?.link) {
      history.push(notificationData.link);
      cancelNotification();
    }
  };

  return (
    <SFlex align={'center'} gap={20} onClick={handleRedirectFromNotification}>
      {notificationData?.icon ?? ''}
      <Flex direction={'column'}>
        <Text type={'text_dim_bold'}>{notificationData?.title ?? ''}</Text>
        <Text type={'text_dim'}>{notificationData?.description ?? ''}</Text>
      </Flex>
      <StopPropagation>
        <Icon IconComponent={CircleCrossIcon} size={25} onClick={cancelNotification} />
      </StopPropagation>
    </SFlex>
  );
};

export default Notification;
