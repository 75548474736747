/* eslint-disable max-len */
import { VehicleIAMPartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehicleIAMPartCategoryTreeItem';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { Technicity } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/model/Technicity';
import {
  IAMLaborTime,
  LaborTimeFilterOption,
} from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetIAMLaborTimesResponse';
import {
  EngineOil,
  GetMaintenancePlanResponse,
} from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/response/GetMaintenancePlanResponse';
import { Reference as DHReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import { IAMRepairMethod } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodsResponse';
import { IAMTreeRepairMethod } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodsTreeResponse';
import { VehicleBrand } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchBMMVehicleBrandsResponse';
import { VehicleEngine } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchBMMVehicleEnginesResponse';
import { IAMVehicleBrand } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchIAMBMMVehicleBrandsResponse';
import { IAMVehicleModel } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchIAMBMMVehicleModelsResponse';
import { IAMVehicleVersionGroup } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchIAMBMMVehicleVersionsResponse';
import { SearchVehicleResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchVehicleResponse';
import { TechnicalDataDetailContent } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataDetailContent';
import { TechnicalDataL1 } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataL1';
import { FullText } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/response/GetFullTextAutocompleteResponse';
import { PlateDetail } from '@1po/1po-bff-fe-spec/generated/catalog/tree/response/PlateDetail';
import { UniversalProduct } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsResponse';
import { UniversalProductsCategory } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsTreeResponse';
import { TechnicalCriteriaType } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/model/TechnicalCriteriaType';
import { TechnicalInfo } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/model/TechnicalInfo';
import {
  IAMVehicleTechnicalCriteriaMainEngine,
  IAMVehicleTechnicalCriteriaTransmission,
  IAMVehicleTechnicalCriteriaVersion,
} from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/response/GetIAMVehicleTechnicalCriteriaResponse';
import { OTSType } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/response/GetVehicleOTSResponse';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { TFunction } from 'i18next';
import { APP_NAMESPACE } from 'app/App.constants';
import { Filters, ReferenceFilterTypes } from 'components/Filter/Filter.types';
import { CURSOR_PAGING_STATUS, NO_DATA, SEARCH_STATUS, SearchData } from 'utils';

export const CATALOG_NAMESPACE = `${APP_NAMESPACE}/CATALOG`;

export const SEARCH_VEHICLE = `${CATALOG_NAMESPACE}/SEARCH_VEHICLE`;

export const GET_VEHICLE_BRANDS_REQUEST = `${CATALOG_NAMESPACE}/GET_VEHICLE_BRANDS_REQUEST`;
export const GET_OTS_INFORMATION_REQUEST = `${CATALOG_NAMESPACE}/GET_OTS_INFORMATION_REQUEST`;
export const GET_VEHICLE_ENGINES_REQUEST = `${CATALOG_NAMESPACE}/GET_VEHICLE_ENGINES_REQUEST`;
export const SEARCH_VEHICLE_BMM = `${CATALOG_NAMESPACE}/SEARCH_VEHICLE_BMM`;
export const SEARCH_VEHICLE_IAM_BMM = `${CATALOG_NAMESPACE}/SEARCH_VEHICLE_IAM_BMM`;

export const GET_IAM_VEHICLE_MODELS_REQUEST = `${CATALOG_NAMESPACE}/GET_IAM_VEHICLE_MODELS_REQUEST`;
export const GET_IAM_VEHICLE_VERSIONS_REQUEST = `${CATALOG_NAMESPACE}/GET_IAM_VEHICLE_VERSIONS_REQUEST`;
export const GET_LEVELS3_DETAIL_REQUEST = `${CATALOG_NAMESPACE}/GET_LEVELS3_DETAIL_REQUEST`;
export const GET_MARKETING_REFERENCES_REQUEST = `${CATALOG_NAMESPACE}/GET_MARKETING_REFERENCES_REQUEST`;
export const GET_PLATE_REFERENCES_REQUEST = `${CATALOG_NAMESPACE}/GET_PLATE_REFERENCES_REQUEST`;
export const REQUEST_SINGLE_REFERENCE = `${CATALOG_NAMESPACE}/REQUEST_SINGLE_REFERENCE`;
export const GET_IAM_LABOR_TIMES_REQUEST = `${CATALOG_NAMESPACE}/GET_IAM_LABOR_TIMES_REQUEST`;
export const GET_IAM_TECHNICAL_DATA_TREE_REQUEST = `${CATALOG_NAMESPACE}/GET_IAM_TECHNICAL_DATA_TREE_REQUEST`;
export const GET_IAM_TECHNICAL_DATA_DETAILS_REQUEST = `${CATALOG_NAMESPACE}/GET_IAM_TECHNICAL_DATA_DETAILS_REQUEST`;
export const GET_IAM_SERVICE_OPERATIONS_REQUEST = `${CATALOG_NAMESPACE}/GET_IAM_SERVICE_OPERATIONS_REQUEST`;
export const GET_MAINTENANCE_PLAN_REQUEST = `${CATALOG_NAMESPACE}/GET_MAINTENANCE_PLAN_REQUEST`;
export const GET_FULLTEXT_AUTOCOMPLETE_REQUEST = `${CATALOG_NAMESPACE}/GET_FULLTEXT_AUTOCOMPLETE_REQUEST`;
export const GET_TEXT_SEARCH_REQUEST = `${CATALOG_NAMESPACE}/GET_TEXT_SEARCH_REQUEST`;
export const GET_UNIVERSAL_PRODUCTS_TREE_REQUEST = `${CATALOG_NAMESPACE}/GET_UNIVERSAL_PRODUCTS_TREE_REQUEST`;
export const GET_UNIVERSAL_PRODUCTS_REQUEST = `${CATALOG_NAMESPACE}/GET_UNIVERSAL_PRODUCTS_REQUEST`;
export const GET_VEHICLE_TECHNICAL_CRITERIA_REQUEST = `${CATALOG_NAMESPACE}/GET_VEHICLE_TECHNICAL_CRITERIA_REQUEST`;
export const GET_IAM_VEHICLE_TECHNICAL_CRITERIA_REQUEST = `${CATALOG_NAMESPACE}/GET_IAM_VEHICLE_TECHNICAL_CRITERIA_REQUEST`;
export const GET_IAM_REFERENCES_REQUEST = `${CATALOG_NAMESPACE}/GET_IAM_REFERENCES_REQUEST`;
export const GET_VEHICLE_CATEGORIES_REQUEST = `${CATALOG_NAMESPACE}/GET_VEHICLE_CATEGORIES_REQUEST`;
export const GET_VEHICLE_CATEGORY_IMAGES_REQUEST = `${CATALOG_NAMESPACE}/GET_VEHICLE_CATEGORY_IMAGES_REQUEST`;
export const SWITCH_VEHICLE_REQUEST = `${CATALOG_NAMESPACE}/SWITCH_VEHICLE_REQUEST`;
export const GET_DATAHUB_TIRE_REQUEST = `${CATALOG_NAMESPACE}/GET_DATAHUB_TIRE_REQUEST`;
export const GET_IAM_REPAIR_METHODS_REQUEST = `${CATALOG_NAMESPACE}/GET_IAM_REPAIR_METHODS_REQUEST`;
export const GET_IAM_REPAIR_METHODS_TREE_REQUEST = `${CATALOG_NAMESPACE}/GET_IAM_REPAIR_METHODS_TREE_REQUEST`;
export const ADD_REFERENCE_FROM_CATALOG_TO_CART = `${CATALOG_NAMESPACE}/ADD_REFERENCE_FROM_CATALOG_TO_CART`;
export const ADD_TIRE_FROM_CATALOG_TO_CART = `${CATALOG_NAMESPACE}/ADD_TIRE_FROM_CATALOG_TO_CART`;
export const REMOVE_CART_REFERENCE_IN_CATALOG = `${CATALOG_NAMESPACE}/REMOVE_CART_REFERENCE_IN_CATALOG`;

export interface MarketingReferencesWrapper {
  status: CURSOR_PAGING_STATUS;
  referenceNumbers?: Array<string>;
  lastId?: string;
}

export interface VehicleItemState {
  searchVehicleStatus?: SEARCH_STATUS;
  vehicleDetail?: VehicleDetail;
  explodedTree?: SearchData<VehiclePartCategoryTreeItem[]>;
  explodedIAMTree?: SearchData<VehicleIAMPartCategoryTreeItem[]>;
  explodedOEMTree?: SearchData<VehicleIAMPartCategoryTreeItem[]>;
  nodeIdToMarketingReferences?: Map<string, MarketingReferencesWrapper>;
  nodeIdToIAMReferences?: Map<string, IAMReferenceNodeWrapper>;
  // todo refactor, unite with DHLaborTimeLocal so we have only one type of labor times
  nodeIdToIAMLaborTimes?: Map<string, IAMLaborTimesWrapper | NO_DATA>;
  // todo: delete this after autocomplete is moved to catalog/searchstatus
  fulltextSearch?: FulltextSearch;
  technicalCriteria?: VehicleTechnicalCriteriaLocal | NO_DATA;
  technicalIAMCriteria?: IAMVehicleTechnicalCriteriaLocal | NO_DATA;
  technicalData?: Map<string, VehicleTechnicalDataLocal | NO_DATA>;
  otsType?: SearchData<OTSType>;
  plates?: Map<string, PlateInfo>;
  multiPlates?: Map<string, MultiPlate[] | NO_DATA>;
  searchKey?: string;
  maintenancePlan?: MaintenancePlanLocal | NO_DATA;
  nodeIdToIAMRepairMethods?: Map<string, IAMRepairMethod[] | NO_DATA>;
  repairMethods?: SearchData<IAMTreeRepairMethod[]>;
}

export interface IamFilters {
  otherBrandFilters: Filters;
  oemFilters: Filters;
  categories: string[];
}

export interface CatalogState {
  vehicles: Map<string, VehicleItemState | undefined>;
  requestedItems: Map<string, string>;
  lastSearchedVehicleKey?: string;
  vehicleBrands: VehicleBrandLocal[] | NO_DATA;
  iam_vehicleBrands: IAMVehicleBrandLocal[] | NO_DATA;
  universalProducts: UniversalProductsLocal;
  iamFilters: IamFilters;
  activeQuickAccess: QuickAccess;
  multipleVehiclesFound: SearchVehicleResponse | undefined;
  foundVehiclesAreFromDifferentCountries: boolean | undefined;
  searchStatus?: SearchStatus;
  technicalCriteriaPdfType?: TechnicalCriteriaType;
}

export interface QuickAccess {
  activeCategory: string | undefined;
  activeSubCategory: string | undefined;
}

export interface MaintenancePlanLocal extends Omit<GetMaintenancePlanResponse, 'engineOilList'> {
  operationsByServiceIds: number[];
  engineOilStandards: string[];
  engineOilList: EngineOilLocal[];
}

export interface EngineOilLocal {
  temp: string;
  dataForTemp: EngineOil[];
}

export interface UniversalProductsLocal {
  tree: UniversalProductsCategory[] | NO_DATA;
  items: Map<string, UniversalProduct | NO_DATA>;
  selection: UniversalProductsSelection | NO_DATA;
  filter: Filters;
}

export interface SearchStatus {
  searchId: string;
  searchText: string;
  vehicleKey?: string;
  status: SEARCH_STATUS;
  source?: CatalogSource;
  type?: SearchType;
  fullTextSearch: FulltextSearch;
  iamFullTextSearch?: IAMFulltextSearchResult[];
}

export type SearchType = 'REFERENCE' | 'FULLTEXT';

export type SearchParams = {
  query: string | undefined;
  vehicleKey: string | undefined;
  pending: boolean;
  isValid: boolean;
  open: boolean;
  focusMenu?: number;
  searchId?: string;
};

export interface FulltextSearch {
  fulltextAutocomplete: FullText[] | NO_DATA;
  lastAutocompleteKey?: string;
  fulltextSearchResult?: FulltextSearchResult;
}

export interface IAMFulltextSearchResult {
  groupName?: string;
  mainReferences?: string[];
  equivalentReferences: string[] | NO_DATA;
  additionalEquivalencesLoading: boolean;
  loading?: boolean;
}

export interface VehicleBrandLocal extends VehicleBrand {
  code: string;
  name: string;
  models: VehicleModel[];
  source: 'DH';
}

export interface VehicleModel {
  code: string;
  name: string;
  types: VehicleModelType[];
}

export interface VehicleModelType {
  code: string;
  name: string;
  engines: VehicleEngine[] | NO_DATA;
}

export interface IAMVehicleBrandLocal extends IAMVehicleBrand {
  models: IAMVehicleModelLocal[] | NO_DATA;
  groups?: IAMVehicleVersionGroup[] | NO_DATA;
  source: 'IAM';
}

export interface IAMVehicleModelLocal extends IAMVehicleModel {
  code: string;
  name: string;
  productionStartDate?: string;
  productionEndDate?: string;
}

export interface FulltextSearchResult {
  refNumbers: string[];
}

export interface VehicleTechnicalCriteriaLocal {
  simplifiedApplicability: boolean;
  groups: Map<TechnicalCriteriaType, TechnicalInfoLocal[]>;
}

export interface TechnicalInfoLocal extends TechnicalInfo {
  enabled?: boolean;
}

export interface IAMVehicleTechnicalCriteriaLocal {
  mainEngine: IAMVehicleTechnicalCriteriaMainEngine;
  transmission: IAMVehicleTechnicalCriteriaTransmission;
  version: IAMVehicleTechnicalCriteriaVersion;
}

export interface LaborTimesWrapper<T> {
  elements: LaborTimeFilterOption[];
  operations: LaborTimeFilterOption[];
  laborTimes: T[];
  quickFilters: string[];
}

export type IAMLaborTimesWrapper = LaborTimesWrapper<IAMLaborTimeLocal>;

export type IAMLaborSubtableLabel = 'included' | 'optional';

export interface VehicleTechnicalDataLocal {
  technicalDataTree: TechnicalDataL1[];
  technicalDataDetail?: Map<string, TechnicalDataDetailLocal>;
}

export interface TechnicalDataDetailLocal {
  technicalDataContentList?: TechnicalDataDetailContent[];
  searchStatus?: SEARCH_STATUS;
}

export function getIAMLaborSubtableLabelText(label: IAMLaborSubtableLabel | undefined, t: TFunction): string {
  switch (label) {
    case 'included':
      return t('catalog.parts.category.car_parts.labor_time.included', 'Included:');
    case 'optional':
      return t('catalog.parts.category.car_parts.labor_time.optional', 'Optional:');
    default:
      return '';
  }
}

export interface IAMLaborTimeLocal extends IAMLaborTime {
  nodeId: string;
  subtableLabel?: IAMLaborSubtableLabel; // used for subtable header in the table
  isIncluded: boolean;
  isSubLaborTime: boolean;
  subtable: IAMLaborTimeLocal[];
}

export interface VehicleExplodedViewDetail {
  index: number;
  nodeId: string;
  label: string;
}

export type SearchVehicleMethod = 'VIN' | 'VRN';

export type ExplodedTree = VehiclePartCategoryTreeItem[];
export type IAMExplodedTree = VehicleIAMPartCategoryTreeItem[];

export interface IAMReferences extends ReferenceFilterTypes {
  referenceNumbers: string[];
}

export interface PlateInfo {
  plateId?: string;
  thumbnailImageKey?: string;
  svgImageKey?: string;
  references?: PlateReferencesDetail[] | NO_DATA;
  plateDetail?: PlateDetail[] | NO_DATA;
  genericParts?: string[];
}

export type IAMReferenceNodeWrapper = {
  otherBrandReferences: IAMReferencesWrapper;
  oemReferences: IAMReferencesWrapper;
};

export type IAMReferencesWrapper = IAMReferences | NO_DATA;

export interface PlateReferencesDetail extends DHReference {
  index: number;
  alternativeReferenceNumbers: string[];
}

export interface MultiPlate {
  plateIds: string[];
}

export interface PlateImage {
  plateId: string;
  imageKey: string | NO_DATA;
}

// Search params (in URL after "?")
export const ActiveTabParam = 'activeTab';
export const PreferredTabParam = 'preferredTab';
export const PlateIdParam = 'plateId';
export const HighlightIndex = 'higlightedIndex';
export const PlateViewModeParam = 'plateViewMode';
export const VehicleCountry = 'vehicleCountry';
export const IAMCatalogSourceParam = 'iamCatalogSource';
export const TechnicalDataIdParam = 'technicalDataId';
export type ApplicationUrlParam =
  | typeof ActiveTabParam
  | typeof PlateIdParam
  | typeof HighlightIndex
  | typeof PlateViewModeParam
  | typeof VehicleCountry
  | typeof IAMCatalogSourceParam
  | typeof TechnicalDataIdParam;

// Search params values
export const PARAM_LABOR_TIME = 'labor_time';
export const PARAM_SPARE_PARTS = 'spare_parts';
export const PARAM_REPAIR_METHODS = 'repair_methods';
export const PARAM_MULTIPLATE = 'multiplate';
export const PARAM_STANDARD = 'standard';
export const PARAM_CONSUMABLES = '20';

export const FLUIDS_ID = '20';
export const INTERVALS_ID = '22';
export const INTERVALS_TAB = 'intervals';
export const FLUIDS_TAB = 'fluids';
export type MaintenanceTabsType = typeof INTERVALS_TAB | typeof FLUIDS_TAB;

export const CAR_PARTS_TAB = 'car_parts';
export const TECHNICAL_DATA_TAB = 'technical_data';
export const TECHNICAL_DOCUMENTATION_TAB = 'technical_documentation';
export type IamCatalogTabsType = typeof CAR_PARTS_TAB | typeof TECHNICAL_DATA_TAB | typeof TECHNICAL_DOCUMENTATION_TAB;

export type DhCatalogTabsType = typeof CAR_PARTS_TAB | typeof TECHNICAL_DATA_TAB | typeof TECHNICAL_DOCUMENTATION_TAB;

export const OTHER_BRAND_KEY = 'other_brand';
export const OEM_BRAND_KEY = 'oem_brand';
export type IamCatalogBrandsType = typeof OTHER_BRAND_KEY | typeof OEM_BRAND_KEY;
// Technical criteria constants
export const TECHNICAL_CRITERIA_MANDATORY_CODES = [
  // brand
  '336',
  // family
  '007',
  // body type
  '008',
  // engine
  '999',
  // gearbox
  '997',
];
export const TECHNICAL_CRITERIA_SIMPLIFIED_APPLICABILITY = [
  // brand
  '336',
  // family
  '007',
  // body type
  '008',
  // engine
  '999',
  // engine index
  '998',
  // gearbox
  '997',
  // gearbox index
  '996',
  // battery type
  '968',
  // battery index
  '966',
  // second engine type
  '948',
  // second engine index
  '946',
  // phase
  '901',
  // documentary period
  '900',
  // vehicle plant
  'APV001',
  // engine 1 plant
  'APV002',
  // gearbox plant
  'APV003',
  // battery plant
  'APV004',
  // engine 2 plant
  'APV005',
  // side direction
  '027',
  // level equipment
  '013',
  // version
  '513',
  // heating system
  '046',
  // power type
  '019',
  // number of gears
  '427',
];

export interface UniversalProductsSelection {
  level1: string | NO_DATA;
  level2: string | NO_DATA;
  level3: string | NO_DATA;
}

export const NORMAL = 'NORMAL';
export const HIGH = 'HIGH';
export const VERY_HIGH = 'VERY_HIGH';

export const TIER_T1 = 'T1';
export const TIER_T2 = 'T2';
export const TIER_T3 = 'T3';

export function getTechnicityTier(tech: Technicity | undefined): string {
  switch (tech) {
    case NORMAL:
      return TIER_T1;
    case HIGH:
      return TIER_T2;
    case VERY_HIGH:
      return TIER_T3;
    default:
      return '';
  }
}

export const DATAHUB = 'DATAHUB';
export const IAM = 'IAM';
export const TIRE = 'TIRE';
export const STANDARD = 'STANDARD';

export type FilterByElementOperationState = {
  filterElement: string;
  filterOperations: string;
};

export const FilterDefault: FilterByElementOperationState = {
  filterElement: 'AllElements',
  filterOperations: 'AllOperations',
};

export const LIFECYCLE_W1 = 'W1';
export const LIFECYCLE_W2 = 'W2';
export const LIFECYCLE_W3 = 'W3';
export const LIFECYCLE_W4 = 'W4';

export const UNIVERSAL_PRODUCTS_NODE_ID = 'N-33909';
export const UNIVERSAL_PRODUCTS_OILS_NODE_ID = 'N-33926';
export const UNIVERSAL_PRODUCTS_OILS_5W30_NODE_ID = 'N-34081';
