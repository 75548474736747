import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { CheckCircleIcon } from 'assets/icons';
import { isEmptyStock } from 'components/StockInfo/EmptyStock';
import PrivateComponent from 'composers/PrivateComponent';
import { sendEmailExadisReferenceInterestRequestSaga } from 'domains/email/Email.store';
import { getStockInfo } from 'domains/references';
import { theme } from 'styles/theme';
import { Flex, Icon, notifyTop, Text, WhiteButton } from 'UI';
import { AppTranslation, FOUND } from 'utils';
import { Country } from 'utils/i18n/Country';

interface ExadisInterestedButtonProps {
  catalogSource: string;
  referenceNumber: string;
  vehicleKey?: string;
}
export const EXADIS_INTEREST = 'exadis_interest_click.reference_number_';

export function ExadisInterestedButton({
  catalogSource,
  referenceNumber,
  vehicleKey,
}: Readonly<ExadisInterestedButtonProps>) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stock = useSelector((state: RootState) => getStockInfo(state, referenceNumber));
  const [isClicked, setIsClicked] = useState(false);

  // 8100145401
  useEffect(() => {
    if (stock?.searchStatus === FOUND && !isEmptyStock(stock)) {
      localStorage.removeItem(EXADIS_INTEREST + referenceNumber);
      setIsClicked(false);
    } else {
      const previousClicked = localStorage.getItem(EXADIS_INTEREST + referenceNumber);
      setIsClicked(!!previousClicked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stock?.searchStatus]);

  if (catalogSource !== 'IAM' || !isEmptyStock(stock)) {
    return null;
  }

  return (
    <PrivateComponent
      render={() => (
        <>
          {!isClicked && (
            <WhiteButton
              stretch={true}
              onClick={() => {
                setIsClicked(true);
                dispatch(sendEmailExadisReferenceInterestRequestSaga({ referenceNumber, vehicleKey }));
                notifyTop(
                  'success',
                  AppTranslation.t('reference.exadis.outOfStock.interest.request.success', 'Request sent successfully'),
                );
                localStorage.setItem(EXADIS_INTEREST + referenceNumber, 'true');
              }}
            >
              {t('reference.exadis.outOfStock.interest.request', 'Interested ?')}
            </WhiteButton>
          )}

          {isClicked && (
            <Flex justify={'flex-end'} gap={5} direction={'row'} align={'center'}>
              <Icon noPointer={true} IconComponent={CheckCircleIcon} color={theme.color.grass_green} />
              <Text type={'text'}>{t('reference.exadis.outOfStock.interest.clicked', 'Interest noted')}</Text>
            </Flex>
          )}
        </>
      )}
      requiredCountries={[Country.FR]}
    />
  );
}
