import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserNotificationResponse } from '@1po/1po-bff-fe-spec/generated/user/response/UserNotificationResponse';
import { notification as antdNotification } from 'antd-v5';
import { mapNotificationToType } from 'components/UserNotifications/notificationsUtils';
import { cancelNotificationRequest, getUserNotifications } from 'domains/user';
import { BlueButton, Flex, Text, WhiteButton } from 'UI';
import { getSearchData } from 'utils';

const NotificationsPopup = () => {
  const { t } = useTranslation();
  const [api, contextHolder] = antdNotification.useNotification();
  const [shownUnreadNotifications, setShownUnreadNotifications] = useState<string[]>([]);
  const notifications = useSelector(getUserNotifications);
  const history = useHistory();
  const dispatch = useDispatch();

  const openNotification = (notification: UserNotificationResponse) => {
    const notificationData = mapNotificationToType(t, notification);
    const key = notification.notificationId ?? '';

    const handleRedirectFromNotification = () => {
      if (notificationData?.link && notification.notificationId) {
        api.destroy(key);
        history.push(notificationData.link);
        dispatch(cancelNotificationRequest({ notificationId: notification.notificationId }));
      }
    };

    const btn = (
      <Flex direction={'row-reverse'} gap={5}>
        <BlueButton onClick={handleRedirectFromNotification}>{t('notifications.action.see', 'See')}</BlueButton>
        <WhiteButton onClick={() => api.destroy()}>{t('common.ignore_all', 'Ignore all')}</WhiteButton>
      </Flex>
    );
    api.open({
      key,
      message: <Text type={'text_dim_bold'}>{notificationData?.title ?? ''}</Text>,
      description: <Text type={'text_dim'}>{notificationData?.description ?? ''}</Text>,
      btn,
      duration: 0,
      style: { width: 400 },
    });
    setShownUnreadNotifications((prev) => [...prev, key]);
  };

  useEffect(() => {
    const notificationsData = getSearchData(notifications)?.notifications ?? [];
    [...notificationsData].reverse().forEach((notification) => {
      if (notification.notificationId && !shownUnreadNotifications.includes(notification.notificationId)) {
        openNotification(notification);
      }
    });
    //eslint-disable-next-line
  }, [notifications.data?.notifications]);

  return <>{contextHolder}</>;
};

export default NotificationsPopup;
