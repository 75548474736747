import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PromotionBanner } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/PromotionBanner';
import { ROUTER_PROMOTION } from 'app/AppRouter';
import { Banner } from 'UI';

const PromotionBannerWrapper = ({ banner }: { banner: PromotionBanner }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Banner
      key={banner.promotionId}
      image={banner.imageUrl}
      dataType={'URL'}
      title={banner.title}
      description={banner.catchLine}
      buttonLabel={t('promotions.see_offers', 'See offers')}
      onClick={() => history.push(`${ROUTER_PROMOTION}/${banner.promotionId}`)}
      height={400}
    />
  );
};

export default PromotionBannerWrapper;
