import React from 'react';
import { PresentableOrderStatusType } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { UserNotificationResponse } from '@1po/1po-bff-fe-spec/generated/user/response/UserNotificationResponse';
import { TFunction } from 'i18next';
import { ROUTER_IN_PROGRESS_ORDERS } from 'app/AppRouter';
import { getColor, getStatusIcon, getStatusLabel } from 'domains/order/OrderStatus';
import { theme } from 'styles';
import { Icon } from 'UI';

interface NotificationLine {
  title: string;
  description: string;
  link: string;
  icon: React.ReactNode;
}

export const mapNotificationToType = (
  t: TFunction,
  notification: UserNotificationResponse,
): NotificationLine | undefined => {
  const contextMap = notification.notificationContext?.contextMap;
  const getOrderLink = (internalOrderId: unknown) => {
    if (typeof internalOrderId === 'string') {
      return `${ROUTER_IN_PROGRESS_ORDERS}/${internalOrderId}`;
    }
    return ROUTER_IN_PROGRESS_ORDERS;
  };

  switch (notification.NotificationType) {
    case 'ORDER_STATUS_CHANGE': {
      const orderId = contextMap?.['orderId'];
      const orderStatus = contextMap?.['orderStatus'];
      const internalOrderId = contextMap?.['callerId'];
      const color = getColor(orderStatus as PresentableOrderStatusType);
      return {
        title: t('notifications.order_updated.title', 'Your order {{orderId}} status updated.', { orderId }),
        description: t(
          'notifications.order_updated.description',
          'Your order {{orderId}} has been updated to: {{orderStatus}}',
          { orderId, orderStatus: getStatusLabel(orderStatus as PresentableOrderStatusType) },
        ),
        link: getOrderLink(internalOrderId),
        icon: (
          <Icon
            IconComponent={getStatusIcon(orderStatus as PresentableOrderStatusType)}
            size={20}
            color={theme.color[color]}
          />
        ),
      };
    }
    case 'REFERENCE_STATUS_CHANGE': {
      const orderId = contextMap?.['orderId'];
      const referenceStatus = contextMap?.['referenceStatus'];
      const referenceId = contextMap?.['referenceId'];
      const referenceName = contextMap?.['referenceName'];
      const internalOrderId = contextMap?.['callerId'];
      const color = getColor(referenceStatus as PresentableOrderStatusType);

      return {
        title: t('notifications.reference_updated.title', 'Your reference {{referenceId}} status updated.', {
          referenceId,
        }),
        description: t(
          'notifications.reference_updated.description',
          'Your reference {{referenceId}}, {{referenceName}} from the Order {{orderId}} has been updated to: {{referenceStatus}}',
          {
            referenceId,
            referenceName,
            orderId,
            referenceStatus: getStatusLabel(referenceStatus as PresentableOrderStatusType),
          },
        ),
        link: getOrderLink(internalOrderId),
        icon: (
          <Icon
            IconComponent={getStatusIcon(referenceStatus as PresentableOrderStatusType)}
            size={20}
            color={theme.color[color]}
          />
        ),
      };
    }
    default:
      return undefined;
  }
};
