import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Seller } from '@1po/1po-bff-fe-spec/generated/user/model/Seller';
import { Dispatch } from 'redux';
import { RootState } from 'app/AppStore';
import { isUserTokenInvalid } from 'domains/user/User.mapper';
import {
  fetchUserNotificationsRequest,
  fetchVehicleSearchHistorySubscriptionSaga,
  getDelegationSession,
  getPossibleBuyersForDelegationSaga,
  getPossibleDelegateSessionBuyers,
  getPossibleDelegateSessionSellers,
  getPossibleSellersForDelegationSaga,
  getTokenExpired,
  getTokenUser,
  getTradingProfile,
  getUserSellersRequestSaga,
  getUserSellersToChoose,
  getVehicleSearchHistory,
  resetDelegationSessionSaga,
  setCurrentUserSaga,
  setUserProfileSearchStatus,
} from 'domains/user/User.store';
import { ERROR, NO_DATA, useUserInactivity } from 'utils';
import {
  getErrorNoConnectionToBESide,
  isConnected,
  wsConnect,
  wsInactivityDisconnect,
} from '../webSockets/WebSocket.store';

export const useFetchVehicleSearchHistorySubscription = () => {
  const dispatch = useDispatch();

  const tradingProfile = useSelector(getTradingProfile);
  const vehicleHistory = useSelector(getVehicleSearchHistory);

  useEffect(() => {
    if (tradingProfile && vehicleHistory === undefined) {
      dispatch(fetchVehicleSearchHistorySubscriptionSaga());
    }
  }, [dispatch, tradingProfile, vehicleHistory]);
};

export const fetchSellers = (dispatch: Dispatch, sellersToChooseFrom?: Seller[] | NO_DATA) => {
  if (!sellersToChooseFrom) {
    dispatch(getUserSellersRequestSaga());
  }
};

export const useFetchSellers = () => {
  const dispatch = useDispatch();
  const sellersToChooseFrom = useSelector(getUserSellersToChoose);

  useEffect(() => {
    fetchSellers(dispatch, sellersToChooseFrom);
  }, [dispatch, sellersToChooseFrom]);
};

export const useConnectToBE = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getTokenUser);
  useEffect(() => {
    if (currentUser) {
      if (isUserTokenInvalid(currentUser)) {
        dispatch(setUserProfileSearchStatus(ERROR));
        return;
      }
      if (currentUser?.selectedProfile) {
        dispatch(wsConnect());
      }
    }
  }, [dispatch, currentUser]);
};

export const useDisconnectWSOnUserInactivity = () => {
  const dispatch = useDispatch();
  const isWsConnected = useSelector(isConnected);
  const disconnectUserOnTimeout = useCallback(() => {
    if (isWsConnected) {
      dispatch(wsInactivityDisconnect());
    }
  }, [dispatch, isWsConnected]);
  useUserInactivity(disconnectUserOnTimeout, 1800000);
};

export const useFetchLocalUserToken = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentUserSaga());
  }, [dispatch]);
};

export const useFetchSellersForDelegationSession = () => {
  const dispatch = useDispatch();
  const sellers = useSelector(getPossibleDelegateSessionSellers);
  const delegationSession = useSelector(getDelegationSession);

  useEffect(() => {
    if (!sellers?.searchStatus) {
      dispatch(getPossibleSellersForDelegationSaga());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, delegationSession]);
};

export const useResetDelegateSessionOnLogout = () => {
  const dispatch = useDispatch();
  const isNotConnectedToBE = useSelector(getErrorNoConnectionToBESide);
  const tokenExpired = useSelector(getTokenExpired);
  const connectedAs = useSelector(getDelegationSession);

  useEffect(() => {
    if (connectedAs && (isNotConnectedToBE || tokenExpired)) {
      dispatch(resetDelegationSessionSaga());
    }
    // eslint-disable-next-line
  }, [isNotConnectedToBE, tokenExpired]);
};

export const useFetchBuyersForSellersForDelegationSession = (sellerId: string | undefined) => {
  const dispatch = useDispatch();
  const buyers = useSelector((state: RootState) => getPossibleDelegateSessionBuyers(state, sellerId));

  useEffect(() => {
    if (sellerId && !buyers?.searchStatus) {
      dispatch(getPossibleBuyersForDelegationSaga(sellerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sellerId]);
};

export const useFetchUserNotifications = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserNotificationsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
