import {
  SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_RESPONSE,
  SEND_EMAIL_FROM_GARAGE_RESPONSE,
  SEND_EMAIL_REFERENCE_INTEREST_EXADIS_RESPONSE,
  SEND_EMAIL_TO_ASSISTANCE_RESPONSE,
  SEND_RETURN_REQUEST_EMAIL_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { Attachment as GarageAttachment } from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailFromGarage';
import {
  Attachment as AssistanceAttachment,
  Type as ContactFormType,
} from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailToAssistance';
import { ReturnEmailRequest } from '@1po/1po-bff-fe-spec/generated/order/request/ReturnEmailRequest';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import {
  EMAIL_DATA_STATUS,
  EMAIL_NAMESPACE,
  EmailState,
  FILLING,
  SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_REQUEST,
  SEND_EMAIL_EXADIS_REFERENCE_INTEREST_REQUEST,
  SEND_EMAIL_FROM_GARAGE_REQUEST,
  SEND_EMAIL_TO_ASSISTANCE_REQUEST,
  SendDocumentationAlertMotrioLocal,
  SendEmailFromGarageLocal,
  SendEmailToAssistanceLocal,
} from 'domains/email/Email.types';
import { NO_DATA } from 'utils';

// Init state
export const emailToAssistanceInitialState: SendEmailToAssistanceLocal = {
  email: '',
  subject: '',
  socialReason: '',
  body: '',
  companyRegistrationNumber: '',
  request: undefined,
  attachments: [],
  vrn: '',
  urlLink: '',
  sendCopy: false,
  isSent: FILLING,
};

const emailDocumentationAlertMotrioInitialState: SendDocumentationAlertMotrioLocal = {
  isSent: FILLING,
  email: undefined,
  subject: '',
  socialReason: undefined,
  companyRegistrationNumber: undefined,
  sendCopy: false,
  vehicleRegistrationNumber: undefined,
  vehicleVin: undefined,
  vehicleBrand: undefined,
  vehicleModel: undefined,
  vehicleVersion: undefined,
  message: '',
  userMessage: '',
  request: undefined,
  countryCode: undefined,
  referenceNumber: undefined,
  referenceBrand: undefined,
  companyRegistrationNumberR1: undefined,
  referenceDescription: undefined,
  socialReasonR1: undefined,
  vehicleCountryCode: undefined,
  vehicleEngineCode: undefined,
};

const initialState: EmailState = {
  emailFromGarageData: {
    recipients: [],
    subject: '',
    body: '',
  },
  emailToAssistance: emailToAssistanceInitialState,
  emailDocumentationAlertMotrio: emailDocumentationAlertMotrioInitialState,
};

// Saga actions
export const sendEmailExadisReferenceInterestRequestSaga = createAction<{
  referenceNumber: string;
  vehicleKey?: string;
}>(SEND_EMAIL_EXADIS_REFERENCE_INTEREST_REQUEST);
export const sendEmailExadisReferenceInterestResponseSaga = createAction(SEND_EMAIL_REFERENCE_INTEREST_EXADIS_RESPONSE);
export const sendEmailFromGarageRequestSaga = createAction(SEND_EMAIL_FROM_GARAGE_REQUEST);
export const sendEmailFromGarageResponseSaga = createAction(SEND_EMAIL_FROM_GARAGE_RESPONSE);
export const sendEmailReturnRequestRequestSaga = createAction<ReturnEmailRequest>('SEND_RETURN_REQUEST_EMAIL_REQUEST');
export const sendEmailReturnRequestResponseSaga = createAction(SEND_RETURN_REQUEST_EMAIL_RESPONSE);
export const sendEmailToAssistanceRequestSaga = createAction<ContactFormType>(SEND_EMAIL_TO_ASSISTANCE_REQUEST);
export const sendEmailToAssistanceResponseSaga = createAction(SEND_EMAIL_TO_ASSISTANCE_RESPONSE);
export const sendEmailDocumentationAlertMotrioRequestSaga = createAction(SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_REQUEST);
export const sendEmailDocumentationAlertMotrioResponseSaga = createAction(
  SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_RESPONSE,
);

// Slice
const slice = createSlice({
  name: EMAIL_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setEmailFromGarageData: (state, { payload }: PayloadAction<SendEmailFromGarageLocal>) => {
      state.emailFromGarageData = payload;
    },
    setEmailFromGarageAttachments: (state, { payload }: PayloadAction<GarageAttachment[] | NO_DATA>) => {
      state.emailFromGarageData.attachments = payload;
    },
    setEmailToAssistanceData: (state, { payload }: PayloadAction<SendEmailToAssistanceLocal>) => {
      state.emailToAssistance = payload;
    },
    setEmailToAssistanceDataStatus: (state, { payload }: PayloadAction<EMAIL_DATA_STATUS>) => {
      state.emailToAssistance.isSent = payload;
    },
    setEmailToAssistanceAttachments: (state, { payload }: PayloadAction<AssistanceAttachment[] | NO_DATA>) => {
      state.emailToAssistance.attachments = payload;
    },
    setEmailDocumentationAlertMotrioData: (state, { payload }: PayloadAction<SendDocumentationAlertMotrioLocal>) => {
      state.emailDocumentationAlertMotrio = payload;
    },
    setEmailDocumentationAlertMotrioDataStatus: (state, { payload }: PayloadAction<EMAIL_DATA_STATUS>) => {
      state.emailDocumentationAlertMotrio.isSent = payload;
    },
    resetEmailDocumentationAlertMotrioData: (state) => {
      state.emailDocumentationAlertMotrio = emailDocumentationAlertMotrioInitialState;
    },
  },
});

// Actions
export const {
  setInitialState,
  setEmailFromGarageData,
  setEmailFromGarageAttachments,
  setEmailToAssistanceData,
  setEmailToAssistanceDataStatus,
  setEmailToAssistanceAttachments,
  setEmailDocumentationAlertMotrioData,
  setEmailDocumentationAlertMotrioDataStatus,
  resetEmailDocumentationAlertMotrioData,
} = slice.actions;

// Getters/Selectors
export const getEmailFromGarageData = createSelector(
  (state: RootState) => state.email.emailFromGarageData,
  (val) => val,
);
export const getEmailToAssistanceData = createSelector(
  (state: RootState) => state.email.emailToAssistance,
  (val) => val,
);
export const getEmailToAssistanceDataStatus = createSelector(
  (state: RootState) => state.email.emailToAssistance.isSent,
  (val) => val,
);
export const getEmailDocumentationAlertMotrioData = createSelector(
  (state: RootState) => state.email.emailDocumentationAlertMotrio,
  (val) => val,
);

export default slice.reducer;
